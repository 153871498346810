import React from "react";
/**
 *
 * Wrap component in divs with specific styling
 *
 * @param children
 * @returns {*}
 * @constructor
 */
var Content = function (_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-12 col-xl-12" }, children)));
};
export default Content;
