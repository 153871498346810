/**
 *Created by Mikael Lindahl on 2020-05-26
 */
"use strict";
import React from "react";
import './SubmitSteps.scss';
/**
 * Render submit steps
 *
 * @param props {object} Submit steps properties
 * @returns {*}
 * @constructor
 */
export default function SubmitSteps(props) {
    return React.createElement(React.Fragment, null, props.steps.map(function (s, i) {
        return React.createElement("div", { key: "s".concat(i), className: 'my-3' },
            React.createElement("div", { className: 'my-2' },
                React.createElement("b", null, s.key),
                ' ',
                s.operation),
            s.value && React.createElement("div", { className: 'my-2' },
                React.createElement("pre", null,
                    React.createElement("code", null, s.value))));
    }));
}
