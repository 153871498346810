var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import util from "../lib/util";
import { CellEligibleFor, CellLocale, CellTrash } from "../components/tables/TableTranslationCells";
import translationConfigTables from "../constants/translationConfigTables";
import React from "react";
/**
 * Render delete icon for header column deletion
 *
 * @param locale {string} Translation locale
 * @param translation {object} Current translation
 * @param translation_edit {object} Edits to translation
 * @param updateTranslationEdit {function} Callback for updating translation edit
 * @returns {*}
 * @constructor
 */
function HeaderTrash(_a) {
    var locale = _a.locale, translation = _a.translation, translation_edit = _a.translation_edit, updateTranslationEdit = _a.updateTranslationEdit;
    var count = 0;
    var all = [];
    for (var key in translation_edit) {
        all.push(key);
        if (translation_edit[key][locale] && translation_edit[key][locale].action == 'deleted') {
            count++;
        }
    }
    for (var key in translation) {
        if (all.indexOf(key) == -1) {
            all.push(key);
        }
    }
    var is_deleted = (util.dicToList(translation).length <= count
        && util.dicToList(translation_edit).length <= count);
    var icon = !is_deleted ? 'fa-trash' : 'fa-undo';
    return React.createElement("i", { className: "fas ".concat(icon), onClick: function () {
            var _a;
            var update = {};
            for (var _i = 0, all_1 = all; _i < all_1.length; _i++) {
                var key = all_1[_i];
                var te = util.getTranslation({ key: key, locale: locale, translation: translation_edit });
                var t = util.getTranslation({ key: key, locale: locale, translation: translation });
                var action = te && te.action == 'deleted' && !te.is_deleted_row
                    ? te.is_created
                        ? 'created'
                        : (te.text != te.text_original
                            ? 'edited'
                            : 'default')
                    : 'deleted';
                var entry = util.generateTranslationEdit({
                    action: action,
                    is_deleted_column: !is_deleted,
                    is_deleted_row: te && te.is_deleted_row,
                    is_created: te && te.is_created,
                    text_original: te ? te.text_original : (t ? t.text : ''),
                    text: te ? te.text : (t ? t.text : ''),
                });
                update[key] = (_a = {},
                    _a[locale] = entry,
                    _a);
            }
            updateTranslationEdit({ update: update, reset: true });
        } });
}
/**
 * Get translation with data in translation edit merged in
 *
 * @param translation {object} Current translation
 * @param translation_edit {object} Edits to translation
 * @returns {*}
 */
function getTranslationMerged(_a) {
    var translation = _a.translation, translation_edit = _a.translation_edit;
    translation = util.clone(translation);
    for (var key in translation_edit) {
        for (var locale in translation_edit[key]) {
            var te = translation_edit[key][locale];
            if (!translation[key]) {
                translation[key] = {};
            }
            if (!translation[key][locale]) {
                translation[key][locale] = { text: '' };
            }
            translation[key][locale].text = te.text;
        }
    }
    return translation;
}
export default function useTableTranslationColumnsAndData(_a) {
    var only_show = _a.only_show, translation = _a.translation, translation_edit = _a.translation_edit, updateTranslationEdit = _a.updateTranslationEdit, setEligibleForFilter = _a.setEligibleForFilter, eligibleForFilter = _a.eligibleForFilter;
    /**
     * Format table columns
     *
     * @param translation {object} Current translation
     * @param translation_edit {object} Edits to translation
     * @param updateTranslationEdit {function} Callback for updating translation edit
     * @returns {[]}
     */
    function getColumns(selectEligibleFor
    // {
    //     translation,
    //     translation_edit,
    //     updateTranslationEdit,
    //     setEligibleForFilter,
    //     eligibleForFilter
    // }: TableTranslationTProps<TranslationRowData>
    ) {
        var locales = ['key'];
        for (var _i = 0, _a = util.getLocales([translation, translation_edit]); _i < _a.length; _i++) {
            var locale = _a[_i];
            if (locales.indexOf(locale) != -1) {
                continue;
            }
            locales.push(locale);
        }
        var columns = [];
        var _loop_1 = function (locale) {
            columns.push({
                id: locale,
                header: function () { return React.createElement("div", { className: 'd-flex' },
                    React.createElement("div", null, locale),
                    locale != 'key' &&
                        React.createElement("div", { className: 'ml-auto' }, HeaderTrash({
                            locale: locale,
                            translation: translation,
                            translation_edit: translation_edit,
                            updateTranslationEdit: updateTranslationEdit
                        }))); },
                accessorFn: function (row, index) { return row[locale].text; },
                filterFn: 'includesString',
                enableColumnFilter: true,
                enableSorting: locale == 'key',
                cell: function (_a) {
                    var cell = _a.cell;
                    return CellLocale({
                        cell: cell,
                        locale: locale,
                        translation_edit: translation_edit,
                        translation: translation,
                        updateTranslationEdit: updateTranslationEdit
                    });
                }
            });
        };
        for (var _b = 0, locales_1 = locales; _b < locales_1.length; _b++) {
            var locale = locales_1[_b];
            _loop_1(locale);
        }
        var options = __spreadArray([], translationConfigTables.options, true);
        options.push({ value: 'show_all', label: 'Show all' });
        columns.push({
            id: 'eligible_for',
            header: function () { return React.createElement("div", null,
                React.createElement("div", null, 'Eligible for'),
                React.createElement("div", null, selectEligibleFor({
                    value: eligibleForFilter,
                    set: setEligibleForFilter,
                    options: options
                }))); },
            enableColumnFilter: false,
            enableSorting: false,
            accessorFn: function (row, index) { return row.eligible_for.text; },
            // accessorKey: 'eligible_for' as keyof TranslationRowData,
            cell: function (_a) {
                var cell = _a.cell;
                return CellEligibleFor({
                    cell: cell,
                    translation_edit: translation_edit,
                    updateTranslationEdit: updateTranslationEdit
                });
            }
        }, {
            id: 'trash',
            header: '',
            accessorFn: function (row, index) { return row.key.text; },
            // accessorKey: 'key' as keyof TranslationRowData,
            enableColumnFilter: false,
            enableSorting: false,
            cell: function (_a) {
                var cell = _a.cell;
                return CellTrash({
                    cell: cell,
                    translation_edit: translation_edit,
                    updateTranslationEdit: updateTranslationEdit
                });
            }
        });
        return columns;
    }
    /**
     * Format data
     *
     * @param translation {object} Current translation
     * @param translation_edit {object} Edits to translation
     * @param only_show {boolean} False if table is editable
     * @returns {*}
     */
    function getData(
    // {
    //     translation,
    //     translation_edit,
    //     only_show,
    //     eligibleForFilter
    // }: TableTranslationTProps<TranslationRowData>
    ) {
        var translation_merged = getTranslationMerged({ translation: translation, translation_edit: translation_edit });
        var data = util.dicToList(translation_merged);
        var data_out = data.reduce(function (tot, row) {
            if (eligibleForFilter != 'show_all' && row.eligible_for.text != eligibleForFilter) {
                return tot;
            }
            var key = row.key.text;
            var row_new = {};
            for (var locale in row) {
                var entry = row[locale];
                var is_created = false;
                var te = util.getTranslation({ key: key, locale: locale, translation: translation_edit });
                var className = 'translation-default';
                var action = 'default';
                var text = entry.text;
                var text_original = text;
                if (te) {
                    className = te.className;
                    text = te.text;
                    action = te.action;
                    text_original = te.text_original;
                    is_created = te.is_created;
                }
                row_new[locale] = { text: text, className: className, text_original: text_original, key: key, action: action, is_created: is_created };
            }
            if (only_show == 'edit' && translation_edit[key]) {
                tot.push(row_new);
            }
            else if (only_show == 'time_type' && key.slice(0, 9) == 'time_type') {
                tot.push(row_new);
            }
            else if (only_show == 'travel_type' && key.slice(0, 11) == 'travel_type') {
                tot.push(row_new);
            }
            else if (only_show == 'all') {
                tot.push(row_new);
            }
            return tot;
        }, []);
        data_out = util.sort(data_out, function (v) { return v.key.text; });
        return data_out;
    }
    return {
        getData: getData,
        getColumns: getColumns
    };
}
