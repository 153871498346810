/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import './ModalInputSalaryCompany.scss';
import { Button, Modal } from "react-bootstrap";
import Utils from "../../lib/util";
import useModal from "../../hooks/useModal";
import InputTextSimpleView from "./InputTextSimpleView";
import InputCheckboxSimpleView from "./InputCheckboxSimpleView";
import InputSalaryInstructions from "./InputSalaryInstructions";
import Util from "../../lib/util";
import Salary from "../../constants/salary";
import Issue from "../Issue";
import InputTextList from "./InputTextList";
import util from "../../lib/util";
var debug = require('debug')('bk-manager:frontend-react:components:inputs:ModalInputSalaryCompany');
/**
 * Get close button for salary input company modal
 *
 * @param props {object} Input salary company properties
 * @param modal {object} Modal hook
 * @param setCompany {function} Set current company data
 * @returns {JSX.Element}
 */
function getCloseButton(_a) {
    var props = _a.props, modal = _a.modal, setCompany = _a.setCompany;
    return React.createElement(Button, { variant: "secondary", onClick: function () {
            if (props.mode == 'add') {
                setCompany(Salary.COMPANY_INIT);
            }
            modal.onModalClose();
        } }, "Back");
}
/**
 * Get help text for companies from swagger docs
 *
 * @param key {string} Company data config key
 * @param docs {object} Swagger documentation object
 * @returns {*}
 */
function getHelpCompanies(key, docs) {
    var key_base = 'backend.properties.salarySystem.properties.companies.items.properties.';
    var help;
    switch (key) {
        default:
            var keys = key.split('.');
            var new_key = key_base + keys.join('.properties.');
            help = util.getNestedObject(docs, new_key).description;
            break;
    }
    return help;
}
/**
 * Get save button for company salary instruction data
 *
 * @param props {object} Input salary company properties
 * @param company {object} Current company object
 * @param setCompany {function} Set current company data
 * @param modal {object} Modal hook
 * @returns {JSX.Element}
 */
function getSaveButton(_a) {
    var props = _a.props, company = _a.company, setCompany = _a.setCompany, modal = _a.modal;
    return React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: function () {
                props.onChange({ value: Utils.clone(company) });
                modal.onModalClose();
            }, disabled: !company.info.company_name
                || !company.info.organization_number
                || props.other_customer_ids.includes(company.id)
                || (props.company_names
                    && props.company_names.includes(company.info.company_name)) }, "Save"),
        !company.info.company_name && React.createElement("div", { className: 'text-danger mt-1' }, "Missing required setting company name"),
        !company.info.organization_number && React.createElement("div", { className: 'text-danger mt-1' }, "Missing required setting organization number"),
        (props.other_customer_ids.includes(company.id)
            || (props.company_names
                && props.company_names.includes(company.info.company_name))) && React.createElement("div", { className: 'text-danger mt-1' }, "Company name can not match an already existing salary config customer name"));
}
/**
 * Render modal for input salary company
 *
 * @param props {props} Input salary company properties
 * @returns {*}
 * @constructor
 */
export default function ModalInputSalaryCompany(props) {
    var _a = useState(props.company || Salary.COMPANY_INIT), company = _a[0], setCompany = _a[1];
    var modal = props.modal || useModal();
    useEffect(function () {
        setCompany(props.company || Salary.COMPANY_INIT);
    }, [props.company]);
    return (React.createElement(Modal, { className: 'modal-input-salary-company', show: modal.modal_open, onHide: function () {
        } },
        React.createElement(Modal.Header, null,
            React.createElement(Modal.Title, null, props.title)),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "d-flex justify-content-between p-3 mt-3}" },
                React.createElement("div", null, getSaveButton({ props: props, company: company, setCompany: setCompany, modal: modal })),
                React.createElement("div", null, getCloseButton({ props: props, modal: modal, setCompany: setCompany }))),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: "col-12 col-lg-6 mb-5" },
                        React.createElement(InputTextSimpleView, { disabled: props.disabled, required: true, label: 'Company name', value: company.info.company_name || '', onChange: function (_a) {
                                var value = _a.value;
                                var info = Utils.clone(company.info);
                                info.company_name = value;
                                setCompany(__assign(__assign({}, company), { info: info }));
                            }, placeholder: 'Enter company name ...', help: getHelpCompanies('info.company_name', props.docs) }),
                        React.createElement(InputTextSimpleView, { disabled: props.disabled, required: true, label: 'Company organization number', value: company.info.organization_number || '', onChange: function (_a) {
                                var value = _a.value;
                                var info = Utils.clone(company.info);
                                info.organization_number = value;
                                setCompany(__assign(__assign({}, company), { info: info }));
                            }, placeholder: 'Add organization number ...', help: getHelpCompanies('info.organization_number', props.docs) }),
                        React.createElement(InputCheckboxSimpleView, { disabled: props.disabled, label: 'Use allowance version 1', onChange: function (_a) {
                                var value = _a.value;
                                var allowance = Utils.clone(company.allowance);
                                allowance.version_1_active = value;
                                setCompany(__assign(__assign({}, company), { allowance: allowance }));
                            }, value: company.allowance && company.allowance.version_1_active || false, help: getHelpCompanies('allowance.version_1_active', props.docs) }),
                        React.createElement(InputCheckboxSimpleView, { disabled: props.disabled, label: 'Use allowance version 2', onChange: function (_a) {
                                var value = _a.value;
                                var allowance = Utils.clone(company.allowance);
                                allowance.version_2_active = value;
                                setCompany(__assign(__assign({}, company), { allowance: allowance }));
                            }, value: company.allowance && company.allowance.version_2_active || false, help: getHelpCompanies('allowance.version_2_active', props.docs) }),
                        React.createElement(InputTextList, { label: 'Group of users salary addon', help_file_upload: 'Choose file to upload employee id. Needs to be row ' +
                                'separated. One employee id on each row.', placeholder: 'Add employee ids', text_list: company.salary_addon_employee_ids_group || [], onChange: function (_a) {
                                var text_list = _a.text_list;
                                setCompany(__assign(__assign({}, company), { salary_addon_employee_ids_group: text_list }));
                            } }),
                        React.createElement(InputSalaryInstructions, { instructions: Util.sort(company.instructions || [], function (v) { return v.data_type + v.employee_type; }), data_type_options: props.data_type_options, onChange: function (_a) {
                                var value = _a.value, index = _a.index, uid = _a.uid, remove = _a.remove;
                                debug('onChange', value, index, uid, remove);
                                if (remove) {
                                    setCompany(function (_a) {
                                        var instructions = _a.instructions;
                                        instructions = Util.clone(instructions);
                                        instructions = instructions.reduce(function (tot, val, i) {
                                            if (i != index) {
                                                tot.push(val);
                                            }
                                            return tot;
                                        }, []);
                                        return __assign(__assign({}, company), { instructions: instructions });
                                    });
                                }
                                else if (index == undefined) {
                                    setCompany(function (_a) {
                                        var instructions = _a.instructions;
                                        instructions = Util.clone(instructions);
                                        instructions.push(value);
                                        return __assign(__assign({}, company), { instructions: instructions });
                                    });
                                }
                                else if (uid != undefined) {
                                    setCompany(function (_a) {
                                        var instructions = _a.instructions;
                                        var index_exist = instructions.map(function (v) { return v.uid; }).findIndex(function (v) { return v == uid; });
                                        instructions = Util.clone(instructions);
                                        // let index_exist = index as keyof Instruction[]
                                        Object.assign(instructions[index_exist], value);
                                        debug('uid', instructions[index_exist], value, instructions, index_exist, uid);
                                        return __assign(__assign({}, company), { instructions: instructions });
                                    });
                                }
                                else {
                                    setCompany(function (_a) {
                                        var instructions = _a.instructions;
                                        instructions = Util.clone(instructions);
                                        var index_exist = index;
                                        Object.assign(instructions[index_exist], value);
                                        debug(instructions[index_exist], value, instructions, index_exist);
                                        return __assign(__assign({}, company), { instructions: instructions });
                                    });
                                }
                            } })),
                    React.createElement("div", { className: '.d-none .d-lg-block col-lg-6' },
                        React.createElement("h3", null, "Issues"),
                        props.issues.map(function (issue, index) { return React.createElement(Issue, __assign({ key: index }, { issue: issue, index: index })); })))),
            React.createElement(Modal.Footer, { className: "d-flex justify-content-between" },
                React.createElement("div", null, getSaveButton({ props: props, company: company, setCompany: setCompany, modal: modal })),
                React.createElement("div", null, getCloseButton({ props: props, modal: modal, setCompany: setCompany }))))));
}
