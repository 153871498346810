/**
 *Created by Mikael Lindahl on 2019-10-21
 */

"use strict";

const j2s = require('joi-to-swagger');
const schema = require('./schema');

module.exports=function(){

    const {swagger} = j2s(schema.config);

    return {
        swagger:'2.0',
        info: {
            description: "This is the documentation for customer config using " +
                "joi-to-swagger [https://www.npmjs.com/package/joi-to-swagger] " +
                "",
            version: "1.0.0",
            title: "Documentation Customer Config"
        },
        tags:[
            {
                "name": "config",
                "description": "Access config"
            }
        ],
        definitions: {
            config: {
                ...swagger
            }
        }
    };

};