/**
 *Created by Mikael Lindahl on 2019-10-30
 */
"use strict";
export default {
    // A global config thingy
    // absence: ["VAB", "Sen", "Sjuk", "ATF", "Permission", "Permitteringslön",
    //     "Fackligt arbete", "Ledig", "Helglön", "Pappaledig", "Semester",
    //     "Föräldraledig", "Tjänstledig", "Begravning", "Kompledig", "Möte/utbildning",
    //     "Ogiltig frånvaro", "Smittbärarpenning"],
    project_types: ['Löpanderäkning', 'Fastpris'],
    on_call_types: ['Beredskap vardag', 'Beredskap helg', 'Beredskap storhelg'],
    fields: ['name', 'unit']
};
