/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import "./InputTextList.scss";
import util from "../../lib/util";
var debug = require('debug')('bk-manager:frontend-react:components:inputs:InputTextList');
/**
 * Render input for adding text to a list
 */
var InputTextList = /** @class */ (function (_super) {
    __extends(InputTextList, _super);
    function InputTextList(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            text: ''
        };
        _this.fileInput = React.createRef();
        _this.onAdd = _this.onAdd.bind(_this);
        _this.onDelete = _this.onDelete.bind(_this);
        _this.onChangeFile = _this.onChangeFile.bind(_this);
        _this.getEdit = _this.getEdit.bind(_this);
        return _this;
    }
    /**
     * On change file load file data and add to list
     *
     * @param event {object} Input change event
     */
    InputTextList.prototype.onChangeFile = function (event) {
        var _this = this;
        event.preventDefault();
        var file = this.fileInput.current.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            var data = reader.result;
            var text_list = data.split('\n').map(function (qt) { return qt.trimEnd(); });
            text_list = text_list.filter(function (v) { return v != undefined; });
            text_list = text_list.filter(function (v) { return v != ''; });
            text_list = text_list.concat(_this.props.text_list);
            text_list = util.unique(text_list, function (v) { return v; }, ['', undefined]);
            _this.props.onChange({ text_list: text_list });
        };
        reader.onerror = function (e) {
            alert('Something went wrong. Please check format of text file.');
        };
        reader.readAsText(file);
    };
    /**
     * Add text to list
     */
    InputTextList.prototype.onAdd = function () {
        var text_list = [this.state.text].concat(this.props.text_list || []);
        text_list = util.unique(text_list);
        this.setState({ text: '' });
        this.props.onChange({ text_list: text_list });
    };
    /**
     * Delete text from list
     *
     * @param text {string} Text in list to remove
     */
    InputTextList.prototype.onDelete = function (text) {
        var text_list = this.props.text_list;
        text_list = text_list.reduce(function (tot, val) {
            if (val != text) {
                tot.push(val);
            }
            return tot;
        }, []);
        this.props.onChange({ text_list: text_list });
    };
    /**
     * Get element for show text in list
     *
     * @param text {string} Text to show
     * @returns {JSX.Element}
     */
    InputTextList.prototype.getEntry = function (text) {
        var _this = this;
        return (React.createElement("div", { key: text, className: 'quick-text d-flex-flex-row mx-1' },
            React.createElement("div", null,
                text,
                !this.props.disabled && React.createElement("i", { className: 'delete fas fa-times-circle ml-2', onClick: function () { return _this.onDelete(text); } }))));
    };
    /**
     * Render text edit input
     *
     * @returns {JSX.Element}
     */
    InputTextList.prototype.getEdit = function () {
        var _this = this;
        return (React.createElement("div", null,
            !this.props.exclude_file_upload && React.createElement(React.Fragment, null,
                React.createElement("input", { type: "file", ref: this.fileInput, disabled: this.props.disabled, onChange: this.onChangeFile, className: "form-control" }),
                React.createElement("small", { className: "form-text text-muted mb-4" }, this.props.help_file_upload || 'Choose file to upload quick text. Needs to be row separated. One quick text word on each row.' +
                    'Please limit number of words to 20 and make sure words display nice in mobile (not to long ~30 ' +
                    'character max)')),
            React.createElement("div", { className: 'd-flex flex-row' },
                React.createElement("input", { type: "text", value: this.state.text, disabled: this.props.disabled, onChange: function (e) { return _this.setState({ text: e.target.value }); }, className: "form-control", placeholder: this.props.placeholder || 'Add quick text ...' }),
                React.createElement("button", { className: 'btn btn-primary', onClick: this.onAdd }, "Add")),
            React.createElement("small", { className: "form-text text-muted mb-4" }, this.props.help || 'Add quick text manually. Please limit number of words to 20 and make sure words display nice ' +
                'in mobile (not to long ~30 character max)')));
    };
    InputTextList.prototype.render = function () {
        var _this = this;
        var text_list = this.props.text_list;
        text_list = text_list || [];
        return (React.createElement("div", { className: "input-quick-text form-group d-flex flex-column align-items-start" },
            this.props.label && React.createElement("label", null, this.props.label),
            !this.props.disabled && this.getEdit(),
            React.createElement("div", { className: 'quick-text-list d-flex flex-wrap' }, text_list.map(function (text) { return _this.getEntry(text); }))));
    };
    return InputTextList;
}(Component));
export default InputTextList;
