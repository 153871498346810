import React, { useState, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';
import './InputSortableGrid.scss';
import util from "../../lib/util";
var debug = require('debug')('bk-manager:frontend-react:components:SortableGrid');
/**
 * Render a sortable grid
 *
 * @param props {object} Input sortable grid properties
 * @returns {JSX.Element}
 * @constructor
 */
export default function InputSortableGrid(props) {
    var _a = useState(props.items.map(function (val, i) { return ({ id: i, val: val, disabled: true }); })), state = _a[0], setState = _a[1];
    useEffect(function () {
        setState(props.items.map(function (val, i) { return ({ id: i, val: val, disabled: true }); }));
    }, [props.items]);
    var items = state.map(function (item, key) {
        return React.createElement("div", { className: !props.disabled ? 'grid-square' : 'grid-square disabled', key: key }, "".concat(key, " ").concat(item.val));
    });
    return (React.createElement("div", { className: "row" },
        !props.disabled &&
            React.createElement(ReactSortable, { list: state, setList: function (newState) {
                    var newItems = newState.map(function (item) { return item.val; });
                    var items = state.map(function (item) { return item.val; });
                    if (!util.compare(newItems, items)) {
                        props.onChange(newItems);
                    }
                    setState(newState);
                } }, items),
        props.disabled && items));
}
;
