/**
 *Created by Mikael Lindahl on 2019-10-29
 */
"use strict";
var exclude = {
    table: [
        'backend.salarySystem.codes'
    ],
    grid_time_types: {
        'UE-tid': ['Project running user',
            'Project running supervisor',
            'Project fixed user',
            'Project fixed supervisor',
            // 'Project included ackord',
            'Service'
        ]
    }
};
export default exclude;
