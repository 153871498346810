import util from "../lib/util";
var debug = require('debug')('bk-manager:frontend-react:hooks:useHandleChangeSelectReact');
export function useHandleChangeSelectReact(_a) {
    var isMulti = _a.isMulti, onChange = _a.onChange, options = _a.options, selected = _a.selected, setOptions = _a.setOptions, setSelected = _a.setSelected;
    var handleChangeSelectReact = function (newValue, actionMeta) {
        debug('handleChangeSelectReact', newValue, actionMeta);
        var next_options = options;
        switch (actionMeta.action) {
            case 'create-option':
                if (isMulti) {
                    var value = newValue;
                    next_options = util.unique(value.concat(next_options), function (v) { return v.value; });
                }
                else {
                    next_options.push(newValue);
                }
                // selected = newValue;
                break;
            case 'clear':
            case 'remove-value':
            case 'select-option':
                // selected = newValue;
                break;
        }
        var data;
        if (isMulti) {
            var _newValue = newValue;
            data = {
                value: _newValue ? _newValue.map(function (v) { return v.value; }) : [],
            };
            // props: this.props
        }
        else {
            var _newValue = newValue;
            data = {
                value: _newValue ? _newValue.value : '',
                // props: this.props
            };
        }
        debug(next_options, newValue, data);
        setOptions(next_options);
        setSelected(newValue);
        onChange(data);
        //
        // if (!Array.isArray(selected)) {
        //     selected = [selected]
        // }
        //
        // this.setState({options, selected:newValue},
        //     _ => this.props.onChange(data))
    };
    return { handleChangeSelectReact: handleChangeSelectReact };
}
