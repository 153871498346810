/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
import React, { useState } from "react";
import './InputColor.scss';
import { Button } from "react-bootstrap";
var debug = require('debug')('bk-manager:frontend-react:components:InputColor');
/**
 *
 * Render input color
 *
 * @param props {object} Input color properties
 * @returns {*}
 * @constructor
 */
export default function InputColor(props) {
    var _a = useState(props.value), value = _a[0], setValue = _a[1];
    var _b = useState(false), edit = _b[0], setEdit = _b[1];
    return (React.createElement("div", { className: 'd-flex flex-row input-color' },
        React.createElement("input", { type: "color", disabled: props.disabled, onFocus: function () { return setEdit(true); }, onChange: function (event) {
                debug('set', event.target.value);
                setValue(event.target.value);
            }, value: value, name: props.name, className: "form-control" }),
        edit && React.createElement(Button, { onClick: function () {
                props.onChange({ value: value });
                setEdit(false);
            } }, "Done")));
}
