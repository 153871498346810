/**
 *Created by Mikael Lindahl on 2022-05-16
 */
import React, { useState } from 'react';
import InputTextList from "./InputTextList";
import url from "../../constants/url.jsx";
/**
 * Input element for units
 *
 * @param props {object} Input units properties
 * @returns {JSX.Element}
 * @constructor
 */
export default function InputUnit(props) {
    var _a = useState(props.units), units = _a[0], setUnits = _a[1];
    return React.createElement(InputTextList, { exclude_file_upload: true, disabled: props.disabled, label: props.label, text_list: units, onChange: function (_a) {
            var text_list = _a.text_list;
            var payload = {
                units: text_list
            };
            console.log(payload);
            fetch(url.GLOBAL_CONFIG_UNITS, {
                method: 'PUT',
                body: JSON.stringify(payload)
            }).catch(function (e) {
                console.log(e);
                alert('Something when wrong saving to db...');
            });
            setUnits(text_list);
        }, placeholder: 'Add unit', help: 'Add or remove unit' });
}
