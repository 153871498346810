/**
 *Created by Mikael Lindahl on 2019-10-30
 */

"use strict";

module.exports = {
  backend: {
    absenceTimeTypes: [
      "VAB",
      "Sen",
      "Sjuk",
      "ATF",
      "Permission",
      "Permitteringslön",
      "Fackligt arbete",
      "Ledig",
      "Helglön",
      "Pappaledig",
      "Semester",
      "Föräldraledig",
      "Tjänstledig",
      "Begravning",
      "Kompledig",
    ],
    company: "",
    companyName: "",
    cron: {
      automaticReporter: true,
      cleanGlobalData: true,
      projectSummariesValidation: true,
      pushNotificationFriday: true,
      pushNotificationMonday: true,
      removalProjectChecklists: true,
      removeAdditionalHistoryInformation: true,
      userEducationReminder: true,
      userMissingHoursAndAttests: true,
      verifyDateAndSendCompleteUserCount: true,
    },
    decidedHoursNegative: ["UE-tid"],
    language: "sv",
    legacyWebDomain: "",
    locationBasedReporting: false,
    salarySystem: {
      default_company: "",
      legacy: false,
      type: "none",
    },
    supervisorReporting: false,
    notifySupervisorNewATA: false,
    overtimeRules: [],
  },
  mobile: {
    absenceTimeTypes: [
      "time_type_vab",
      "time_type_late",
      "time_type_sick",
      "time_type_atf",
      "time_type_permission",
      "time_type_permission_salary",
      "time_type_union_work",
      "time_type_free_time",
      "time_type_weekend_salary",
      "time_type_paternal_leave",
      "time_type_vacation",
      "time_type_parental_leave",
      "time_type_on_leave",
      "time_type_funeral",
      "time_type_compensation_free",
    ],
    accentColor: "#D66E37",
    ataForAll: false,
    bulletinBoardEnabled: true,
    clientType: 2,
    endingSalaryDay: 0,
    enableReportingProjectRunningMaterialEquipment: false,
    knowledgeBaseUrl: "",
    locationBasedReporting: false,
    mainMenu: {
      handbook: true,
      myinbox: true,
      myreports: true,
      projects: true,
      serviceorders: true,
      settings: true,
    },
    mebReportFlow: false,
    primaryColor: "#006450",
    projectMenu: {
      ata: true,
      checklists: true,
      deviations: true,
      diaries: false,
      documents: true,
      drivingExpense: false,
      hazard1: false,
      hazard2: false,
      notes: true,
      questionsAnswer: false,
      reporting: true,
      safety_round: false,
      traktamenteOne: true,
      traktamenteTwo: false,
    },
    quickText: [],
    remoteDrivingTypes: {
      project: [],
      serviceorder: [],
    },
    remoteTimeTypes: {
      projectFixed: [],
      projectFixedLagbas: [],
      projectRunning: [],
      projectRunningLagbas: [],
      serviceorder: [],
      subSupplier: ["time_type_ue"],
    },
    serviceorderQuickText: [],
    startingSalaryDay: 1,
    supervisorReporting: false,
    timeTypes: {
      subSupplier: ["time_type_ue"],
    },
    serviceTypes: [],
    time: {
      minTimeInterval: 0.5,
    },
  },
  web: {
    absenceTimeTypes: [
      "VAB",
      "Sen",
      "Sjuk",
      "ATF",
      "Permission",
      "Permitteringslön",
      "Fackligt arbete",
      "Ledig",
      "Helglön",
      "Pappaledig",
      "Semester",
      "Föräldraledig",
      "Tjänstledig",
      "Begravning",
      "Kompledig",
    ],
    active_modules: {
      attest: true,
      attestOvertimeSummary: false,
      equipment: true,
      material: true,
      mebReportFlow: false,
      personal: {
        absence: true,
        board: true,
        education: true,
        handbook: true,
        users: true,
      },
      project: {
        ATA: true,
        QA: false,
        checklist: true,
        deviations: true,
        diary: true,
        documents: true,
        internal_documents: true,
        invoices: true,
        ml_proj_overrun: false,
        notes: true,
        reports: true,
        safety_round: false,
        timeplan: true,
      },
      salary_integration: "hogia",
      service: true,
      settings: {
        subcompanies: false,
        absenceReporting: false,
      },
      time: {
        ata: true,
        driving: true,
        equipment: true,
        material: true,
        time: true,
      },
      webReporting: true,
      attestMenu: {
        project: true,
        service: true,
      },
    },
    allowAnyoneToAddChecklist: false,
    ataTypes: [],
    serviceTypes: [],
    companyName: "",
    diary: { allowEveningTemp: false },
    equipment: {
      allowEquipmentReporting: true,
      fields: ["name", "unit"],
    },
    hazardName: "",
    invoicing: {
      fastpris: {
        drivingTypes: [],
        timeTypes: ["UE-tid"],
      },
      löpanderäkning: {
        drivingTypes: [],
        timeTypes: ["UE-tid"],
      },
      service: {
        drivingTypes: [],
        timeTypes: ["UE-tid"],
      },
    },
    locationBasedReporting: false,
    material: {
      allowMaterialReporting: true,
      fields: ["name", "unit"],
    },
    overtimeTimeTypes: [],
    projectTypes: ["Löpanderäkning", "Fastpris"],
    remainingHoursTimeTypes: ["UE-tid"],
    reportingStartingDate: 1,
    theme: {
      primaryColor: "#006450",
      secondaryColor: "#F1F1F1",
      datepickerColor: "#FFFFFF",
      datepickerSndColor: "#006450",
      navTextColor: "#006450",
      settingsReportColor: "#FFFFFF",
    },
    time: {
      allowDiaryReporting: true,
      allowExtraTime: true,
      allowSeeingOwnTimeReporting: { "non-supervisor": true },
      allowTimeReporting: { "non-supervisor": true, supervisor: true },
      ataForAll: false,
      minHourValue: "0.5",
      minTimeInterval: 0.5,
      multiusertimereporting: { supervisor: false },
      subSupplierTimeTypes: ["UE-tid"],
      timeTypes: {
        ackord: {
          "non-supervisor": [],
          supervisor: [],
        },
        timlön: {
          "non-supervisor": [],
          supervisor: [],
        },
      },
    },
    timeTypes: {
      project: {
        Fastpris: {
          "non-supervisor": "Ackordslön",
          supervisor: "Ackordslön (Lagbas)",
        },
        other: {
          "non-supervisor": "Timlön",
          supervisor: "Lagbaslön",
        },
      },
      types: [],
    },
    travel: {
      allowTraktamente: true,
      allowTravel: true,
      autoCountPassengerDistance: true,
      fromToLocation: "Ort, från - till",
      project: { drivingTypes: [] },
      service: { drivingTypes: [] },
      traktamenteOne: true,
      traktamenteTwo: false,
    },
  },
};
