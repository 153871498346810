/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
import React from "react";
import "./InputCheckboxSimpleView.scss";
import InputCheckbox from "./InputCheckbox";
/**
 *
 * Extended InputCheckbox component. Input Checkbox plus label and help text
 *
 * @param props {object} Input checkbox simple properties
 * @returns {*}
 * @constructor
 */
export default function InputCheckboxSimpleView(props) {
    var help = props.help, name = props.name, value = props.value;
    return (React.createElement("div", { className: "input-checkbox-simple-view form-group d-flex flex-column align-items-start" },
        React.createElement("label", null, props.label),
        React.createElement("div", { className: 'd-flex flex-row' },
            React.createElement(InputCheckbox, { disabled: props.disabled, onChange: props.onChange, value: value }),
            React.createElement("label", { className: props.disabled && 'disabled', htmlFor: name }),
            help && React.createElement("div", null,
                React.createElement("small", { className: "form-text text-muted" }, help)))));
}
