var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from "react";
import ConfigTool from "../../lib/config";
import "./ConfigTableView.scss";
import Issue from "../Issue";
import RouterLink from "../RouterLink";
import { validate } from "config-validation";
import TableConfig from "../tables/TableConfig";
import ButtonWithTooltip from "../ButtonWithTooltip";
var debug = require('debug')('bk-manager:frontend-react:components:ConfigTableView');
/**
 * Render config table view
 */
var ConfigTableView = /** @class */ (function (_super) {
    __extends(ConfigTableView, _super);
    function ConfigTableView(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            page_size: _this.props.page_size
        };
        _this.onReset = _this.onReset.bind(_this);
        return _this;
    }
    /**
     * On reset config
     */
    ConfigTableView.prototype.onReset = function () {
        debug('onRest');
        this.props.setPageState({ config_edit: {} });
    };
    ConfigTableView.prototype.render = function () {
        var error = validate(ConfigTool.get(this.props), this.props.translation.original).error;
        return (React.createElement("div", { className: 'container' },
            React.createElement("div", { className: 'd-flex flex-row' },
                this.props.back && React.createElement(RouterLink, __assign({}, this.props.back)),
                this.props.continue && React.createElement(RouterLink, __assign({}, this.props.continue)),
                this.props.to_submit && React.createElement(RouterLink, __assign({}, this.props.to_submit)),
                React.createElement(ButtonWithTooltip, { className: 'ml-auto', label: 'Reset edits', onClick: this.onReset }),
                React.createElement(ButtonWithTooltip, { className: 'ml-3', label: 'Sync with db', tooltip: 'Synchronize with remote database. Does not reset edits.', onClick: function () { return location.reload(); } })),
            React.createElement("div", { className: 'row config-table-view' },
                React.createElement("div", { className: 'col-12' },
                    React.createElement(TableConfig, { absence_time_types: this.props.absence_time_types, config: this.props.config, config_edit: this.props.config_edit, page_size: this.props.page_size, type: this.props.type, disabled: this.props.disabled, translation: this.props.translation, setPageState: this.props.setPageState })),
                React.createElement("div", { className: '.d-none .d-lg-block col-12' },
                    React.createElement("h3", null, "Issues"),
                    error.map(function (issue, index) { return React.createElement(Issue, __assign({ key: index }, { issue: issue, index: index })); })),
                React.createElement("div", { className: 'd-flex flex-row' },
                    this.props.back && React.createElement(RouterLink, __assign({}, this.props.back)),
                    this.props.continue && React.createElement(RouterLink, __assign({}, this.props.continue)),
                    this.props.to_submit && React.createElement(RouterLink, __assign({}, this.props.to_submit))))));
    };
    return ConfigTableView;
}(Component));
export default ConfigTableView;
