/**
 *Created by Mikael Lindahl on 2019-12-10
 */
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import './InputFile.scss';
import { Button } from "react-bootstrap";
import * as xlsx from 'xlsx';
var debug = require('debug')('bk-manager:frontend-react:components:InputFile');
/**
 * Convert csv to json
 *
 * @param data {string} Text with csv data
 * @param delimiter {string} Csv data delimiter
 * @returns {*[]}
 */
function csvToJson(data, delimiter) {
    var data_rows = data.trim().split('\n');
    var header = data_rows[0].split(delimiter);
    var rows = data_rows.slice(1);
    var result = [];
    for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
        var row = rows_1[_i];
        var element = row.split(delimiter).reduce(function (tot, val, i) {
            tot[header[i]] = val;
            return tot;
        }, {});
        result.push(element);
    }
    return result;
}
/**
 * Render file input
 */
var InputFile = /** @class */ (function (_super) {
    __extends(InputFile, _super);
    function InputFile(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            file_name: 'No file selected',
            error: '',
            sheets: [],
            selected_sheet: '',
            success: ''
        };
        _this.fileInput = React.createRef();
        _this.onClear = _this.onClear.bind(_this);
        _this.onChangeFile = _this.onChangeFile.bind(_this);
        _this.onSuccess = _this.onSuccess.bind(_this);
        return _this;
    }
    InputFile.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.error !== '') {
            this.setState({ success: '' });
        }
    };
    /**
     * Set file_name to "No file selected"
     */
    InputFile.prototype.onClear = function () {
        this.setState({ file_name: 'No file selected' });
    };
    /**
     * On change file process file and convert to data object depending
     * on if it is a json, csv or xlsx file
     *
     * @param event {object} Input on change event
     */
    InputFile.prototype.onChangeFile = function (event) {
        var _this = this;
        var self = this;
        event.preventDefault();
        this.setState({ error: '', success: '', sheets: [], selected_sheet: '' });
        var file = this.fileInput.current.files[0];
        var file_type = file.name.split('.')[file.name.split('.').length - 1];
        debug('onChangeFile', file_type, file);
        if (!this.props.allowed_file_types.includes(file_type)) {
            var allowed_file_types = this.props.allowed_file_types.join(', ');
            return this.setState({ error: "Wrong file type ".concat(file_type, ". Allowed file types are ").concat(allowed_file_types) });
        }
        this.setState({ file_name: file.name });
        var reader = new FileReader();
        reader.onloadend = function (e) {
            debug(event, file_type);
            var data = reader.result;
            var data_parsed;
            if (file_type == 'json') {
                data_parsed = JSON.parse(data);
                _this.onSuccess({ data: data_parsed, file_type: file_type });
            }
            else if (file_type == 'csv' && self.props.csv_to_json) {
                data_parsed = csvToJson(data, self.props.csv_to_json_delimiter);
                _this.onSuccess({ data: data_parsed, file_type: file_type });
            }
            else if (file_type == 'xlsx') {
                var workbook_1 = xlsx.read(data, {
                    type: 'binary'
                });
                var sheets_1 = [{ 'sheet_name': '', data: {} }];
                workbook_1.SheetNames.forEach(function (sheet_name) {
                    // Here is your object
                    var sheet_data = xlsx.utils.sheet_to_json(workbook_1.Sheets[sheet_name], { defval: "" });
                    var data_parsed_xlsx = {};
                    for (var _i = 0, sheet_data_1 = sheet_data; _i < sheet_data_1.length; _i++) {
                        var row = sheet_data_1[_i];
                        data_parsed_xlsx[row.key] = row;
                    }
                    data_parsed = data_parsed_xlsx;
                    sheets_1.push({ sheet_name: sheet_name, data: data_parsed });
                });
                if (sheets_1.length < 3) {
                    _this.onSuccess({ data: sheets_1[1].data, file_type: file_type });
                }
                else {
                    self.setState({ sheets: sheets_1 });
                }
            }
        };
        reader.onerror = function (e) {
            alert('Something went wrong. Please check format of text file.');
        };
        if (file_type == 'xlsx') {
            reader.readAsBinaryString(file);
        }
        else {
            reader.readAsText(file);
        }
    };
    /**
     * On success update success state and call props callback onChange
     *
     * @param data {object} Input data as json object
     * @param file_type {string} csv | json | xlsx
     */
    InputFile.prototype.onSuccess = function (_a) {
        var data = _a.data, file_type = _a.file_type;
        var n_rows = 0;
        for (var key in data) {
            n_rows++;
        }
        this.setState({ success: 'Imported ' + n_rows + ' rows' });
        this.props.onChange({ data: data, file_type: file_type, file_name: this.state.file_name });
    };
    InputFile.prototype.render = function () {
        var self = this;
        return (React.createElement("div", { className: "input-file form-group mb-0" },
            React.createElement("label", { style: { display: this.props.label ? 'inline-block' : 'none' } }, this.props.label),
            this.props.help && React.createElement("small", { className: "form-text text-muted" }, this.props.help),
            React.createElement("div", { className: 'd-flex flex-row mt-2' },
                React.createElement(Button, { onClick: function () {
                        if (self.fileInput) {
                            self.fileInput.current.click();
                        }
                    }, disabled: this.props.disabled }, "Open file"),
                React.createElement("input", { type: "file", ref: this.fileInput, disabled: this.props.disabled, onChange: this.onChangeFile, key: this.state.file_name, className: "form-control" })),
            React.createElement("div", null,
                this.state.error == '' && React.createElement("div", { className: 'pt-1' },
                    this.state.file_name,
                    " "),
                this.state.error != '' && React.createElement("div", { className: 'text-danger pt-1' },
                    this.state.error,
                    " ")),
            React.createElement("div", { className: 'd-flex flex-column mt-2' }, this.state.sheets.length != 0 && React.createElement(React.Fragment, null,
                React.createElement("small", null, 'Select sheet'),
                React.createElement("select", { className: "input-select custom-select", value: this.state.selected_sheet, onChange: function (event) {
                        self.setState({ selected_sheet: event.target.value });
                        if (event.target.value != '') {
                            for (var _i = 0, _a = self.state.sheets; _i < _a.length; _i++) {
                                var sheet = _a[_i];
                                if (sheet.sheet_name == event.target.value) {
                                    self.onSuccess({ data: sheet.data, file_type: 'xlsx' });
                                }
                            }
                        }
                    } }, this.state.sheets.map(function (o) {
                    return React.createElement("option", { key: o.sheet_name, value: o.sheet_name }, o.sheet_name);
                })))),
            React.createElement("div", null, this.state.success != ''
                && this.props.error == ''
                && React.createElement("div", { className: 'text-success pt-1 pl-2' },
                    this.state.success,
                    " ")),
            this.props.clear_button && React.createElement(Button, { onClick: this.onClear }, "Clear file input")));
    };
    InputFile.defaultProps = {
        allowed_file_types: ['json'],
        csv_to_json_delimiter: ',',
    };
    return InputFile;
}(Component));
export default InputFile;
