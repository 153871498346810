/**
 *Created by Mikael Lindahl on 2020-06-09
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import PropTypes from "prop-types";
import InputSelectSimpleView from "./InputSelectSimpleView";
import InputFile from "./InputFile";
import util from "../../lib/util";
import url from "../../constants/url.jsx";
import translationConfigTables from "../../constants/translationConfigTables";
import { Button, Modal } from "react-bootstrap";
import useModal from "../../hooks/useModal";
import csvDelimiters from "../../constants/csvDelimiters";
var debug = require('debug')('bk-manager:frontend-react:components:InputTranslationFile');
/**
 * On change callback for translation file upload
 *
 * @param data {object} Translation data to update or replave with
 * @param import_type  {string} Import type for translation file replace | update
 * @param file_type {string} File type json | csv
 * @param onChange {function} On change callback
 * @param setError {function} Set error text
 * @param translation {object} Current translation object
 * @param setCount {function} Set change count
 * @param translation_edit {object} Current translation edit object
 * @returns {*}
 */
function onChange(_a) {
    var data = _a.data, import_type = _a.import_type, file_type = _a.file_type, onChange = _a.onChange, setError = _a.setError, translation = _a.translation, setCount = _a.setCount, translation_edit = _a.translation_edit;
    if (file_type == 'csv') {
        var tmp = {};
        var csv_data = data;
        debug('csv_data', csv_data);
        for (var _i = 0, csv_data_1 = csv_data; _i < csv_data_1.length; _i++) {
            var d = csv_data_1[_i];
            tmp[d.key] = d;
        }
        data = tmp;
        // data = tmp
    }
    debug('data', data);
    data = data;
    var row = file_type == 'json' ? 1 : 2;
    for (var key in data) {
        if (!data[key].key) {
            setError("Missing \"key\" field in imported ".concat(file_type, " row ").concat(row, " and data\n") + JSON.stringify(data[key], null, 2));
            return;
        }
        row++;
    }
    debug('data', data);
    row = file_type == 'json' ? 1 : 2;
    for (var key in data) {
        if (!data[key].eligible_for
            || !translationConfigTables.options.map(function (v) { return v.value; }).includes(data[key].eligible_for)) {
            setError(React.createElement("div", null,
                React.createElement("div", null, "Missing/wrong \"eligible_for\" field in imported ".concat(file_type, " for row ").concat(row, " and data:")),
                React.createElement("div", null, "".concat(JSON.stringify(data[key], null, 2), " '}")),
                React.createElement("div", null, "".concat('Need to be one of [' + translationConfigTables.options.map(function (v) { return v.value; }).join(', ') + ']'))));
            return;
        }
        row++;
    }
    var update = {};
    var all_locales = util.getLocales([data, translation], []);
    var data_locales = util.getLocales([data], []);
    for (var key in data) {
        if (!update[key]) {
            update[key] = {};
        }
        for (var locale in data[key]) {
            var text_original = translation[key] && translation[key][locale] !== undefined
                ? translation[key][locale].text
                : null;
            if (text_original === data[key][locale]) {
                continue;
            }
            var val = data[key][locale];
            var action = text_original !== null ? 'edited' : 'created';
            var args = {
                action: action,
                text_original: text_original,
                text: data[key][locale] ? val : '',
                is_created: text_original == null
            };
            var entry = util.generateTranslationEdit(args);
            update[key][locale] = entry;
        }
    }
    if (import_type == 'replace') {
        for (var key in translation) {
            for (var locale in translation[key]) {
                if (!data_locales.includes(locale)) {
                    var entry = util.generateTranslationEdit({
                        action: 'deleted',
                        text_original: translation[key][locale] ? translation[key][locale].text : null,
                        text: translation[key][locale] ? translation[key][locale].text : '',
                        is_created: translation[key][locale] === undefined,
                        is_deleted_row: false,
                        is_deleted_column: true
                    });
                    if (!update[key]) {
                        update[key] = {};
                    }
                    update[key][locale] = entry;
                }
            }
        }
    }
    var all_key = util.getKeys([translation, data]);
    // Create empty entries if new locales not present in translation and
    // value is missing for the key in imported data
    for (var _b = 0, all_key_1 = all_key; _b < all_key_1.length; _b++) {
        var key = all_key_1[_b];
        for (var _c = 0, all_locales_1 = all_locales; _c < all_locales_1.length; _c++) {
            var locale = all_locales_1[_c];
            if (!update[key]) {
                update[key] = {};
            }
            var entry = void 0;
            // Deletion
            if (import_type == 'replace' && (!data[key] || data[key][locale] === undefined)) {
                entry = util.generateTranslationEdit({
                    action: 'deleted',
                    text_original: translation[key] && translation[key][locale] ? translation[key][locale].text : null,
                    text: translation[key] && translation[key][locale] ? translation[key][locale].text : '',
                    is_created: translation[key] === undefined || translation[key][locale] === undefined,
                    is_deleted_row: !data[key],
                    is_deleted_column: !data_locales.includes(locale)
                });
            }
            else if ( // Create entry if locale is missing and data has key but also missing locale
            (!translation[key] || translation[key][locale] === undefined)
                && (!data[key] || (data[key] && data[key][locale] === undefined))
            // )
            ) {
                entry = util.generateTranslationEdit({
                    action: 'created',
                    text_original: '',
                    text: '',
                    is_created: true
                });
            }
            if (entry) {
                update[key][locale] = entry;
            }
        }
    }
    var count = {
        created: 0,
        edited: 0,
        deleted: 0,
        default: 0
    };
    for (var key in update) {
        for (var attribute in update[key]) {
            count[update[key][attribute].action]++;
        }
    }
    setCount(count);
    // Updates that have not change between translation and translation_edit will be
    // discarded. I believe this is done in DataProvider
    // Always replace current edits when importing
    return onChange({ update: update, is_replace: true });
}
var count_default = {
    created: 0,
    edited: 0,
    deleted: 0,
};
/**
 * Render input translation file
 *
 * @param props {object} Input translation file properties
 * @returns {JSX.Element}
 * @constructor
 */
export default function InputTranslationFile(props) {
    var _a = useState(null), import_type = _a[0], setImportType = _a[1];
    var _b = useState(''), error = _b[0], setError = _b[1];
    var _c = useState(count_default), count = _c[0], setCount = _c[1];
    var _d = useState([]), data = _d[0], setData = _d[1];
    var _e = useState(''), file_name = _e[0], setFileName = _e[1];
    var _f = useState(null), file_type = _f[0], setFileType = _f[1];
    var _g = useModal(), onModalClose = _g.onModalClose, onModalOpen = _g.onModalOpen, modal_open = _g.modal_open;
    var link = React.createElement("a", { href: url.GLOBAL_CONFIG_FILE_TYPES, target: '_blank' }, "here");
    var params = {
        import_type: import_type,
        onChange: props.onChange,
        setError: setError,
        translation: props.translation,
        translation_edit: props.translation_edit
    };
    return React.createElement("div", { className: props.className },
        React.createElement(InputSelectSimpleView, { onChange: function (_a) {
                var value = _a.value;
                setError('');
                setCount(count_default);
                setImportType(value);
            }, value: import_type || '', help: 'What type of import do you like to perform?', options: [
                { label: '', value: null },
                { label: 'Update current translation', value: 'update' },
                { label: 'Replace current translation', value: 'replace' }
            ] }),
        React.createElement(InputFile, { allowed_file_types: ['json', 'csv', 'xlsx'], csv_to_json: true, csv_to_json_delimiter: csvDelimiters.TRANSLATION_CSV, disabled: import_type == null, help: React.createElement("span", null,
                'Please select a file to import (xlsx, semicolon separated .csv or .json). Examples of file formats can be found ',
                " ",
                link), onChange: function (_a) {
                var data = _a.data, file_type = _a.file_type, file_name = _a.file_name;
                setError('');
                setFileName(file_name);
                setData(data);
                setFileType(file_type);
                onModalOpen();
            } }),
        [
            'created',
            'edited',
            'deleted'
        ].map(function (s) {
            if (count[s] == 0) {
                return React.createElement("div", { key: s });
            }
            else {
                return React.createElement("div", { key: s, className: 'text-success pt-1 pl-2' }, "".concat(count[s], " ").concat(s, " ").concat(count[s] > 1 ? 'cells' : 'cell'),
                    " ");
            }
        }),
        error != '' && React.createElement("div", { className: "text-danger" }, error),
        React.createElement(Modal, { show: modal_open, onHide: onModalClose, onEnter: function () { return debug('enter!'); } },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Import data from file ".concat(file_name),
                    " ")),
            React.createElement(Modal.Body, null,
                React.createElement("p", null, "This action will remove all current updates made in the table. Do you want to continue?")),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "primary", onClick: function () {
                        onChange(__assign({ data: data, file_type: file_type, setCount: setCount }, params));
                        onModalClose();
                    } }, "Yes"),
                React.createElement(Button, { variant: "secondary", onClick: onModalClose }, "No"))));
}
InputTranslationFile.propTypes = {
    current: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    translation: PropTypes.object.isRequired,
    translation_edit: PropTypes.object.isRequired
};
