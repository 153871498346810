/**
 *Created by Mikael Lindahl on 2019-11-11
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import PouchDB from "pouchdb";
// import {IdMeta, GetMeta} from "@types/pouchdb"
import util from "./util";
var debug = require('debug')('bk-manager:frontend-react:lib:storage');
var db = new PouchDB('storage');
/**
 * Drop local browser pouchdb
 *
 * @returns {Promise<void>}
 */
function drop() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                //deleting database
                db.destroy({}, function (err, response) {
                    if (err) {
                        return console.log(err);
                    }
                    else {
                        console.log("Database Deleted");
                    }
                });
            }
            catch (err) {
                console.error(err);
            }
            return [2 /*return*/];
        });
    });
}
/**
 * Add data to local browser pouchdb
 *
 * @param id {string} Pouchdb identifier
 * @returns {Promise<null|*>}
 */
function get(id) {
    return __awaiter(this, void 0, void 0, function () {
        var data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, db.get(id)];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data];
                case 2:
                    err_1 = _a.sent();
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * Get storage key for storing data in pouchdb
 *
 * @param [uid] {string} Optional uid. if not provided then query uid is used
 * @returns {string}
 */
function getKey(uid) {
    return "".concat(typeof uid == 'string' ? uid : util.getQuery().uid, "-").concat(util.getTip());
}
/**
 * Remove data in local browser pouchdb
 *
 * @param id {string} Pouchdb identifier
 * @param key {string} Key to remove in pouchdb object
 * @returns {Promise<void>}
 */
function remove(id, key) {
    return __awaiter(this, void 0, void 0, function () {
        var doc, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    debug('remove');
                    return [4 /*yield*/, get(id)];
                case 1:
                    doc = _a.sent();
                    delete doc[key];
                    return [4 /*yield*/, db.put(doc)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    console.error(err_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
}
/**
 * Set data to local browser pouchdb
 *
 * @param _id {string} Pouchdb identifier
 * @param data {object} Data to set pouchdb record to
 * @returns {Promise<null|*>}
 */
function set(_id, data) {
    return __awaiter(this, void 0, void 0, function () {
        var current, doc, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 6, , 7]);
                    return [4 /*yield*/, get(_id)];
                case 1:
                    current = _a.sent();
                    if (!(data === null)) return [3 /*break*/, 3];
                    return [4 /*yield*/, db.remove(current)];
                case 2:
                    _a.sent();
                    return [2 /*return*/, null];
                case 3:
                    doc = __assign(__assign({ _id: _id }, current), data);
                    return [4 /*yield*/, db.put(doc)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, get(_id)];
                case 5: return [2 /*return*/, _a.sent()];
                case 6:
                    err_3 = _a.sent();
                    debug('set error');
                    console.error(err_3);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    });
}
export default {
    drop: drop,
    get: get,
    getKey: getKey,
    remove: remove,
    set: set
};
