/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
import React from "react";
import InputColor from "./InputColor";
var debug = require('debug')('bk-manager:frontend-react:components:InputColorSimpleView');
/**
 *
 * Extended InputColor component. Input Color plus label and help text
 *
 * @param props {object} Input color simple view properties
 * @returns {*}
 * @constructor
 */
export default function InputColorSimpleView(props) {
    return (React.createElement("div", { className: "input-color form-group" },
        React.createElement("label", null, props.label),
        React.createElement(InputColor, { disabled: props.disabled, onChange: function (_a) {
                var value = _a.value;
                return props.onChange({ value: value });
            }, value: props.value, name: props.name }),
        props.help && React.createElement("small", { className: "form-text text-muted" }, props.help)));
}
