var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from "react";
import Issue from "../Issue";
import "./ConfigIssueView.scss";
import PropTypes from 'prop-types';
import RouterLink from "../RouterLink";
var debug = require('debug')('bk-manager:frontend-react:components:ConfigIssueView');
/**
 * Render config issue view
 */
var ConfigIssueView = /** @class */ (function (_super) {
    __extends(ConfigIssueView, _super);
    function ConfigIssueView(props) {
        return _super.call(this, props) || this;
    }
    ConfigIssueView.prototype.render = function () {
        return (React.createElement("div", { className: "config-issue-view container" },
            this.props.issues.length > 0 && React.createElement("div", { className: 'd-flex flex-row' },
                this.props.back && React.createElement(RouterLink, __assign({}, this.props.back)),
                this.props.continue && React.createElement(RouterLink, __assign({}, this.props.continue)),
                this.props.to_submit && React.createElement(RouterLink, __assign({}, this.props.to_submit))),
            this.props.issues.map(function (issue, index) { return React.createElement(Issue, __assign({ key: index }, { issue: issue, index: index })); }),
            this.props.issues.length === 0 &&
                React.createElement("div", { className: 'congratulations' }, "Congratulations! No warning or conflicts"),
            React.createElement("div", { className: 'd-flex flex-row' },
                this.props.back && React.createElement(RouterLink, __assign({}, this.props.back)),
                this.props.continue && React.createElement(RouterLink, __assign({}, this.props.continue)),
                this.props.to_submit && React.createElement(RouterLink, __assign({}, this.props.to_submit)))));
    };
    ConfigIssueView.propTypes = {
        back: PropTypes.object,
        continue: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        issues: PropTypes.array
    };
    return ConfigIssueView;
}(Component));
export default ConfigIssueView;
