/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import url from "../constants/url.jsx";
import RoutingCreateEditShowDelete from "./RoutingCreateEditShowDelete";
import CustomerDocumentationView from "../components/views/CustomerDocumentationView";
import ConfigEventView from "../components/views/ConfigEventView";
import ConfigGlobalView from "../components/views/ConfigGlobalView";
import ConfigGlobalTranslationFileTypesView from "../components/views/ConfigGlobalTranslationFileTypesView";
import ConfigGlobalTranslationValidLocalesView from "../components/views/ConfigGlobalTranslationValidLocalesView";
import queryString from "query-string";
import DataProvider from "../components/DataProvider";
import ExportUserDataView from "../components/views/ExportUserDataView";
var debug = require('debug')('bk-manager:frontend-react:routers:RoutingApp');
/**
 * Main routing for the app
 */
var RoutingApp = /** @class */ (function (_super) {
    __extends(RoutingApp, _super);
    function RoutingApp(props) {
        var _this = _super.call(this, props) || this;
        _this.renderCreate = _this.renderCreate.bind(_this);
        _this.renderDocumentation = _this.renderDocumentation.bind(_this);
        _this.renderEdit = _this.renderEdit.bind(_this);
        _this.renderGlobalConfig = _this.renderGlobalConfig.bind(_this);
        _this.renderShow = _this.renderShow.bind(_this);
        return _this;
    }
    RoutingApp.prototype.renderCreate = function () {
        return (React.createElement(DataProvider, { endpoint: url.STATE, is_config: true, render: function (state) { return React.createElement(RoutingCreateEditShowDelete, __assign({ type: 'create' }, state)); } }));
    };
    RoutingApp.prototype.renderDelete = function () {
        return (React.createElement(DataProvider, { endpoint: url.STATE, is_config: true, render: function (state) { return React.createElement(RoutingCreateEditShowDelete, __assign({ type: 'delete' }, state)); } }));
    };
    RoutingApp.prototype.renderDocumentation = function () {
        return (React.createElement(CustomerDocumentationView, null));
    };
    RoutingApp.prototype.renderEdit = function () {
        return (React.createElement(DataProvider, { endpoint: url.STATE, is_config: true, render: function (state) { return React.createElement(RoutingCreateEditShowDelete, __assign({ type: 'edit' }, state)); } }));
    };
    RoutingApp.prototype.renderEvent = function () {
        var query = queryString.parse(location.search);
        var endpoint = url.EVENT(query.uid);
        console.log(endpoint, query);
        return (React.createElement(DataProvider, { endpoint: endpoint, render: function (state) { return React.createElement(ConfigEventView, __assign({}, state)); } }));
    };
    RoutingApp.prototype.renderGlobalConfig = function () {
        return (React.createElement(DataProvider, { endpoint: url.GLOBAL_CONFIG, render: function (state) { return React.createElement(ConfigGlobalView, __assign({}, state)); } }));
    };
    RoutingApp.prototype.renderExportUserData = function () {
        debug('hej');
        return (React.createElement(DataProvider, { endpoint: url.EXPORT_USER_DATA, render: function (state) { return React.createElement(ExportUserDataView, { user_emails_instances: state.user_emails_instances }); } }));
    };
    RoutingApp.prototype.renderGlobalConfigTranslationFileTypes = function () {
        return React.createElement(ConfigGlobalTranslationFileTypesView, null);
    };
    RoutingApp.prototype.renderGlobalConfigTranslationValidLocales = function () {
        return React.createElement(ConfigGlobalTranslationValidLocalesView, null);
    };
    RoutingApp.prototype.renderShow = function () {
        return (React.createElement(DataProvider, { endpoint: url.STATE, is_config: true, render: function (state) { return React.createElement(RoutingCreateEditShowDelete, __assign({ type: 'show', disabled: true }, state)); } }));
    };
    RoutingApp.prototype.render = function () {
        return (React.createElement(Router, null,
            React.createElement("div", null,
                React.createElement(Route, { path: "".concat(url.APP_BASE, "/create"), component: this.renderCreate }),
                React.createElement(Route, { path: "".concat(url.APP_BASE, "/delete"), component: this.renderDelete }),
                React.createElement(Route, { path: "".concat(url.APP_BASE, "/edit"), component: this.renderEdit }),
                React.createElement(Route, { path: "".concat(url.APP_BASE, "/show"), component: this.renderShow }),
                React.createElement(Route, { path: "".concat(url.APP_BASE, "/documentation"), component: this.renderDocumentation }),
                React.createElement(Route, { path: "".concat(url.APP_BASE, "/event"), component: this.renderEvent }),
                React.createElement(Route, { exact: true, path: "".concat(url.APP_BASE, "/global-config"), component: this.renderGlobalConfig }),
                React.createElement(Route, { exact: true, path: "".concat(url.APP_BASE, "/export-user-data"), component: this.renderExportUserData }),
                React.createElement(Route, { path: "".concat(url.GLOBAL_CONFIG_FILE_TYPES), component: this.renderGlobalConfigTranslationFileTypes }),
                React.createElement(Route, { path: "".concat(url.GLOBAL_CONFIG_VALID_LOCALES), component: this.renderGlobalConfigTranslationValidLocales }))));
    };
    return RoutingApp;
}(Component));
export default RoutingApp;
