/**
 *Created by Mikael Lindahl on 2019-12-10
 */
"use strict";
import React from "react";
/**
 * Export data as csv file in browser for user
 *
 * @param props {object} Export data csv properties
 * @returns {*}
 * @constructor
 */
export default function ExportAsCsvFile(props) {
    var data = "text/csv;charset=utf-16," + encodeURIComponent(props.data);
    return (React.createElement("a", { className: 'btn btn-secondary ' + props.className || '', href: "data:".concat(data), download: props.file_name || 'data.csv' }, props.label));
}
