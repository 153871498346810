/**
 *Created by Mikael Lindahl on 2019-11-04
 */
"use strict";
import React from "react";
import url from "../constants/url.jsx";
import { Link } from "react-router-dom";
import "./RouterLink.scss";
/**
 * Render a router link
 *
 * @param props {object} Router link properties
 * @returns {*}
 * @constructor
 */
export default function RouterLink(props) {
    return (React.createElement(Link, { className: 'btn btn-primary router-link', to: "".concat(url.APP_BASE, "/").concat(props.path) }, props.label));
}
