import React from "react";
var debug = require('debug')('bk-manager:frontend-react:components:ConfigGlobalTranslationFileTypesView');
/**
 * Render global config translation file types view. Show how translation
 * file should be formatted for successful import
 *
 * @returns {*}
 * @constructor
 */
export default function ConfigGlobalTranslationFileTypesView() {
    var example_json = {
        "_new": {
            "de": "Neu abc",
            "en": "New",
            "hu": "Új",
            "key": "_new",
            "sv": "Ny",
            "eligible_for": "mobile",
        },
        "absence_hours": {
            "de": "Abwesenheit",
            "en": "Absence",
            "hu": "Frånvaro",
            "key": "absence_hours",
            "sv": "Frånvaro",
            "eligible_for": "web_backend_puppeteer-pdf-server",
        },
        "add_diary": {
            "de": "Tagebuch hinzufügen",
            "en": "Add to diary",
            "hu": "Napló hozzáadása",
            "key": "add_diary",
            "sv": "Lägg till dagbok",
            "eligible_for": "web_backend_puppeteer-pdf-server",
        }
    };
    var example_csv = 'key;de;en;hu;sv\n' +
        '_new;Neu;New;Új;Ny;eligible_for\n' +
        'absence_hours;Abwesenheit;Absence;Frånvaro;Frånvaro;mobile\n' +
        'add_diary;Tagebuch hinzufügen;Add to diary;Napló hozzáadása;Lägg till dagbok;web_backend_puppeteer-pdf-server\n' +
        'add_new_row_button_title;Neue Zeile hinzufügen;Add new row;Új sor hozzáadása;Lägg till ny rad;web_backend_puppeteer-pdf-server';
    return React.createElement("div", { id: 'global-view-file-types' },
        React.createElement("div", { className: "header" },
            React.createElement("div", { className: "header-body" },
                React.createElement("div", { className: "row align-items-end" },
                    React.createElement("div", { className: "col" },
                        React.createElement("h6", { className: "header-pretitle" }, "Help"),
                        React.createElement("h1", { className: "header-title" }, "Global config file types"))))),
        React.createElement("div", { className: 'global-config container' },
            React.createElement("h3", null, "Xlsx"),
            React.createElement("small", { className: "form-text text-muted mb-2" }, 'Example of .xlsx file. Note that the the key and eligible_for field is required'),
            React.createElement("img", { className: 'mb-5 pl-2', 
                // style={{width:'700px'}}
                src: '/static/images/example-translation-xlsx.png' }),
            React.createElement("h3", null, "Csv"),
            React.createElement("small", { className: "form-text text-muted mb-2" }, 'Example of .csv file format. ' +
                'Note that the the  key and eligible_for column is required'),
            React.createElement("pre", { className: 'mb-5' },
                React.createElement("code", null,
                    React.createElement("div", null, example_csv))),
            React.createElement("h3", null, "Json"),
            React.createElement("small", { className: "form-text text-muted mb-2" }, 'Example of .json file format. This is the format stored in byggkollen_config.global. ' +
                'Note that the the  key and eligible_for field is required'),
            React.createElement("pre", null,
                React.createElement("code", null,
                    React.createElement("div", null, JSON.stringify(example_json, null, 2))))));
}
