/**
 *Created by Mikael Lindahl on 2020-05-25
 */
"use strict";
import { defaultConfig } from "config-validation";
import mapping from "../constants/mappingsTravelAndTimeTypes";
import util from "./util";
var debug = require("debug")("bk-manager:frontend-react:lib:config");
/**
 * Add empty list when undefined since firestore does not store empty lists
 *
 * @param config {object} Byggkollen config
 * @returns {*}
 */
function addEmptyList(config) {
    config = util.clone(config);
    for (var _i = 0, _a = [
        mapping.time_type_absence,
        mapping.time_type_sub_supplier,
        mapping.time_type_work,
        mapping.travel_type,
    ]; _i < _a.length; _i++) {
        var maps = _a[_i];
        maps = maps.slice(1); // Ignore first entry
        for (var _b = 0, maps_1 = maps; _b < maps_1.length; _b++) {
            var map = maps_1[_b];
            var setups = util.clone(map.maps_to);
            setups.push(map.primary);
            for (var _c = 0, setups_1 = setups; _c < setups_1.length; _c++) {
                var setup = setups_1[_c];
                var key = setup.key;
                var before = util.getNestedObject(config, key);
                if (before === undefined) {
                    util.setNestedObject(config, key, []);
                }
            }
        }
    }
    return config;
}
/**
 * Add add-hoc fixes that should be done to config file. E.g. clean up
 * of field that no longer belongs in config. This will then automatically
 * be applied to customer on edit.
 *
 * @param config {object} Byggkollen config
 * @param translation {object} Byggkollen translation mapping
 */
function fix(config, translation) {
    var _a, _b, _c, _d;
    var config_flatten = util.flattenObject(config);
    var config_fix = {};
    if (!config || util.isEmptyObject(config)) {
        return;
    }
    var description = "Add-hoc fix. Wrong type. Should be number since this will display decimal in web reporting.";
    // Correct type
    if (config.web.time.minHourValue &&
        typeof config.web.time.minHourValue == "string") {
        var before = config.web.time.minHourValue;
        var after = Number(config.web.time.minHourValue);
        util.setNestedObject(config_fix, "web.time.minHourValue", {
            action: "edited",
            before: before,
            after: after,
            description: description,
        });
    }
    // Correct type
    if (config.web.time.minTimeInterval &&
        typeof config.web.time.minTimeInterval == "string") {
        var before = config.web.time.minTimeInterval;
        var after = Number(config.web.time.minTimeInterval);
        util.setNestedObject(config_fix, "web.time.minTimeInterval", {
            action: "edited",
            before: before,
            after: after,
            description: description,
        });
    }
    // Correct type
    if (((_a = config.mobile.time) === null || _a === void 0 ? void 0 : _a.minTimeInterval) &&
        typeof ((_b = config.mobile.time) === null || _b === void 0 ? void 0 : _b.minTimeInterval) == "string") {
        var before = (_c = config.mobile.time) === null || _c === void 0 ? void 0 : _c.minTimeInterval;
        var after = Number((_d = config.mobile.time) === null || _d === void 0 ? void 0 : _d.minTimeInterval);
        util.setNestedObject(config_fix, "mobile.time.minTimeInterval", {
            action: "edited",
            before: before,
            after: after,
            description: description,
        });
    }
    description = "Add-hoc fix. Removed accidentally added parameters.";
    // Fix remove default. Added by mistake
    if (config.default) {
        var before = config.default;
        util.setNestedObject(config_fix, "default", {
            action: "deleted",
            before: before,
            after: null,
            description: description,
        });
    }
    // Fix mistake hazard on mobile should not be directly under mobile
    for (var key in config_flatten) {
        if (key == "mobile.hazard1" || key == "mobile.hazard2") {
            var before = util.getNestedObject(config, key);
            util.setNestedObject(config_fix, key, {
                action: "deleted",
                before: before,
                after: null,
                description: description,
            });
        }
    }
    // Fix types ensure that they are in the correct languages in their respective lists
    description =
        "Add-hoc fix. Ensured the list is unique, do not contain types that" +
            "are not in global translation and all types are in the same translation.";
    for (var _i = 0, _e = [
        mapping.time_type_absence,
        mapping.time_type_work,
        mapping.travel_type,
    ]; _i < _e.length; _i++) {
        var maps_4 = _e[_i];
        maps_4 = maps_4.slice(1); // Ignore first entry
        for (var _f = 0, maps_2 = maps_4; _f < maps_2.length; _f++) {
            var map = maps_2[_f];
            var setups = util.clone(map.maps_to);
            setups.push(map.primary);
            for (var _g = 0, setups_2 = setups; _g < setups_2.length; _g++) {
                var setup = setups_2[_g];
                var key = setup.key, name_type = setup.name_type, language = setup.language;
                var before = util.getNestedObject(config, key) || [];
                var after = [];
                var is_fix = false;
                for (var _h = 0, before_1 = before; _h < before_1.length; _h++) {
                    var l = before_1[_h];
                    var trans_key = "".concat(name_type, "_names");
                    var trans = language == "sv"
                        ? "key->sv"
                        : "sv->key";
                    // Make sure only valid types defined by translation
                    if (l == null || l == undefined) {
                        is_fix = true;
                    }
                    else if (translation[trans_key][language].indexOf(l) == -1) {
                        var v = translation[name_type][trans][l];
                        if (v != null && v != undefined) {
                            after.push(v);
                        }
                        is_fix = true;
                    }
                    else {
                        after.push(l);
                    }
                }
                after = util.unique(after);
                if (is_fix) {
                    util.setNestedObject(config_fix, key, {
                        action: "edited",
                        before: before,
                        after: after,
                        description: description,
                    });
                }
            }
        }
    }
    description = "Add-hoc fix. Ensure list are unique";
    // Unique list
    for (var _j = 0, _k = ["backend.decidedHoursNegative"]; _j < _k.length; _j++) {
        var key = _k[_j];
        var before = util.getNestedObject(config, key) || [];
        var after = util.unique(before);
        if (before.length != after.length) {
            util.setNestedObject(config_fix, key, {
                action: "edited",
                before: before,
                after: after,
                description: description,
            });
        }
    }
    description = "Add-hoc fix. Ensure color codes are upper case";
    for (var _l = 0, _m = [
        "mobile.primaryColor",
        "mobile.accentColor",
        "web.theme.primaryColor",
        "web.theme.secondaryColor",
        "web.theme.datepickerColor",
        "web.theme.datepickerSndColor",
        "web.theme.navTextColor",
        "web.theme.settingsReportColor",
    ]; _l < _m.length; _l++) {
        var key = _m[_l];
        var before = util.getNestedObject(config, key);
        if (before) {
            var after = before.toUpperCase();
            if (before != after) {
                util.setNestedObject(config_fix, key, {
                    action: "edited",
                    before: before,
                    after: after,
                    description: description,
                });
            }
        }
    }
    description = "Add-hoc fix. Added UE-tid";
    var maps = mapping.time_type_sub_supplier;
    maps = maps.slice(1); // Ignore first entry
    for (var _o = 0, maps_3 = maps; _o < maps_3.length; _o++) {
        var map = maps_3[_o];
        var setups = util.clone(map.maps_to);
        setups.push(map.primary);
        for (var _p = 0, setups_3 = setups; _p < setups_3.length; _p++) {
            var setup = setups_3[_p];
            var key = setup.key, language = setup.language;
            var before = util.getNestedObject(config, key);
            var after = language == "sv" ? ["UE-tid"] : ["time_type_ue"];
            var is_fix = false;
            if (!before || before.length < 1 || !util.compare(before, after)) {
                is_fix = true;
                util.setNestedObject(config_fix, key, {
                    action: "edited",
                    before: before,
                    after: after,
                    description: description,
                });
            }
        }
    }
    return config_fix;
}
/**
 * Get the final edit composed of added defaults, add-hoc fixes
 * and user edits.
 *
 * @param config_edit {object} Config edits
 * @param config {object} Current byggkollen config
 * @param translation {object} Byggkollen translation mapping
 * @returns {*}
 */
function getFinalEdit(_a) {
    var config_edit = _a.config_edit, config = _a.config, translation = _a.translation;
    var config_fix = fix(config, translation);
    var config_default = syncWithDefault(config);
    config_edit = util.clone(config_edit || {});
    var config_fix_flatten = util.flattenObject(config_fix, "action", [
        "added",
        "edited",
        "deleted",
    ]);
    var config_default_flatten = util.flattenObject(config_default, "action", [
        "added",
        "edited",
        "deleted",
    ]);
    for (var _i = 0, _b = [config_fix_flatten, config_default_flatten]; _i < _b.length; _i++) {
        var flatten = _b[_i];
        for (var key in flatten) {
            var obj = flatten[key];
            var value = util.getNestedObject(config_edit, key);
            if (!value && obj.action == "deleted") {
                // Keep information for firestore. Set a value to undefined and firestore will delete
                util.setNestedObject(config_edit, key, null);
            }
            else if (!value) {
                util.setNestedObject(config_edit, key, obj.after);
            }
        }
    }
    return config_edit;
}
/**
 * Get config with added defaults, add-hoc fixes
 * and user edits applied
 *
 * @param config_edit {object} Config edits
 * @param config {object} Current byggkollen config
 * @param translation {object} Byggkollen translation mapping
 * @returns {*}
 */
function get(_a) {
    var config_edit = _a.config_edit, config = _a.config, translation = _a.translation;
    var config_edit_final = getFinalEdit({ config_edit: config_edit, config: config, translation: translation });
    var config_edit_final_flatten = util.flattenObject(config_edit_final);
    config = util.clone(config);
    for (var key in config_edit_final_flatten) {
        var value = config_edit_final_flatten[key];
        if (value == undefined) {
            util.deleteNestedObject(config, key);
        }
        else {
            util.setNestedObject(config, key, value);
        }
    }
    return config;
}
/**
 * Remove values from edit if the already exists in config.
 * E.i.taking care of removing a value from edit when users first
 * change a value and then later changed it back. It should not
 * be in config edit anymore.
 *
 * @param config_edit {object} Config edits
 * @param config {object} Current byggkollen config
 * @returns {{config_edit: *, is_edit_update: *}}
 */
function removeValuesFromConfigEditThatAreEqualInConfig(config_edit, config) {
    var flatten = util.flattenObject(config_edit);
    var is_edit_update = false;
    for (var key in flatten) {
        var current = util.getNestedObject(config, key);
        var edit = flatten[key];
        if (util.compare(current, edit)) {
            is_edit_update = true;
            util.deleteNestedObject(config_edit, key);
        }
    }
    return { config_edit: config_edit, is_edit_update: is_edit_update };
}
/**
 * Sync with default values that are missing in config
 *
 * @param config {object} Current byggkollen config
 */
function syncWithDefault(config) {
    if (!config) {
        return;
    }
    var config_default = {};
    var config_flatten = util.flattenObject(config);
    var default_flatten = util.flattenObject(defaultConfig);
    // If new default values are present update
    for (var key in default_flatten) {
        if (config_flatten[key] === undefined &&
            !util.isEmptyArray(default_flatten[key]) //firestore stores empty arrays as undefined. No need to add.
        ) {
            util.setNestedObject(config_default, key, {
                action: "added",
                before: null,
                after: default_flatten[key],
            });
        }
    }
    return config_default;
}
/**
 * Convert config to key value list
 *
 * @param obj
 * @returns {[]}
 */
function toKeyValueList(obj) {
    obj = util.flattenObject(obj, null, null, ["companies", "instructions"]);
    var list = [];
    for (var key in obj) {
        list.push({
            key: key,
            value: obj[key],
        });
    }
    return list;
}
export default {
    addEmptyList: addEmptyList,
    fix: fix,
    get: get,
    getFinalEdit: getFinalEdit,
    removeValuesFromConfigEditThatAreEqualInConfig: removeValuesFromConfigEditThatAreEqualInConfig,
    syncWithDefault: syncWithDefault,
    toKeyValueList: toKeyValueList,
};
