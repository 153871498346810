/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import InputSelectReact from "./InputSelectReact";
var debug = require('debug')('bk-manager:frontend-react:components:InputSelectReactSimpleView');
/**
 * Render select react ux component with label and help text for simple view
 *
 * @param props {object} Input select react simple view props
 * @returns {*}
 * @constructor
 */
export default function InputSelectReactSimpleView(props) {
    var props_select_react = {
        className: 'input-select-react',
        creatable: props.creatable,
        multi: props.multi,
        disabled: props.disabled,
        onChange: props.onChange,
        selected: props.selected,
        options: props.options,
        styles: props.styles
    };
    return (React.createElement("div", { className: "form-group" },
        props.label && React.createElement("label", null, props.label),
        props.help && React.createElement("small", { className: "form-text text-muted mb-2" }, props.help),
        React.createElement("div", { className: 'd-flex flex-row' },
            React.createElement(InputSelectReact, __assign({}, props_select_react)),
            props.required && React.createElement("small", { className: "form-text text-danger mb-2 ml-2" }, 'required'))));
}
