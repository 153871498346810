/**
 *Created by Mikael Lindahl on 2019-12-10
 */
"use strict";
import React from "react";
import * as xlsx from 'xlsx';
import { saveAs } from 'file-saver';
var debug = require('debug')('bk-manager:frontend-react:components:ExportAsXLSXFile');
/**
 * Export data as xlsx file in browser for user
 *
 * @param props {object} Export data xlsx properties
 * @returns {*}
 * @constructor
 */
export default function ExportAsXLSXFile(props) {
    var download = function () {
        // let csvData = []
        //
        // let header = ['key']
        // let locales = util.getLocales([props.data], ['eligible_for', 'key'])
        // header = header.concat(locales)
        // for (let key in props.data) {
        //
        //     csvData.push(props.data[key])
        // }
        // header.push('eligible_for')
        var fileName = props.file_name || 'data.xlsx';
        var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        var ws = xlsx.utils.json_to_sheet(props.data.csvData, { header: props.data.header });
        var wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        var excelBuffer = xlsx.write(wb, { bookType: 'xlsx', type: 'array' });
        var data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName);
    };
    return (React.createElement("a", { className: 'btn btn-secondary ' + props.className || '', onClick: function () {
            download();
        } }, props.label));
}
