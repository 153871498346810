/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import util from "../../lib/util";
import './InputCheckboxGrid.scss';
import InputSortableGrid from "./InputSortableGrid";
import { Button } from "react-bootstrap";
var debug = require('debug')('bk-manager:frontend-react:components:InputCheckboxGrid');
/**
 * Get grid header
 *
 * @param header {array<object>} List with header objects
 * @returns {object}
 *
 * */
function getHeader(headers) {
    headers = util.clone(headers);
    headers.push({
        label: 'All'
    });
    var style = {
        width: "".concat(100 / headers.length, "vw")
    };
    var headers_elements = [];
    for (var _i = 0, headers_1 = headers; _i < headers_1.length; _i++) {
        var h = headers_1[_i];
        headers_elements.push(React.createElement("b", null, h.label));
    }
    return (headers_elements.map(function (r, i) { return React.createElement("th", { key: i, style: style, className: i == 0 ? 'first' : '' },
        React.createElement("div", null, r)); }));
}
/**
 *
 * Get grid row
 *
 * @param row_input {array<object>} List with row data
 * @param props {object} Get row properties
 * @returns {object}
 *
 * */
function getRow(row_input, props) {
    var row = [];
    var _loop_1 = function (i) {
        if (i > 0) {
            row.push([
                React.createElement("input", { key: 'input', disabled: row_input[i].disabled || props.disabled, onChange: function () { return props.onChange(__assign({}, row_input[i])); }, type: 'checkbox', checked: row_input[i].checked, name: row_input[i].value_sv }),
                React.createElement("label", { className: (row_input[i].disabled || props.disabled) && 'disabled', key: 'label', htmlFor: 'row-' + i })
            ]);
        }
        else {
            row.push(React.createElement("b", null, row_input[i].row_label));
        }
    };
    for (var i = 0; i < row_input.length; i++) {
        _loop_1(i);
    }
    row.push(React.createElement("input", { key: 'input', disabled: props.disabled, onChange: function (event) {
            var row = util.clone(row_input);
            var config_edit_local = {};
            for (var _i = 0, _a = row.slice(1, row.length - 1); _i < _a.length; _i++) {
                var r = _a[_i];
                if (r.disabled) {
                    continue;
                }
                r.checked = !event.target.checked;
                props.onChange(__assign(__assign({}, r), { config_edit_local: config_edit_local, skip_state_update: true })); // for snappy ux
            }
            props.onChange(__assign(__assign({}, row[row.length - 1]), { config_edit_local: config_edit_local, skip_state_update: false }));
        }, type: 'checkbox', checked: row_input.slice(1).reduce(function (tot, r) {
            return r.disabled || r.checked && tot;
        }, true), name: 'all' }));
    var style = {
        width: "".concat(100 / row.length, "vw")
    };
    return (row.map(function (r, i) { return React.createElement("td", { key: i, className: i == 0 ? 'first' : '', style: style },
        React.createElement("div", null, r)); }));
}
/**
 * Get grid header options
 *
 * @param props {object} Get grid header properties
 * @returns {{header: *}[]}
 */
function getTypeLists(props) {
    var headers = util.clone(props.header);
    var lists = headers.slice(1).map(function (header) {
        return {
            header: header
        };
    });
    return lists;
}
/**
 * Render sort ux
 *
 * @param props {object} Render sort properties
 * @returns {*}
 */
function renderSort(props) {
    return React.createElement("div", { key: props.index, className: 'col' },
        React.createElement("h4", null, props.header.label),
        !props.disabled && React.createElement("small", { className: "form-text text-muted mb-2" }, 'Drag and drop to reorder'),
        React.createElement(InputSortableGrid, { items: props.header.values, disabled: props.disabled, onChange: function (items) {
                props.onChange({ items: items, header: props.header });
            } }));
}
/**
 *
 * Render input checkbox grid
 *
 * @param props {object} Input checkbox grid properties
 * @returns {*}
 * @constructor
 */
export default function InputCheckboxGrid(props) {
    var _a = useState(false), collapseOne = _a[0], setCollapseOne = _a[1];
    var _b = useState(false), collapseTwo = _b[0], setCollapseTwo = _b[1];
    var _c = useState(false), hideEmpty = _c[0], setHideEmpty = _c[1];
    var type_list = getTypeLists(props);
    var onChangeSort = props.onChangeSort;
    var rows = props.rows && util.clone(props.rows);
    if (hideEmpty) {
        rows = rows.reduce(function (tot, r) {
            var bool = r.slice(1).reduce(function (tot, e) { return e.checked || tot; }, false);
            if (bool) {
                tot.push(r);
            }
            return tot;
        }, []);
    }
    return (React.createElement("div", { className: "form-group input-checkbox-grid" },
        React.createElement("label", null, props.label),
        props.help && React.createElement("small", { className: "form-text text-muted mb-2" }, props.help),
        React.createElement(Button, { onClick: props.onSync }, "Synchronize "),
        React.createElement("small", { className: "form-text text-muted mb-2" }, 'Make sure data visible here are mapped to underlying map of list/lists in the config. This can help ' +
            'mitigate conflicts and warnings'),
        React.createElement("div", { className: 'hider', onClick: function () { return setCollapseOne(!collapseOne); } },
            'Grid',
            React.createElement("i", { className: "fas ".concat(!collapseOne
                    ? 'fa-chevron-down' : 'fa-chevron-up', " expand") })),
        React.createElement(Button, { variant: "secondary", style: { display: !collapseOne ? 'none' : 'block' }, className: props.className, onClick: function () { return setHideEmpty(!hideEmpty); } }, hideEmpty ? 'Show empty' : 'Hide empty'),
        React.createElement("div", { style: { display: !collapseOne ? 'none' : 'block' } },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null, getHeader(props.header))),
                React.createElement("tbody", null, rows && rows.map(function (r, i) { return React.createElement("tr", { key: i }, getRow(r, props)); })))),
        React.createElement("div", { className: 'hider', onClick: function () { return setCollapseTwo(!collapseTwo); } },
            'Order',
            React.createElement("i", { className: "fas ".concat(!collapseTwo
                    ? 'fa-chevron-down' : 'fa-chevron-up', " expand") })),
        React.createElement("div", { style: { display: !collapseTwo ? 'none' : 'block' } }, type_list.map(function (_a, index) {
            var header = _a.header;
            return renderSort({
                header: header,
                index: index,
                onChange: onChangeSort,
                disabled: props.disabled
            });
        }))));
}
