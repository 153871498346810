/**
 *Created by Mikael Lindahl on 2021-08-24
 */
"use strict";
export default {
    options: [
        // {
        //     label: 'Mobile',
        //     value: 'mobile'
        // },
        {
            value: 'time_and_travel_types',
            label: 'Time and travel types'
        },
        {
            value: 'web_backend_puppeteer-pdf-server',
            label: 'Web, backend and pdf'
        }
    ]
};
