/**
 *Created by Mikael Lindahl on 2019-10-22
 */

"use strict";

const defaultConfig=require('./default');
const validate=require('./validate');
const schema=require('./schema');
const swagger=require('./swagger');

module.exports={
    defaultConfig,
    validate,
    schema,
    swagger
};
