/**
 *Created by Mikael Lindahl on 2019-10-01
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import util from "../../lib/util";
import { useHandleChangeSelectReact } from "../../hooks/useHandleChangeSelectReact";
var debug = require('debug')('bk-manager:frontend-react:components:InputSelectReact');
/**
 * Render select react ux component
 */
export default function InputSelectReact(props) {
    var _a = useState(props.options), options = _a[0], setOptions = _a[1];
    var _b = useState(props.selected), selected = _b[0], setSelected = _b[1];
    var handleChangeSelectReact = useHandleChangeSelectReact({
        isMulti: props.multi,
        onChange: props.onChange,
        options: options,
        selected: selected,
        setOptions: setOptions,
        setSelected: setSelected
    }).handleChangeSelectReact;
    // static propTypes = {
    //     creatable:PropTypes.bool,
    //     disabled: PropTypes.bool,
    //     multi: PropTypes.bool,
    //     onChange: PropTypes.func.isRequired,
    //     options: PropTypes.array,
    //     selected: PropTypes.array,
    // };
    // constructor(props) {
    //     super(props);
    //
    //     this.state = {
    //         selected: props.selected,
    //         options: props.options
    //     };
    //
    //     this.handleChange = this.handleChange.bind(this);
    // }
    // render() {
    // let {options, selected} = this.state;
    var _options = util.sort(options, function (v) { return v.value; }, 'desc', true);
    // selected=util.sort(selected || [], v=>v.value);
    var props_react_select = {
        isMulti: props.multi,
        className: props.className || 'table-select',
        classNamePrefix: props.classNamePrefix || 'table-select-inner',
        value: selected,
        onChange: handleChangeSelectReact,
        options: _options,
        isDisabled: props.disabled,
        styles: props.styles
    };
    if (props.creatable) {
        return React.createElement(Creatable, __assign({}, props_react_select));
    }
    else {
        return React.createElement(Select, __assign({}, props_react_select));
    }
    // }
}
