/**
 *Created by Mikael Lindahl on 2020-05-27
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { flexRender, getCoreRowModel, getFilteredRowModel, getFacetedRowModel, getFacetedUniqueValues, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import './Table.scss';
var debug = require('debug')('bk-manager:frontend-react:components:Table');
// A debounced input react component
function DebouncedInput(_a) {
    var initialValue = _a.value, onChange = _a.onChange, _b = _a.debounce, debounce = _b === void 0 ? 500 : _b, props = __rest(_a, ["value", "onChange", "debounce"]);
    var _c = React.useState(initialValue), value = _c[0], setValue = _c[1];
    React.useEffect(function () {
        setValue(initialValue);
    }, [initialValue]);
    React.useEffect(function () {
        var timeout = setTimeout(function () {
            onChange(value);
        }, debounce);
        return function () { return clearTimeout(timeout); };
    }, [value]);
    return (React.createElement("input", __assign({}, props, { value: value, onChange: function (e) { return setValue(e.target.value); } })));
}
function Filter(_a) {
    var column = _a.column, table = _a.table;
    var columnFilterValue = column.getFilterValue();
    var sortedUniqueValues = React.useMemo(function () { return Array.from(column.getFacetedUniqueValues().keys()).sort(); }, [column.getFacetedUniqueValues()]);
    // debug('sortedUniqueValues', sortedUniqueValues, column.getFacetedUniqueValues())
    return React.createElement(React.Fragment, null,
        React.createElement("datalist", { id: column.id + 'list' }, sortedUniqueValues.slice(0, 5000).map(function (value) { return (React.createElement("option", { value: value || '', key: value || '' })); })),
        React.createElement(DebouncedInput, { type: "text", value: (columnFilterValue !== null && columnFilterValue !== void 0 ? columnFilterValue : ''), onChange: function (value) { return column.setFilterValue(value); }, placeholder: "Search... (".concat(column.getFacetedUniqueValues().size, ")"), className: "w-36 border shadow rounded", list: column.id + 'list' }),
        React.createElement("div", { className: "h-1" }));
}
/**
 * Render table pagination
 *
 * @param props {object} React table props
 * @param options {object} Extra options
 * @returns {*}
 */
function renderPagination(table, options) {
    var onPageIndexChange = options.onPageIndexChange, onPageSizeChange = options.onPageSizeChange, pageSizeOptions = options.pageSizeOptions //my option
    ;
    return React.createElement("div", { className: "pagination" },
        React.createElement("button", { key: 'start', className: "border rounded p-1", onClick: function () {
                onPageIndexChange(0);
                table.setPageIndex(0);
            }, disabled: !table.getCanPreviousPage() }, '<<'),
        React.createElement("button", { key: 'left', className: "border rounded p-1", onClick: function () {
                onPageIndexChange(table.getState().pagination.pageIndex - 1);
                table.previousPage();
            }, disabled: !table.getCanPreviousPage() }, '<'),
        React.createElement("button", { key: 'right', className: "border rounded p-1", onClick: function () {
                onPageIndexChange(table.getState().pagination.pageIndex + 1);
                table.nextPage();
            }, disabled: !table.getCanNextPage() }, '>'),
        React.createElement("button", { key: 'end', className: "border rounded p-1", onClick: function () {
                onPageIndexChange(table.getPageCount() - 1);
                table.setPageIndex(table.getPageCount() - 1);
            }, disabled: !table.getCanNextPage() }, '>>'),
        React.createElement("span", { key: 'page-count', className: "flex items-center gap-1" },
            React.createElement("div", null, "Page"),
            React.createElement("strong", null,
                table.getState().pagination.pageIndex + 1,
                " of",
                ' ',
                table.getPageCount())),
        React.createElement("span", { key: 'page-index', className: "flex items-center gap-1" },
            "| Go to page:",
            React.createElement("input", { type: "number", defaultValue: table.getState().pagination.pageIndex + 1, onChange: function (e) {
                    var page = e.target.value ? Number(e.target.value) - 1 : 0;
                    table.setPageIndex(page);
                }, className: "border p-1 rounded w-16" })),
        React.createElement("select", { value: table.getState().pagination.pageSize, onChange: function (e) {
                if (onPageSizeChange) {
                    onPageSizeChange(Number(e.target.value));
                }
                table.setPageSize(Number(e.target.value));
            } }, pageSizeOptions.map(function (pageSize) { return (React.createElement("option", { key: pageSize, value: pageSize },
            "Show ",
            pageSize)); })));
}
/**
 * Render header for table
 *
 * @param table {Table} React table object
 */
function renderHeader(table) {
    return table.getHeaderGroups().map(function (headerGroup) { return (React.createElement("tr", { key: headerGroup.id }, headerGroup.headers.map(function (header) {
        var _a;
        return (React.createElement("th", { key: header.id, colSpan: header.colSpan }, header.isPlaceholder ? null : (React.createElement(React.Fragment, null,
            React.createElement("div", __assign({}, {
                className: header.column.getCanSort()
                    ? 'cursor-pointer select-none'
                    : '',
                onClick: header.column.getToggleSortingHandler(),
            }),
                flexRender(header.column.columnDef.header, header.getContext()), (_a = {
                asc: ' 🔼',
                desc: ' 🔽',
            }[header.column.getIsSorted()]) !== null && _a !== void 0 ? _a : ''),
            header.column.getCanFilter() ? (React.createElement("div", null,
                React.createElement(Filter, { column: header.column, table: table }))) : null))));
    }))); });
}
/**
 * Create the table
 *
 * @param columns {array} React table column configuration
 * @param data {array} React table data
 * @param options {object} Extra options
 * @returns {*}
 * @constructor
 */
function Create(_a) {
    // Use the state and functions returned from useTable to build your UI
    // let userTableProps;
    var _b;
    var columns = _a.columns, data = _a.data, options = _a.options;
    var _c = React.useState([]), columnFilters = _c[0], setColumnFilters = _c[1];
    var _d = React.useState([]), sorting = _d[0], setSorting = _d[1];
    // const [globalFilter, setGlobalFilter] = React.useState('')
    var table = useReactTable({
        data: data,
        columns: columns,
        state: {
            columnFilters: columnFilters,
            sorting: sorting
            // globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        // onGlobalFilterChange: setGlobalFilter,
        // globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: options.getFacetedUniqueValues
            ? options.getFacetedUniqueValues() : getFacetedUniqueValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });
    // const {
    //     getTableProps,
    //     getTableBodyProps,
    //     headerGroups,
    //     prepareRow,
    //     page, // Instead of using 'rows', we'll use page,
    //     // which has only the rows for the active page
    //
    //     // The rest of these things are super handy, too ;)
    //     canPreviousPage,
    //     canNextPage,
    //     pageOptions,
    //     pageCount,
    //     gotoPage,
    //     nextPage,
    //     previousPage,
    //     setPageSize,
    //     state: {pageIndex, pageSize},
    // } = userTableProps = useTable(
    //     {
    //         columns,
    //         data,
    //         initialState: {
    //             filters: options.filters,
    //             pageIndex: options.defaultPageIndex,
    //             pageSize: options.defaultPageSize
    //         },
    //         defaultColumn: options.defaultColumn,
    //         filterTypes: options.filterTypes,
    //         // autoResetPage:true
    //     },
    //     useFilters,
    //     useSortBy,
    //     usePagination,
    // );
    // Render the UI for your table
    return (React.createElement(React.Fragment, null, (_b = options.showPaginationTop) !== null && _b !== void 0 ? _b : renderPagination(table, options),
        React.createElement("table", null,
            React.createElement("thead", null, renderHeader(table)),
            React.createElement("tbody", null, table
                .getRowModel()
                .rows
                .map(function (row) {
                var props = options.getTrProps ? options.getTrProps(row) : {};
                return (React.createElement("tr", __assign({ key: row.id }, props), row.getVisibleCells().map(function (cell) {
                    if (options.renderWithoutTd) {
                        return React.createElement(cell.column.columnDef.cell, __assign(__assign({}, cell.getContext()), { key: cell.id }));
                    }
                    else {
                        return (React.createElement("td", { key: cell.id }, flexRender(cell.column.columnDef.cell, cell.getContext())));
                    }
                })));
            }))),
        renderPagination(table, options)));
}
/**
 * Table component
 *
 * @param columns {array} React table column configuration
 * @param data {array} React table data
 * @param defaultPageSize {number} Default page size
 * @param onPageSizeChange {function} Callback for page size change
 * @param pageSizeOptions {object} React table options for page size
 * @param renderWithoutTd {boolean} If to render without td element in table
 * @returns {*}
 * @constructor
 */
export default function Table(props) {
    var _a = React.useState(0), defaultPageIndex = _a[0], onPageIndexChange = _a[1];
    var columns = React.useMemo(function () { return props.columns; }, [props.columns]);
    var data = React.useMemo(function () { return props.data; }, [props.data]);
    return (React.createElement("div", { className: 'table-wrapper' },
        React.createElement(Create, { columns: columns, data: data, options: {
                // columnFilters,
                defaultPageIndex: defaultPageIndex,
                defaultPageSize: props.defaultPageSize || 10,
                getFacetedUniqueValues: props.getFacetedUniqueValues,
                getTrProps: props.getTrProps,
                // filterTypes,
                // defaultColumn,
                onPageIndexChange: onPageIndexChange,
                onPageSizeChange: props.onPageSizeChange,
                pageSizeOptions: props.pageSizeOptions || [10, 20, 50],
                renderWithoutTd: props.renderWithoutTd
            } })));
}
