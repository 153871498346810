var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import ConfigTools from "../../lib/config";
import PropTypes from "prop-types";
import Table from "./Table";
import InputSelectReact from "../inputs/InputSelectReact";
// import TableSelectCreatable from "./TableSelectCreatable";
import exclude from "../../constants/exclude";
import util from "../../lib/util";
import InputText from "../inputs/InputText";
import InputCheckbox from "../inputs/InputCheckbox";
import InputColor from "../inputs/InputColor";
import "./TableConfig.scss";
import reporting from "../../constants/reporting";
var debug = require("debug")("bk-manager:frontend-react:components:ConfigTable");
/**
 *
 * Get ux input type
 *
 * @param props
 * @returns {string|"undefined"|"object"|"boolean"|"number"|"string"|"function"|"symbol"|"bigint"}
 */
function getRowType(_a) {
    var key = _a.key, value = _a.value;
    switch (key) {
        case "mobile.clientType":
        case "mobile.endingSalaryDay":
        case "mobile.startingSalaryDay":
        case "web.reportingStartingDate":
            return "select";
        case "backend.absenceTimeTypes":
        case "backend.decidedHoursNegative":
        case "mobile.absenceTimeTypes":
        case "mobile.timeTypes.projectFixed":
        case "mobile.timeTypes.projectFixedLagbas":
        case "mobile.timeTypes.projectRunning":
        case "mobile.timeTypes.projectRunningLagbas":
        case "mobile.timeTypes.serviceorder":
        case "mobile.timeTypes.subSupplier":
        case "mobile.remoteTimeTypes.projectFixed":
        case "mobile.remoteTimeTypes.projectFixedLagbas":
        case "mobile.remoteTimeTypes.projectRunning":
        case "mobile.remoteTimeTypes.projectRunningLagbas":
        case "mobile.remoteTimeTypes.serviceorder":
        case "mobile.remoteTimeTypes.subSupplier":
        case "mobile.drivingTypes.project":
        case "mobile.drivingTypes.serviceorder":
        case "mobile.remoteDrivingTypes.project":
        case "mobile.remoteDrivingTypes.serviceorder":
        case "web.absenceTimeTypes":
        case "web.ataTypes":
        case "web.serviceTypes":
        case "web.invoicing.fastpris.timeTypes":
        case "web.invoicing.löpanderäkning.timeTypes":
        case "web.invoicing.service.timeTypesTimlön":
        case "web.time.timeTypes.ackord.non-supervisor":
        case "web.time.timeTypes.ackord.supervisor":
        case "web.time.timeTypes.timlön.non-supervisor":
        case "web.time.timeTypes.timlön.supervisor":
        case "web.timeTypes.types":
        case "web.travel.project.drivingTypes":
        case "web.travel.service.drivingTypes":
        case "web.equipment.fields":
        case "web.material.fields":
        case "web.remainingHoursTimeTypes": // not in prod
        case "web.projectTypes":
        case "web.invoicing.service.drivingTypes":
        case "web.invoicing.fastpris.drivingTypes":
        case "web.invoicing.löpanderäkning.drivingTypes":
        case "web.invoicing.service.timeTypes":
        case "web.time.subSupplierTimeTypes":
            return "select-multi";
        case "mobile.quickText":
            return "select-multi-creatable";
        case "mobile.primaryColor":
        case "mobile.accentColor":
        case "web.theme.primaryColor":
        case "web.theme.secondaryColor":
        case "web.theme.datepickerColor":
        case "web.theme.datepickerSndColor":
        case "web.theme.navTextColor":
        case "web.theme.settingsReportColor":
            return "color";
        default:
            return typeof value;
    }
}
/**
 * Render table view for byggkollen config ux component
 */
var TableConfig = /** @class */ (function (_super) {
    __extends(TableConfig, _super);
    function TableConfig(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            page_size: _this.props.page_size,
        };
        _this.getOptionsSelect = _this.getOptionsSelect.bind(_this);
        _this.onPageSizeChange = _this.onPageSizeChange.bind(_this);
        _this.renderCell = _this.renderCell.bind(_this);
        return _this;
    }
    /**
     * Get selection option for a config key
     *
     * @param key {string} Config key
     * @param options {object} Optional select options
     * @returns {{label: *, value: *}[]|*}
     */
    TableConfig.prototype.getOptionsSelect = function (key, options) {
        // debug('getOptionsSelect', key, options)
        var days;
        switch (key) {
            case "mobile.clientType":
                var values = ["1", "2", "3", "4", "5", "6"];
                return values.map(util.toOptions);
            case "mobile.endingSalaryDay":
                days = [];
                for (var i = 0; i < 28; i++) {
                    days.push(String(i));
                }
                return days.map(util.toOptions);
            case "mobile.startingSalaryDay":
            case "web.reportingStartingDate":
                days = [];
                for (var i = 1; i < 29; i++) {
                    days.push(String(i));
                }
                return days.map(util.toOptions);
            case "mobile.quickText":
                return options;
            case "mobile.timeTypes.projectFixed":
            case "mobile.timeTypes.projectFixedLagbas":
            case "mobile.timeTypes.projectRunning":
            case "mobile.timeTypes.projectRunningLagbas":
            case "mobile.timeTypes.serviceorder":
            case "mobile.remoteTimeTypes.projectFixed":
            case "mobile.remoteTimeTypes.projectFixedLagbas":
            case "mobile.remoteTimeTypes.projectRunning":
            case "mobile.remoteTimeTypes.projectRunningLagbas":
            case "mobile.remoteTimeTypes.serviceorder":
                return this.props.translation.time_type_names["key"].map(util.toOptions);
            case "mobile.timeTypes.subSupplier":
            case "mobile.remoteTimeTypes.subSupplier":
                return ["time_type_ue"].map(util.toOptions);
            case "backend.absenceTimeTypes":
            case "web.absenceTimeTypes":
                return this.props.absence_time_types.map(util.toOptions);
            case "backend.decidedHoursNegative":
            case "web.ataTypes":
            case "web.invoicing.fastpris.timeTypes":
            case "web.invoicing.löpanderäkning.timeTypes":
            case "web.invoicing.service.timeTypesTimlön":
            case "web.time.timeTypes.ackord.non-supervisor":
            case "web.time.timeTypes.ackord.supervisor":
            case "web.time.timeTypes.timlön.non-supervisor":
            case "web.time.timeTypes.timlön.supervisor":
            case "web.timeTypes.types":
            case "web.remainingHoursTimeTypes": // not in prod
            case "web.invoicing.service.timeTypes":
                return this.props.translation.time_type_names["sv"].map(util.toOptions);
            case "web.time.subSupplierTimeTypes":
                return ["UE-tid"].map(util.toOptions);
            case "mobile.drivingTypes.project":
            case "mobile.drivingTypes.serviceorder":
            case "mobile.remoteDrivingTypes.project":
            case "mobile.remoteDrivingTypes.serviceorder":
                return this.props.translation.travel_type_names["key"].map(util.toOptions);
            case "web.travel.project.drivingTypes":
            case "web.travel.service.drivingTypes":
            case "web.invoicing.fastpris.drivingTypes":
            case "web.invoicing.löpanderäkning.drivingTypes":
            case "web.invoicing.service.drivingTypes":
                return this.props.translation.travel_type_names["sv"].map(util.toOptions);
            case "web.equipment.fields":
            case "web.material.fields":
                return reporting.fields.map(util.toOptions);
            case "web.projectTypes":
                return reporting.project_types.map(util.toOptions);
            default:
                return this.props.translation.time_type_names["sv"].map(util.toOptions);
        }
    };
    /**
     * On change callback for table config
     *
     * @param value {string} On change value
     * @param props {object} On change properties
     */
    TableConfig.prototype.onChange = function (_a) {
        var value = _a.value, key = _a.key;
        var config_edit = util.clone(this.props.config_edit);
        util.setNestedObject(config_edit, key, value);
        this.props.setPageState({ config_edit: config_edit, uid: util.getUid() });
    };
    /**
     * On page size callback for table config
     *
     * @param page_size {number} Page size
     * @returns {Promise<void>}
     */
    TableConfig.prototype.onPageSizeChange = function (page_size) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.props.setPageState({ page_size: page_size });
                return [2 /*return*/];
            });
        });
    };
    /**
     * Get table cell render callback for table config
     *
     * @param config {object} Current config
     * @returns {(function({cell: *}): *)|*}
     */
    TableConfig.prototype.renderCell = function (config) {
        var self = this;
        return function (_a) {
            var cell = _a.cell;
            var key = cell.row.original.key;
            var value = cell.row.original.value;
            // let value = util.getNestedObject(config, key);
            switch (getRowType({ value: value, key: key })) {
                case "select":
                    return (React.createElement(InputSelectReact, { key: key, multi: false, onChange: function (_a) {
                            var value = _a.value;
                            return self.onChange({ value: value, key: key });
                        }, disabled: self.props.disabled, selected: util.toOptions(String(value)), options: self.getOptionsSelect(key) }));
                case "select-multi":
                    return (React.createElement(InputSelectReact, { key: key, onChange: function (_a) {
                            var value = _a.value;
                            return self.onChange({ value: value, key: key });
                        }, multi: true, disabled: self.props.disabled, selected: value.map(util.toOptions), options: self.getOptionsSelect(key, value.map(util.toOptions)) }));
                case "select-multi-creatable":
                    return (React.createElement(InputSelectReact, { key: key, creatable: true, onChange: function (_a) {
                            var value = _a.value;
                            return self.onChange({ value: value, key: key });
                        }, multi: true, disabled: self.props.disabled, selected: value.map(util.toOptions), options: self.getOptionsSelect(key, value.map(util.toOptions)) }));
                // return <TableSelectCreatable key={key}
                //                              onChange={({value}) => self.onChange({value, key})}
                //                              multi={true}
                //                              disabled={self.props.disabled}
                //                              selected={value.map(util.toOptions)}
                //                              options={self.getOptionsSelect(key, value.map(util.toOptions))}/>;
                case "object":
                    return (React.createElement(InputSelectReact, { key: key, multi: false, onChange: function (_a) {
                            var value = _a.value;
                            return self.onChange({ value: value, key: key });
                        }, disabled: self.props.disabled, selected: value.map(util.toOptions), options: self.getOptionsSelect(key, value.map(util.toOptions)) }));
                case "string":
                case "number":
                    return (React.createElement(InputText, { key: key, onBlur: function (_a) {
                            var value = _a.value;
                            return self.onChange({ value: value, key: key });
                        }, disabled: self.props.disabled, value: value }));
                case "color":
                    return (React.createElement(InputColor, { key: key, onChange: function (_a) {
                            var value = _a.value;
                            return self.onChange({ value: value.toUpperCase(), key: key });
                        }, disabled: self.props.disabled, value: value }));
                case "boolean":
                    return (React.createElement(InputCheckbox, { key: key, disabled: self.props.disabled, onChange: function (_a) {
                            var value = _a.value;
                            return self.onChange({ value: value, key: key });
                        }, value: value }));
                default:
                    return React.createElement("div", null, "unknown data");
            }
        };
    };
    TableConfig.prototype.render = function () {
        var config = ConfigTools.get({
            config_edit: this.props.config_edit,
            config: this.props.config,
            translation: this.props.translation,
        });
        var config_list = ConfigTools.toKeyValueList(config);
        // debug('config_list', config_list)
        config_list = util.sort(config_list, function (v) { return v.key; });
        config_list = config_list.reduce(function (tot, val) {
            var bool = true;
            exclude.table.forEach(function (v) {
                if (val.key.includes(v)) {
                    bool = false;
                }
            });
            if (bool) {
                tot.push(val);
            }
            return tot;
        }, []);
        var columns = [
            {
                id: "key",
                header: "Key",
                filterFn: "includesString",
                enableColumnFilter: true,
                enableSorting: true,
                accessorKey: "key", // String-based value accessors!
                // footer: props => props.column.id,
            },
            {
                id: "value",
                header: "Value",
                accessorKey: "value",
                enableColumnFilter: false,
                enableSorting: false,
                cell: this.renderCell(config),
                // footer: props => props.column.id,
            },
        ];
        return (React.createElement("div", { className: "table-config" },
            React.createElement(Table, { data: config_list, columns: columns, defaultPageSize: this.state.page_size, showPaginationTop: true, onPageSizeChange: this.onPageSizeChange, pageSizeOptions: [5, 10, 20, 25, 50, 100, config_list.length] })));
    };
    TableConfig.propTypes = {
        absence_time_types: PropTypes.array.isRequired,
        config: PropTypes.object.isRequired,
        config_edit: PropTypes.object.isRequired,
        page_size: PropTypes.number,
        disabled: PropTypes.bool,
        setPageState: PropTypes.func.isRequired,
        translation: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
    };
    return TableConfig;
}(Component));
export default TableConfig;
