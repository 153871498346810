/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import { Redirect, NavLink } from "react-router-dom";
import url from "../constants/url.jsx";
import SelectCustomerView from "../components/views/SelectCustomerView";
import ConfigSimpleView from "../components/views/ConfigSimpleView";
import ConfigIssueView from "../components/views/ConfigIssueView";
import ConfigSubmitView from "../components/views/ConfigSubmitView";
import { defaultConfig, validate } from "config-validation";
import ConfigTableView from "../components/views/ConfigTableView";
import ConfigTool from "../lib/config";
import util from "../lib/util";
import Storage from "../lib/storage";
import "./RoutingCreateEditShowDelete.scss";
var debug = require('debug')('bk-manager:frontend-react:routers:RoutingCreateEditShowDelete');
/**
 * Render create, edit or show views
 */
var RoutingCreateEditShowDelete = /** @class */ (function (_super) {
    __extends(RoutingCreateEditShowDelete, _super);
    function RoutingCreateEditShowDelete(props) {
        var _this = _super.call(this, props) || this;
        var tab = util.getQuery().tab;
        var tip = util.getTip();
        var redirect = '';
        if (!tab && location) {
            redirect = "".concat(url.APP_BASE, "/").concat(tip).concat(util.toQuery({ uid: 'default', tab: 'start' }));
        }
        if (location && !props.config && util.getUid() != '') {
            var goto = "".concat(url.APP_BASE, "/").concat(tip).concat(util.toQuery({ uid: '', tab: 'start' }));
            return location.href = goto;
        }
        var config = util.getUid() == ''
            ? {}
            : (util.getUid() == 'default'
                ? defaultConfig
                : props.config);
        _this.state = __assign(__assign({}, props), { redirect: redirect, config: config });
        _this.renderIssueView = _this.renderIssueView.bind(_this);
        _this.renderStart = _this.renderStart.bind(_this);
        _this.renderSimpleView = _this.renderSimpleView.bind(_this);
        _this.renderTableView = _this.renderTableView.bind(_this);
        _this.renderSubmitView = _this.renderSubmitView.bind(_this);
        _this.renderHeader = _this.renderHeader.bind(_this);
        _this.setPageState = _this.setPageState.bind(_this);
        _this.validate = _this.validate.bind(_this);
        return _this;
    }
    RoutingCreateEditShowDelete.prototype.validate = function () {
        var config = ConfigTool.get({
            config: this.state.config,
            config_edit: this.state.config_edit,
            translation: this.state.translation
        });
        var error = (config
            ? validate(config, this.props.translation.original)
            : { error: [] }).error;
        var res = { issues: error };
        return res;
    };
    RoutingCreateEditShowDelete.prototype.renderStart = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement(SelectCustomerView, { company_list: this.props.company_list, config: this.state.config, config_edit: this.state.config_edit || {}, label: 'Customer', missing_translations: this.props.missing_translations, type: util.getTip(), continue: !util.isEmptyObject(this.state.config) && {
                    label: 'Next >',
                    path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=").concat(util.getTip() == 'delete' ? 'submit' : 'simple')
                }, help_text: util.getTip() == 'create'
                    ? 'Pick a config to base new customer on'
                    : (util.getTip() == 'edit'
                        ? 'Pick a customer to edit' : (util.getTip() == 'delete'
                        ? 'Pick a customer to delete'
                        : 'Pick a customer to view')), onSelect: function (_a) {
                    var state = _a.state;
                    _this.setPageState(state, function () {
                        _this.setState({
                            redirect: "".concat(url.APP_BASE, "/").concat(util.getTip(), "?uid=").concat(state.uid, "&tab=start")
                        });
                    });
                }, translation: this.props.translation })));
    };
    RoutingCreateEditShowDelete.prototype.renderSimpleView = function () {
        // debug('renderSimpleView')
        return React.createElement(ConfigSimpleView, { absence_time_types: this.props.absence_time_types, allowance_mapping: this.state.allowance_mapping, config: this.state.config, config_edit: this.state.config_edit || {}, type: util.getTip(), back: {
                label: '< Back',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=start")
            }, continue: {
                label: 'Next >',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=table")
            }, to_submit: {
                label: 'To Submit',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=submit")
            }, disabled: this.props.disabled, setPageState: this.setPageState, subcompanies: this.state.subcompanies, translation: this.props.translation, locales_available_for_web: this.props.locales_available_for_web });
    };
    RoutingCreateEditShowDelete.prototype.renderTableView = function () {
        return React.createElement(ConfigTableView, { absence_time_types: this.props.absence_time_types, config: this.state.config, config_edit: this.state.config_edit || {}, page_size: this.state.page_size || 20, type: util.getTip(), back: {
                label: '< Back',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=simple")
            }, continue: {
                label: 'Next >',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=issue")
            }, to_submit: {
                label: 'To Submit',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=submit")
            }, disabled: this.props.disabled, translation: this.props.translation, setPageState: this.setPageState });
    };
    RoutingCreateEditShowDelete.prototype.renderIssueView = function () {
        // debug('renderIssueView')
        var issues = this.validate().issues;
        return React.createElement(ConfigIssueView, { issues: issues, back: {
                label: '< Back',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=table")
            }, continue: util.getTip() != 'show' && {
                label: 'Next >',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=submit")
            }, to_submit: {
                label: 'To Submit',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=submit")
            } });
    };
    RoutingCreateEditShowDelete.prototype.renderSubmitView = function () {
        var issues = this.validate().issues;
        return React.createElement(ConfigSubmitView, { config: this.state.config, config_edit: this.state.config_edit || {}, type: util.getTip(), issues: issues, back: {
                label: '< Back',
                path: "".concat(util.getTip(), "?uid=").concat(util.getUid(), "&tab=").concat(util.getTip() == 'delete' ? 'start' : 'issue')
            }, translation: this.props.translation, setPageState: this.setPageState });
    };
    RoutingCreateEditShowDelete.prototype.renderHeader = function (title, n_edits) {
        var config = this.state.config;
        var label;
        var uid = util.getUid();
        if (uid == 'file-import') {
            label = "Based on file import of ".concat(config.backend
                ? config.backend.companyName
                : (config.backend.companyName == ""
                    ? '""'
                    : 'unknown'));
        }
        else if (this.props.type === 'create') {
            label = "Based on ".concat(util.getUid() == 'default'
                ? 'default'
                : "".concat(config && config.backend
                    ? config.backend.companyName
                    : (config && config.backend && config.backend.companyName == ""
                        ? '""'
                        : 'unknown')));
        }
        else {
            label = "".concat(config && config.backend ? "".concat(config.backend.companyName, " (").concat(uid, ")") : '');
        }
        return React.createElement("div", { className: "header" },
            React.createElement("div", { className: "header-body" },
                React.createElement("div", { className: "row align-items-end" },
                    React.createElement("div", { className: "col" },
                        React.createElement("h5", { className: "header-pretitle" },
                            this.props.project_id,
                            !util.isEmptyObject(this.state.config) && ' | ',
                            React.createElement("div", { className: 'd-inline company-name' }, !util.isEmptyObject(this.state.config) && label),
                            n_edits > 0 && " | ",
                            React.createElement("div", { className: 'd-inline n-edits' }, n_edits > 0 && "".concat(n_edits, " edits"))),
                        React.createElement("h1", { className: "header-title" }, "".concat(title, " "))))));
    };
    RoutingCreateEditShowDelete.prototype.render = function () {
        var config_edit = this.state.config_edit;
        var n_edits = util.dicToList(util.flattenObject(config_edit)).length;
        var uid = util.getQuery().uid || '';
        return (React.createElement("div", null,
            this.state.redirect && React.createElement(Redirect, { push: true, to: this.state.redirect }),
            util.getTip() == 'create' && this.renderHeader("Create new customer", n_edits),
            util.getTip() == 'edit' && this.renderHeader("Edit existing customer", n_edits),
            util.getTip() == 'show' && this.renderHeader("View existing customer"),
            util.getTip() == 'delete' && this.renderHeader("Delete existing customer"),
            React.createElement("div", { className: 'config-customer' },
                React.createElement("div", { className: 'nav-tabs' },
                    React.createElement("ul", { className: 'nav container' },
                        React.createElement("li", { className: 'nav-item' },
                            React.createElement(NavLink, { className: 'nav-link', isActive: function () { return util.getQuery().tab == 'start'; }, to: "".concat(url.APP_BASE, "/").concat(util.getTip()).concat(util.toQuery({ uid: uid, tab: 'start' })) }, util.getTip() == 'create' ? "Pick template" : "Pick customer")),
                        !util.isEmptyObject(this.state.config) && util.getTip() != 'delete' &&
                            React.createElement(React.Fragment, null,
                                React.createElement("li", { className: 'nav-item' },
                                    React.createElement(NavLink, { className: 'nav-link', isActive: function () { return util.getQuery().tab == 'simple'; }, to: "".concat(url.APP_BASE, "/").concat(util.getTip()).concat(util.toQuery({
                                            uid: uid,
                                            tab: 'simple'
                                        })) }, "Simple view")),
                                React.createElement("li", { className: 'nav-item' },
                                    React.createElement(NavLink, { className: 'nav-link', isActive: function () { return util.getQuery().tab == 'table'; }, to: "".concat(url.APP_BASE, "/").concat(util.getTip()).concat(util.toQuery({
                                            uid: uid,
                                            tab: 'table'
                                        })) }, "Table view")),
                                React.createElement("li", { className: 'nav-item' },
                                    React.createElement(NavLink, { className: 'nav-link', isActive: function () { return util.getQuery().tab == 'issue'; }, to: "".concat(url.APP_BASE, "/").concat(util.getTip()).concat(util.toQuery({
                                            uid: uid,
                                            tab: 'issue'
                                        })) }, "Issues"))),
                        !util.isEmptyObject(this.state.config) && util.getTip() != 'show' &&
                            React.createElement("li", { className: 'nav-item' },
                                React.createElement(NavLink, { className: 'nav-link', isActive: function () { return util.getQuery().tab == 'submit'; }, to: "".concat(url.APP_BASE, "/").concat(util.getTip()).concat(util.toQuery({
                                        uid: uid,
                                        tab: 'submit'
                                    })) }, "Submit")))),
                React.createElement("hr", null),
                util.getQuery().tab == 'start' && this.renderStart(),
                util.getQuery().tab == 'simple' && this.renderSimpleView(),
                util.getQuery().tab == 'table' && this.renderTableView(),
                util.getQuery().tab == 'issue' && this.renderIssueView(),
                util.getQuery().tab == 'submit' && this.renderSubmitView())));
    };
    RoutingCreateEditShowDelete.prototype.setPageState = function (state, cb) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, config_edit, is_edit_update, key;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        debug('setPageState');
                        if (state.config_edit) {
                            _a = ConfigTool.removeValuesFromConfigEditThatAreEqualInConfig(state.config_edit, state.config || this.state.config), config_edit = _a.config_edit, is_edit_update = _a.is_edit_update;
                            if (is_edit_update) {
                                state.config_edit = config_edit;
                            }
                        }
                        cb = cb || (function () {
                        });
                        if (!(state.uid != '')) return [3 /*break*/, 2];
                        key = Storage.getKey(state.uid);
                        return [4 /*yield*/, Storage.set(key, state)];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        this.setState(state, cb);
                        return [2 /*return*/];
                }
            });
        });
    };
    return RoutingCreateEditShowDelete;
}(Component));
export default RoutingCreateEditShowDelete;
