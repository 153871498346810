/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from "react";
import './InputSalaryInstructions.scss';
import { Button } from "react-bootstrap";
import InputSelectReactSimpleView from "./InputSelectReactSimpleView";
import InputProjectId from "./InputProjectId";
import InputTextSimpleView from "./InputTextSimpleView";
import InputSelectSimpleView from "./InputSelectSimpleView";
import InputCheckboxSimpleView from "./InputCheckboxSimpleView";
import { swagger } from "config-validation";
import Util from "../../lib/util";
import Salary from "../../constants/salary";
import InputPaymentInstructionDuplicateRules from "./InputPaymentInstructionDuplicateRules";
var debug = require('debug')('bk-manager:frontend-react:components:InputSalaryInstructions');
/**
 * Generate help text for salary instruction component using swagger docs object
 *
 * @param key {string} Salary instruction key to get
 * @param docs {object} Swagger docs object
 * @returns {JSX.Element|{replace_project_id, rule, set_project_to, project_types: string}}
 */
function getHelpText(key, docs) {
    // let description = docs.instruction.properties[key].description
    var data = docs.instruction.properties[key];
    var help;
    switch (key) {
        case 'quantity_from':
        case 'salary_instruction_map':
            var pre = data.description.split('\n')[0];
            var list = data.description.split('\n').slice(1);
            help = React.createElement(React.Fragment, null,
                React.createElement("div", null, pre),
                React.createElement("ul", null, list.map(function (s, i) { return React.createElement("li", { key: i }, s); })));
            break;
        case 'replace_project_id':
            help = {
                replace_project_id: data.description,
                project_types: "".concat(data.properties.project_types.description, ". \n                ").concat(data.properties.project_types.items.description),
                rule: data.properties.rule.description,
                set_project_to: data.properties.set_project_to.description
            };
            break;
        default:
            help = data.description;
            break;
    }
    return help;
}
/**
 * Get options for salary instruction component using swagger docs object
 *
 * @param key {string} Salary instruction key to get
 * @param docs {object} Swagger docs object
 * @returns {{rule: {label: *, value: *}[], project_types: {label: *, value: *}[]}|{label: *, value: *}[]}
 */
function getOptions(key, docs) {
    var data = docs.instruction.properties[key];
    var options;
    switch (key) {
        case 'replace_project_id':
            options = {
                project_types: data.properties.project_types.items.enum.map(function (s) { return Util.toOptions(s); }),
                rule: data.properties.rule.enum.map(function (s) { return Util.toOptions(s); }),
            };
            break;
        case 'payment_instruction_duplicate_rules':
            options = data.items.properties.quantity_from.enum.map(function (s) { return Util.toOptions(s); });
            break;
        default:
            options = data.enum.map(function (s) { return Util.toOptions(s); });
            break;
    }
    return options;
}
/**
 * Get salary code title for payment instruction list
 *
 * @param instruction {object} payment instruction ( driver | time | allowance)
 * @returns {string|JSX.Element}
 */
function getSalaryCodeTitle(instruction) {
    var title = instruction.data_type;
    if (instruction.data_type == 'Beredskap') {
        title = "".concat(title, " for on call type ").concat(instruction.onCallType);
    }
    title = instruction.employee_type == 'default' ? "".concat(title) : "".concat(title, " for employee type ").concat(instruction.employee_type);
    if (instruction.is_duplicate) {
        return React.createElement(React.Fragment, null,
            React.createElement("span", null, "".concat(title)),
            React.createElement("span", { className: 'text-danger ml-2' }, 'Duplicate please remove'));
    }
    return React.createElement("span", null, "".concat(title));
}
/**
 * Render dot before text
 *
 * @param color {string} Color of dot
 * @returns {{alignItems: string, display: string, ":before": {marginRight: number, backgroundColor: string, borderRadius: number, display: string, width: number, content: string, height: number}}}
 */
function dot(color) {
    if (color === void 0) { color = '#ccc'; }
    return {
        alignItems: 'center',
        display: 'flex',
        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    };
}
/**
 * Generate add salary instruction buttons
 *
 * @param addError {function} Add error
 * @param addInstruction {function} Add instruction
 * @param instructions {array} List of instructions
 * @param onChange {function} On change callback
 * @param setAdd {function} Set add boolean
 * @param setAddError {function} Set add error
 * @param setAddInstruction {function} Set a new instruction
 * @returns {JSX.Element}
 */
function getAddButtons(_a) {
    var addError = _a.addError, addInstruction = _a.addInstruction, instructions = _a.instructions, onChange = _a.onChange, setAdd = _a.setAdd, setAddError = _a.setAddError, setAddInstruction = _a.setAddInstruction;
    return React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Button, { className: 'mb-3 mr-2', onClick: function () {
                    if (!addInstruction.payTypeId) {
                        setAddError('Salary code needs to be set');
                        return;
                    }
                    if (!addInstruction.data_type) {
                        setAddError('Data type needs to be set');
                        return;
                    }
                    var current_instructions_dic = Util.listToDic(instructions, function (i) { return Util.getInstructionKey(i); });
                    if (current_instructions_dic[Util.getInstructionKey(addInstruction)]) {
                        setAddError("Instruction already exist for data type ".concat(addInstruction.data_type, "\n                             and employee type ").concat(addInstruction.employee_type));
                        return;
                    }
                    onChange({ value: Util.clone(addInstruction) });
                    var add_instruction = Salary.DEFAULT_INSTRUCTION;
                    add_instruction.uid = Util.autoId();
                    setAddInstruction(add_instruction);
                    setAdd(false);
                    setAddError('');
                } }, 'Done'),
            React.createElement(Button, { className: 'mb-3 btn btn-secondary', onClick: function () {
                    var add_instruction = Salary.DEFAULT_INSTRUCTION;
                    add_instruction.uid = Util.autoId();
                    setAddInstruction(add_instruction);
                    setAdd(false);
                    setAddError('');
                } }, 'Cancel')),
        addError && React.createElement("div", { className: 'alert alert-danger mb-3' }, addError));
}
/**
 * Generate items from a instruction
 *
 * @param docs {object} Swagger documentation object
 * @param props {object} Props
 * @param instruction {object} Salary instruction
 * @param onChange {function} On change callback
 * @param index {number} Instruction index
 * @returns {*[]}
 */
function getItems(_a) {
    var docs = _a.docs, props = _a.props, instruction = _a.instruction, onChange = _a.onChange, index = _a.index;
    var items = [];
    var _loop_1 = function (key, label, options, required) {
        var _onChange = function (_a) {
            var _b;
            var value = _a.value;
            debug('_onChange', value, index);
            onChange({
                value: (_b = {}, _b[key] = value, _b),
                index: index,
                uid: instruction.uid
            });
        };
        if (key == 'onCallType' && instruction.data_type != 'Beredskap') {
            return "continue";
        }
        var item = void 0;
        switch (key) {
            case 'absence_salary_code':
            case 'add_extent_if_quantity_is_below_eight':
            case 'adjust_sick_compensation':
            case 'driving_salary_code':
            case 'karlssons_on_leave':
            case 'service_add_s_to_project':
                item = React.createElement(InputCheckboxSimpleView, { key: key, disabled: props.disabled, label: label, onChange: _onChange, value: instruction[key] || false, help: getHelpText(key, docs) });
                break;
            case 'copy_supervisor_entry_and_create_user_entry':
            case 'note':
            case 'payTypeCode':
            case 'payTypeId':
                item = React.createElement(InputTextSimpleView, { key: key, required: required, disabled: props.disabled, label: label, onChange: _onChange, value: instruction[key] || '', help: getHelpText(key, docs) });
                break;
            case 'employee_type':
            case 'quantity_from':
            case 'salary_instruction_map':
                item = React.createElement(InputSelectSimpleView, { key: key, required: required, disabled: props.disabled, label: label, onChange: _onChange, value: instruction[key] || '', options: options, help: getHelpText(key, docs) });
                break;
            case 'data_type':
                var colourStyles = {
                    singleValue: function (styles, _a) {
                        var data = _a.data, isDisabled = _a.isDisabled, isFocused = _a.isFocused, isSelected = _a.isSelected;
                        return __assign(__assign({}, styles), dot(props.data_type_options.includes(data.value) ? 'green' : 'red'));
                    }
                };
                item = React.createElement(InputSelectReactSimpleView, { label: 'Data type', key: key, required: required, disabled: props.disabled, creatable: true, onChange: _onChange, selected: Util.toOptions(instruction[key] || ''), options: props.data_type_options.map(Util.toOptions), styles: colourStyles, help: getHelpText(key, docs) });
                break;
            case 'payment_instruction_duplicate_rules':
                item = React.createElement(InputPaymentInstructionDuplicateRules, { disabled: props.disabled, quantity_from_options: options, key: key, rules: instruction.payment_instruction_duplicate_rules || [], onChange: _onChange });
                break;
            case 'replace_project_id':
                item = React.createElement(InputProjectId, { disabled: props.disabled, key: key, value: instruction.replace_project_id, options: options, help: getHelpText(key, docs), onChange: _onChange });
                break;
            default:
                if (key in Object.keys(instruction)) {
                    var key_not_setup = key;
                    item = React.createElement("div", { key: key }, "".concat(key, " - ").concat(typeof instruction[key_not_setup] == 'object'
                        ? JSON.stringify(instruction[key_not_setup])
                        : instruction[key_not_setup]));
                }
                else {
                    item = React.createElement("div", { key: key }, "Missing key: ".concat(key, " in instruction"));
                }
                break;
        }
        items.push(item);
    };
    for (var _i = 0, _b = [
        {
            key: 'payTypeId',
            label: 'Salary code',
            required: true
        },
        {
            key: 'data_type',
            label: ' Data type',
            required: true
        },
        {
            key: 'note',
            label: 'Note'
        },
        {
            key: 'employee_type',
            label: 'Employee type',
            options: getOptions('employee_type', docs)
        },
        {
            key: 'payTypeCode',
            label: 'Pay type code'
        },
        {
            key: 'quantity_from',
            label: 'Salary quantity instruction',
            options: getOptions('quantity_from', docs)
        },
        {
            key: 'salary_instruction_map',
            label: 'Report mapping',
            options: getOptions('salary_instruction_map', docs)
        },
        {
            key: 'absence_salary_code',
            label: 'Absence salary instruction'
        },
        {
            key: 'service_add_s_to_project',
            label: 'Add S before project code'
        },
        {
            key: 'add_extent_if_quantity_is_below_eight',
            label: 'Add extent if quantity is below eight'
        },
        {
            key: 'adjust_sick_compensation',
            label: 'Adjust sick compensation'
        },
        {
            key: 'copy_supervisor_entry_and_create_user_entry',
            label: 'Copy supervisor entry and create user entry'
        },
        {
            key: 'payment_instruction_duplicate_rules',
            label: 'Make a duplicate and change it accordingly specified rules',
            options: getOptions('payment_instruction_duplicate_rules', docs)
        },
        {
            key: 'driving_salary_code',
            label: ' Driving salary instruction'
        },
        {
            key: 'karlssons_on_leave',
            label: 'Karlssons on leave'
        },
        {
            key: 'replace_project_id',
            label: 'Replace project id',
            options: getOptions('replace_project_id', docs)
        },
    ]; _i < _b.length; _i++) {
        var _c = _b[_i], key = _c.key, label = _c.label, options = _c.options, required = _c.required;
        _loop_1(key, label, options, required);
    }
    return items;
}
/**
 *
 * Render salary instructions input
 *
 * @param props {object} Input salary instruction properties
 * @returns {*}
 * @constructor
 */
export default function InputSalaryInstructions(props) {
    props.instructions.map(function (v) {
        if (!v.uid) {
            v.uid = Util.autoId();
        }
        // if (v.is_dublicate != undefined) {
        //     delete v.is_dublicate
        // }
    });
    debug(props.instructions);
    var add_instruction = Salary.DEFAULT_INSTRUCTION;
    add_instruction.uid = Util.autoId();
    var _a = useState(props.instructions || []), instructions = _a[0], setInstructions = _a[1];
    var _b = useState([]), edit = _b[0], setEdit = _b[1];
    var _c = useState(false), add = _c[0], setAdd = _c[1];
    var _d = useState(''), addError = _d[0], setAddError = _d[1];
    var _e = useState(add_instruction), addInstruction = _e[0], setAddInstruction = _e[1];
    useEffect(function () {
        setInstructions(props.instructions);
    }, [props.instructions]);
    var docs = swagger().definitions.config.properties;
    var visited_instructions = [];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'd-flex flex-column input-salary-instructions' },
            React.createElement("h3", { className: 'mt-3' }, "Salary instructions"),
            React.createElement("div", { className: 'flex-row' }, !add && React.createElement(Button, { className: 'mb-3', onClick: function () { return setAdd(true); } }, 'Add instruction')),
            add
                &&
                    React.createElement("div", { className: 'p-4 border border-light rounded' },
                        React.createElement("div", { className: 'flex-row' }, getAddButtons({
                            addError: addError,
                            addInstruction: addInstruction,
                            instructions: instructions,
                            onChange: props.onChange,
                            setAdd: setAdd,
                            setAddInstruction: setAddInstruction,
                            setAddError: setAddError
                        })),
                        getItems({
                            docs: docs,
                            instruction: addInstruction,
                            props: props,
                            onChange: function (_a) {
                                var value = _a.value;
                                setAddInstruction(__assign(__assign({}, addInstruction), value));
                            },
                        }),
                        React.createElement("div", { className: 'flex-row' }, getAddButtons({
                            addError: addError,
                            addInstruction: addInstruction,
                            instructions: instructions,
                            onChange: props.onChange,
                            setAdd: setAdd,
                            setAddInstruction: setAddInstruction,
                            setAddError: setAddError,
                        }))),
            React.createElement("div", { className: 'border-bottom border-top border-light' }, instructions.map(function (instruction, index) {
                var instruction_key = Util.getInstructionKey(instruction);
                if (!visited_instructions.includes(instruction_key)) {
                    visited_instructions.push(instruction_key);
                    instruction.is_duplicate = false;
                }
                else {
                    instruction.is_duplicate = true;
                }
                var items = getItems({
                    docs: docs,
                    instruction: instruction,
                    props: props,
                    onChange: props.onChange,
                    index: index
                });
                return React.createElement("div", { key: instruction.uid },
                    React.createElement("div", { className: 'd-flex flex-row justify-content-between salary-instruction-row p-1', title: "".concat(props.disabled ? 'View' : 'Edit', " ").concat(getSalaryCodeTitle(instruction)) },
                        React.createElement("div", { className: "data-type data-type-".concat(props.data_type_options.includes(instruction.data_type)
                                ? 'green'
                                : 'red'), onClick: function () {
                                if (props.disabled) {
                                    return;
                                }
                                var edit_change = __spreadArray([], edit, true);
                                if (edit.includes(instruction.uid)) {
                                    edit_change = edit_change.reduce(function (tot, e) {
                                        if (e != instruction.uid) {
                                            tot.push(e);
                                        }
                                        return tot;
                                    }, []);
                                }
                                else {
                                    edit_change.push(instruction.uid);
                                }
                                setEdit(edit_change);
                            } }, getSalaryCodeTitle(instruction)),
                        React.createElement("i", { title: "Delete ".concat(getSalaryCodeTitle(instruction)), className: "fa fa-trash m-1", "aria-hidden": "true", onClick: function () {
                                if (props.disabled) {
                                    return;
                                }
                                props.onChange({
                                    index: index,
                                    remove: true
                                });
                            } })),
                    edit.includes(instruction.uid) &&
                        React.createElement("div", { className: 'd-flex flex-column ml-5 mb-5 mt-2' },
                            items.map(function (item, i) { return React.createElement("div", { key: i, className: 'mb-3' }, item); }),
                            React.createElement(Button, { onClick: function () {
                                    var edit_change = __spreadArray([], edit, true);
                                    if (edit.includes(instruction.uid)) {
                                        edit_change = edit_change.reduce(function (tot, e) {
                                            if (e != instruction.uid) {
                                                tot.push(e);
                                            }
                                            return tot;
                                        }, []);
                                    }
                                    else {
                                        edit_change.push(instruction.uid);
                                    }
                                    setEdit(edit_change);
                                } }, edit.includes(instruction.uid) ? 'Close' : 'Open')));
            })))));
}
