/**
 *Created by Mikael Lindahl on 2019-10-21
 */

"use strict";

let Joi = require("joi");

const payTypeId_description = "Pay type id for salary instruction";
const quantity_from_description =
  "Rule for how quantity should be set for salary instruction Eligible:\n" +
  '"distance" - from attribute distance on report\n' +
  '"distance-divide_by_ten" - Change unit from kilometer to swedish miles of attribute distance on report by multiplying ' +
  "with one tenth\n" +
  '"hours" - from attribute hours on report\n' +
  '"hours-divide_by_eight" -  Change from  hours to days of attribute hours on report by multiplying ' +
  "with one eight\n" +
  '"hours-exclude_from_project_distribution" - Exclude this time type from calculating project time  ' +
  "distribution (BookDistributionProjects) in visma\n" +
  '"number_of_days" - set quantity to number of days in a date period for a set of reports filtered on period ' +
  "start date and period end date. Start and end period is either for range map start and end date for " +
  "a the filtered set of reports or the salary period start and end date. To learn how reports are filtered " +
  'see attribute "salary_instruction_map"\n' +
  '"passengerDistance" - from attribute passengerDistance on report\n' +
  '"passengerDistance-divide_by_ten"   - Change unit from kilometer to swedish miles of attribute passengerDistance on report by multiplying ' +
  "with one tenth\n" +
  '"one" - set quantity to one\n' +
  '"sum_of_reported_days" - sum of reported days. If report mapping is set to project then this will sum the number of reports that has been submitted in a salary period for that project';
let quantity_from_valid = [
  "distance",
  "distance-divide_by_ten",
  "hours",
  "hours-divide_by_eight",
  "hours-exclude_from_project_distribution",
  "number_of_days",
  "passengerDistance",
  "passengerDistance-divide_by_ten",
  "one",
  "sum_of_reported_days",
];

const instruction = Joi.object({
  absence_salary_code: Joi.boolean().description(
    "Set to true to have absence " + "xml formatting for Kontek salary files"
  ),
  add_extent_if_quantity_is_below_eight: Joi.boolean().description(
    "Add the attribute extent " +
      "to salary instruction if quantity is below eight. Extent is calculated as quantity * 100 / 8 " +
      "Used by åby at time of writing"
  ),
  adjust_sick_compensation: Joi.boolean().description(
    "Adjust sick compensation if " +
      "there are more than 14 reports or more. Then subtract the amount of reports " +
      " with 14 and subtract the remainder from the compensation. Used by åby and " +
      "karlssons at time of writing"
  ),
  copy_supervisor_entry_and_create_user_entry: Joi.string()
    .allow("")
    .description(
      "If set, the supervisor " +
        "entry will be copied and the copy will receive the pay type id that this attribute is set to. Leave empty to " +
        "exclude this options. At time of writing only Åby is using this feature"
    ),
  data_type: Joi.string().description(
    "Data type as a time type, driving type or allowance type. " +
      'E.g. "Timlön", "Sjuk" or "Traktamente Hel". The time and driving types showed here is controlled by ' +
      "the customers available time and driving types. Full list of available allowance types are always shown"
  ),
  driving_salary_code: Joi.boolean().description(
    "Set to true to indicate driving " + "formatting for Kontek salary files"
  ),
  employee_type: Joi.string()
    .valid("default", "1", "2")
    .description(
      'The employee type the instruction is valid for. If set to "default" then the instruction is valid for ' +
        "all employee types (1 and 2). However if set to 1 or 2 then " +
        "this will be used instead of the default. Employee type 1=arbetare and 2=tjänsteman"
    ),
  is_duplicate: Joi.boolean().description(
    "Indicate if entry is duplicate with regard to data type and employee type"
  ),
  karlssons_on_leave: Joi.boolean().description(
    "Adjust periodStartTime and periodEndTime where " +
      "periodStartTime is set to 08:00:00 and periodEndTime is set to periodStartTime + quantity. " +
      "If this property is set to true then salary_instruction_map should probably be set to " +
      "report_range. At time of writing such configuration is valid for alltMurning, karlssons and " +
      "mmp"
  ),
  note: Joi.string()
    .allow("")
    .description(
      "Note to put on salary instruction. Hogia - works for all; Kontek " +
        "- not implemented; Visma - Implemented for quantity hours, distance and passangerDistance "
    ),
  payment_instruction_duplicate_rules: Joi.array()
    .items(
      Joi.object({
        payTypeId: Joi.string().description(payTypeId_description),
        quantity_from: Joi.string()
          .valid("hours", "hours-exclude_from_project_distribution")
          .description(quantity_from_description),
        only_for_salary_add_on_user_group: Joi.boolean().description(
          "Only apply rule for salary add on employee ids group"
        ),
      })
    )
    .description(
      "List of rule sets for duplication. One row for each duplication"
    ),
  payTypeCode: Joi.string().description(
    "Pay type code. Only used by hogia. E.g. L or A"
  ),
  payTypeId: Joi.string().description(payTypeId_description),
  quantity_from: Joi.string()
    .valid(...quantity_from_valid)
    .description(quantity_from_description),
  replace_project_id: Joi.object({
    project_types: Joi.array()
      .items(
        Joi.string()
          .valid("1", "2", "3")
          .description(
            "Project type numbers: " + "1=project, 2=service and 3=äta"
          )
      )
      .description(
        'Filter for project types. Is used if rule="for_project_types"'
      ),
    rule: Joi.string()
      .valid("", "for_project_types", "for_this_salary_type")
      .description("Rule determining when to change project id"),
    set_project_to: Joi.string()
      .allow("")
      .description("Value to set project id to when a specific rule is valid"),
  }).description(
    "Instruction to change project id if needed. Can be set for this salary type " +
      'with rule "for_this_salary_type" or dependent on project type with rule the ' +
      '"for_project_types"'
  ),
  salary_instruction_map: Joi.string()
    .valid(
      "report",
      "report_range",
      "report_limit_to_one_per_day",
      "project",
      "project_range"
    )
    .description(
      "Rule how reports should be batched when creating a salary instruction. " +
        "Eligible maps:\n" +
        '"report" - one report maps to one instruction\n' +
        '"report_range" - same as report except periodStartDate and periodEndDate are set to the min ' +
        "and max of filtered reports instead of min and max supplied on salary export " +
        "from web admin\n" +
        '"report_limit_to_one_per_day" - one per report. Limit reports per day so one\n' +
        '"project" - reports are filtered on project and then each filtered set is mapped to one instruction\n' +
        '"project_range" - reports are first filter on project and the reports are collapsed based on ' +
        "date ranges to a smaller set of reports and then map all reports to one " +
        "instruction. reports are collapsed based on date ranges to a smaller set of reports and " +
        "then map each report to one instruction. The reports are considered to be " +
        "part of the same range if they  are within one day of each other. Weekends " +
        "are by default ignored i.e. a report on a Friday and then another report on " +
        "the following Monday are considered being in the same interval. The rule of " +
        "ignoring weekends are not valid for report type 60 (Allowance 1). Also it is " +
        'a special case for åby sick by setting the attribute "aby_sick_special_case" ' +
        "to true. Then all reports are considered to be in different intervals."
    ),
  service_add_s_to_project: Joi.boolean().description(
    "Add the letter S before service code"
  ),
  uid: Joi.string().description("Unique identifier"),
});

const salarySystem = Joi.object({
  companies: Joi.array()
    .items({
      aby_by_month: Joi.boolean().description(
        "Process reports by months instead of all at the same time. Only " +
          "used by åby at time of writing"
      ),
      aby_sick_special_case: Joi.boolean().description(
        "Special project filter for aby sick. Eligable for " +
          'reports with reportTypeId=10, timeType="Sjuk" and hours<8. Only used by åby at time of writing'
      ),
      aby_replace_project_uid_for_service: Joi.boolean().description(
        "For service reports replace the " +
          "project id with this value. Only used by åby at time of writing"
      ),
      allowance: Joi.object({
        version_1_active: Joi.boolean().description(
          "Indicate if allowance version 1 should be used"
        ),
        version_2_active: Joi.boolean().description(
          "Indicate if allowance version 2 should be used"
        ),
      }).description("Set allowed allowance version/s"),
      instructions: Joi.array()
        .items(instruction)
        .description("List with configurations for data types"),
      id: Joi.string().description(
        "Company identifier. Should be either default or company name." +
          " Company name (can be found at https://www.allabolag.se)"
      ),
      info: Joi.object({
        company_name: Joi.string().description(
          "Company name as entered in the customers salary program. NOTE!!! If " +
            "this company is the default company then this has to correspond to the company name in section 2.1. " +
            "If also this company is a subcompany then the name needs to correspond with the subcompany name in web admin. " +
            "Otherwise salary export will not work!"
        ),
        organization_number: Joi.string().description(
          "Company organization number (can be found at https://www.allabolag.se)"
        ),
      }).description("Company information"),
      salary_addon_employee_ids_group: Joi.array()
        .items(Joi.string())
        .description(
          "List of employee ids that can be used to filter on for salary addon"
        ),
    })
    .description(
      "Salary configurations for customer main company and sub-companies. NOTE!!! The company default company must " +
        "match the default company name for salary export exactly. This can be configured in salary export, in section 2.1. " +
        "If your have subcompanies then it need to match exactly as defined in web admin. " +
        "Salary export will not work if there is a mismatch in company name!"
    ),
  default_company: Joi.string()
    .allow("")
    .description(
      "Default company for salary system. The company name must match the " +
        "default company name for salary export exactly. This can be configured in salary export, in section 2.1."
    ),
  extension: Joi.string()
    .allow(".xml", ".tlu")
    .description("Extension of file to export for salary system"),
  legacy: Joi.boolean()
    .required()
    .description(
      "Set if legacy (old) salary code should run. Only possible for customer who had salary" +
        " exports in the legacy system"
    ),
  module: Joi.string().description(
    "Name of .js file used for this customer that is in folder /BKSalary/salaryModules"
  ),
  type: Joi.string()
    .allow("fortnox", "hogia", "kontek", "visma", "")
    .description("Salary system used"),
}).description("Setup salary system");

const backend = {
  scaffoldRental: Joi.boolean().description("Enable Scaffold rental"),
  absenceTimeTypes: Joi.array()
    .items(Joi.string())
    .description("Time types for absence"),
  company: Joi.string().invalid("").description("Short company name"),
  companyName: Joi.string().invalid("").description("Long company name"),
  cron: Joi.object({
    automaticReporter: Joi.boolean()
      .required()
      .description("Generate automatic reports"),
    cleanGlobalData: Joi.boolean()
      .required()
      .description("Clean global data job"),
    projectSummariesValidation: Joi.boolean()
      .required()
      .description("Validate projects"),
    pushNotificationFriday: Joi.boolean()
      .required()
      .description("Send reminder if you missed to report on friday"),
    pushNotificationMonday: Joi.boolean()
      .required()
      .description("Send reminder if you missed to report on monday"),
    removalProjectChecklists: Joi.boolean()
      .required()
      .description("Remove project old checklist both global and master"),
    removeAdditionalHistoryInformation: Joi.boolean()
      .required()
      .description(
        "For äta/QA remove obsolete information that has been generated over time"
      ),
    userEducationReminder: Joi.boolean()
      .required()
      .description("Education reminder"),
    userMissingHoursAndAttests: Joi.boolean()
      .required()
      .description("Validate result from hours and attest calculations"),
    verifyDateAndSendCompleteUserCount: Joi.boolean()
      .required()
      .description("Sum of active users"),
  })
    .required()
    .description("Cron jobs to be turned on or off"),
  decidedHoursNegative: Joi.array()
    .items(Joi.string())
    .description(
      "Time types that are not included in ackord. Used to distribute money for ackord"
    ),
  language: Joi.string().description(
    "Controls the default language of byggkollen front-end, the default language of email " +
      "text/email pdf's and the default language for external pages in Byggkollen"
  ),
  legacyWebDomain: Joi.string()
    .allow("")
    .description("Deprecated. Legacy domain. Not used anymore"),
  locationBasedReporting: Joi.boolean().description(
    "Location based reporting: Users checkin and checkout to create time reports. Some users" +
      " are required to be onsite, otherwise their reports will be flagged. Project and/or service location " +
      "is used to assess if the users' location is sufficiently close to the worksite. Absence reporting " +
      "is never location based and the only type of reporting allowed from the web."
  ),
  salarySystem,
  supervisorReporting: Joi.boolean()
    .required()
    .description(
      "Supervisor reporting: Is when the project supervisor reports all hours on their project " +
        "(both their own worked hours and the worked hours of others on their team). Users report individually " +
        "for driving and other project reporting, and may view the hours that have been reported for them."
    ),
  notifySupervisorNewATA: Joi.boolean().description(
    "Notify supervisor when new Äta is created"
  ),
  accuraseeReady: Joi.boolean().description("Accurasee ready"),
  accuraseeIntegration: Joi.boolean().description("Has Accurasee Integration"),
  accuraseeIntegrationAPIKey: Joi.string().description(
    "Accurasee Integration API Key"
  ),
  warningTargetHours: Joi.array()
    .items(Joi.string())
    .description("Overtime rules"),
  overtimeRules: Joi.array().items(Joi.object()).description("Overtime rules"),
  advanceDocumentHandling: Joi.boolean().description(
    "Enable advance document handling"
  ),
  advanceDocumentConfig: Joi.object({
    storageType: Joi.string().description("One Drive or Sharepoint"),
    storageUrl: Joi.string().description(
      "Url of business/personal One drive or Sharepoint"
    ),
    storageUseSharedFolder: Joi.string().description(
      "Use shared folder or personal folder" +
        "\n If user shared folder pls included shared user in path example sebastian_isolve_se/Documents..."
    ),
    storageSaveLocation: Joi.string().description(
      "Path to save document in One drive or Sharepoint"
    ),
    storageTemplateLocation: Joi.string().description(
      "Path to template folder in One drive or Sharepoint"
    ),
    storageSharedTemplateUrl: Joi.string().description(
      "Path to shared url of template location"
    ),
    storageSharedSaveLocationUrl: Joi.string().description(
      "Path to shared url of saved location"
    ),
  }),
};

const mobile = {
  ataTypes: Joi.array().items(Joi.string()).description("Types of äta"),
  serviceTypes: Joi.array()
    .items(Joi.string())
    .description("Service price types"),
  absenceTimeTypes: Joi.array().items(Joi.string()),
  accentColor: Joi.string().required().description("Secondary color"),
  ataForAll: Joi.boolean()
    .required()
    .description("Allow all users to see and report äta"),
  bulletinBoardEnabled: Joi.boolean()
    .required()
    .description("Show bulletin board in apps"),
  clientType: Joi.number()
    .valid(1, 2, 3, 4)
    .required()
    .description("Client typ 1=karlsson 2=Karling 3=Åby and 4=Meb"),
  drivingTypes: Joi.object({
    project: Joi.array().items(Joi.string()),
    serviceorder: Joi.array().items(Joi.string()),
  }).description("Driving types for project or service order"),
  enableReportingProjectRunningMaterialEquipment: Joi.boolean()
    .required()
    .description(
      "For mobile set whether or not everyone or only suppervisor can report material/equipment " +
        "on a running project"
    ),
  endingSalaryDay: Joi.number()
    .max(28)
    .min(0)
    .required()
    .description("Ending day for salary period"),
  knowledgeBaseUrl: Joi.string()
    .allow("")
    .required()
    .description("Link to knowledge base"),
  locationBasedReporting: Joi.boolean()
    .valid(Joi.ref("$backend.locationBasedReporting"))
    .description(
      "Location based reporting: Users checkin and checkout to create time reports. Some users" +
        " are required to be onsite, otherwise their reports will be flagged. Project and/or service location " +
        "is used to assess if the users' location is sufficiently close to the worksite. Absence reporting" +
        " is never location based and the only type of reporting allowed from the web."
    ),
  mainMenu: Joi.object({
    handbook: Joi.boolean().required().description("Show handbook"),
    myinbox: Joi.boolean().required().description("Show myinbox"),
    myreports: Joi.boolean().required().description("Show myreports"),
    projects: Joi.boolean().required().description("Show projects"),
    serviceorders: Joi.boolean().required().description("Show serviceorders"),
    settings: Joi.boolean().required().description("Show settings"),
  })
    .required()
    .description("Visibility of main menu items"),
  mebReportFlow: Joi.boolean()
    .valid(Joi.ref("$web.active_modules.mebReportFlow"))
    .description("Implement meb report flow"),
  primaryColor: Joi.string().description("Primary color"),
  projectMenu: Joi.object({
    ata: Joi.boolean().required().description("Show äta"),
    checklists: Joi.boolean().required().description("Show checklist"),
    deviations: Joi.boolean().required().description("Show deviations"),
    diaries: Joi.boolean().required().description("Show diaries"),
    documents: Joi.boolean().required().description("Show documents"),
    drivingExpense: Joi.boolean()
      .required()
      .description("Show driving expense"),
    hazard1: Joi.boolean()
      .required()
      .description("Show tillbud and call it AJ! OJ!"),
    hazard2: Joi.boolean()
      .required()
      .description("Show tillbud and call id tillbud"),
    notes: Joi.boolean().required().description("Show notes"),
    questionsAnswer: Joi.boolean()
      .required()
      .description("Show question and answer"),
    reporting: Joi.boolean().required().description("Show reporting"),
    safety_round: Joi.boolean().required().description("Show safety round"),
    traktamenteOne: Joi.boolean()
      .required()
      .description("Show traktamente one"),
    traktamenteTwo: Joi.boolean().description("Show traktamente two"), // Required ?
  })
    .required()
    .description("Visibility of project menu items"),
  quickText: Joi.array()
    .min(0)
    .items(Joi.string())
    .description("Set quick selection options in project diary"),
  remoteDrivingTypes: Joi.object({
    project: Joi.array().items(Joi.string()),
    serviceorder: Joi.array().items(Joi.string()),
  }).description("Driving types for project or service order"),
  remoteTimeTypes: Joi.object({
    projectFixed: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for project fixed user"),
    projectFixedLagbas: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for project fixed supervisor"),
    projectRunning: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for project running user"),
    projectRunningLagbas: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for project running supervisor"),
    serviceorder: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for serviceorder"),
    subSupplier: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for sub supplier"),
  }).description("Time types"),
  serviceorderQuickText: Joi.array()
    .min(0)
    .items(Joi.string())
    .description("Set quick selection options in serviceorder diary"),
  startingSalaryDay: Joi.number()
    .max(28)
    .min(0)
    .required()
    .description("Staring day for salary period"),
  supervisorReporting: Joi.boolean()
    .valid(Joi.ref("$backend.supervisorReporting"))
    .description(
      "Supervisor reporting: Is when the project supervisor reports all hours on their project " +
        "(both their own worked hours and the worked hours of others on their team). Users report individually " +
        "for driving and other project reporting, and may view the hours that have been reported for them."
    ),
  timeTypes: Joi.object({
    projectFixed: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for project fixed user"),
    projectFixedLagbas: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for project fixed supervisor"),
    projectRunning: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for project running user"),
    projectRunningLagbas: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for project running supervisor"),
    serviceorder: Joi.array()
      .items(Joi.string())
      .min(1)
      .description("Time types for serviceorder"),
    subSupplier: Joi.array()
      .items(Joi.string())
      .description("Time types for sub supplier"),
  }).description("Time types"),
  overtimeRules: Joi.array().items(Joi.object()).description("Overtime rules"),
  accuraseeReady: Joi.boolean().description("Accurasee ready"),
  accuraseeIntegration: Joi.boolean().description("Has Accurasee Integration"),
  taskList: Joi.boolean().description("Enable task list feature"),
  time: Joi.object({
    minTimeInterval: Joi.number().description("Min time interval"),
  }).description("Time"),
};

const web = {
  allowDuplicatedProjectId: Joi.boolean().description(
    "Allow duplicated project id"
  ),
  projectEvaluation: Joi.boolean().description("Enable project evaluation"),
  taskList: Joi.boolean().description("Enable task list feature"),
  scaffoldRental: Joi.boolean().description("Enable scaffold rental"),
  accuraseeReady: Joi.boolean().description("Accurasee ready"),
  accuraseeIntegration: Joi.boolean().description("Has Accurasee Integration"),
  absenceTimeTypes: Joi.array()
    .items(Joi.string())
    .description("Time types for absence"),
  active_modules: Joi.object({
    attest: Joi.boolean().required().description("Show attest in header"),
    attestOvertimeSummary: Joi.boolean()
      .required()
      .description("Show attest overtime summary tab"),
    equipment: Joi.boolean()
      .required()
      .description('Show "hjälpmedel" under settings->resources'),
    material: Joi.boolean()
      .required()
      .description('Show "material" under settings->resources'),
    mebReportFlow: Joi.boolean().description("N.d."),
    personal: Joi.object({
      absence: Joi.boolean()
        .required()
        .description('Visibility of "Personal->Planerad frånvaro" tab'),
      board: Joi.boolean()
        .valid(Joi.ref("$mobile.bulletinBoardEnabled"))
        .required()
        .description('Visibility of "Personal->Anslagstavla" tab'),
      education: Joi.boolean()
        .required()
        .description('Visibility of "Personal->Utbildningar" tab'),
      handbook: Joi.boolean()
        .valid(Joi.ref("$mobile.mainMenu.handbook"))
        .required()
        .description('Visibility of "Personal->Personalhandbok" tab'),
      users: Joi.boolean()
        .required()
        .description('Visibility of "Personal->Anställda" tab'),
    })
      .required()
      .description("Visibility personal tabs"),
    project: {
      ATA: Joi.boolean()
        .valid(Joi.ref("$mobile.projectMenu.ata"))
        .required()
        .description('Visibility of "Project->{pick a project}->ÄTA" option'),
      checklist: Joi.boolean()
        .valid(Joi.ref("$mobile.projectMenu.checklists"))
        .required()
        .description(
          'Visibility of "Project->{pick a project}->Noteringar" option'
        ),
      deviations: Joi.boolean()
        .valid(Joi.ref("$mobile.projectMenu.deviations"))
        .required()
        .description(
          'Visibility of "Project->{pick a project}->Avikelser" option'
        ),
      diary: Joi.boolean()
        .required()
        .description(
          'Visibility of "Project->{pick a project}->Dagbok" option'
        ),
      documents: Joi.boolean()
        .required()
        .description(
          'Visibility of "Project->{pick a project}->[box] Dokument" option'
        ),
      internal_documents: Joi.boolean()
        .required()
        .description(
          'Visibility of "Project->{pick a project}->[box] Interna dokument" option'
        ),
      invoices: Joi.boolean()
        .required()
        .description(
          'Visibility of "Project->{pick a project}->[box] Egna fakturor" option'
        ),
      ml_proj_overrun: Joi.boolean()
        .required()
        .description("Show project overrun tool"),
      notes: Joi.boolean()
        .valid(Joi.ref("$mobile.projectMenu.notes"))
        .required()
        .description(
          'Visibility of "Project->{pick a project}->[box] Noteringar" option'
        ),
      QA: Joi.boolean()
        .valid(Joi.ref("$mobile.projectMenu.questionsAnswer"))
        .required()
        .description(
          'Visibility of "Project->{pick a project}->Frågor och svar" option'
        ),
      reports: Joi.boolean()
        .required()
        .description(
          'Visibility of "Project->{pick a project}->Rapport" option'
        ),
      safety_round: Joi.boolean()
        .valid(Joi.ref("$mobile.projectMenu.safety_round"))
        .required()
        .description("Show safety round"),
      timeplan: Joi.boolean()
        .required()
        .description(
          'Visibility of "Project->{pick a project}->[box] Tidplan" option'
        ),
      attestMenu: Joi.object({
        project: Joi.boolean().description(
          'Visibility of "Attest per project" tab'
        ),
        service: Joi.boolean().description(
          'Visibility of "Attest per service" tab'
        ),
      }),
    },
    salary_integration: Joi.string().description(
      "If hogia then show employee number in PDFs"
    ),
    service: Joi.boolean()
      .valid(Joi.ref("$mobile.mainMenu.serviceorders"))
      .required()
      .description("Visibility of tab Service"),
    settings: Joi.object({
      subcompanies: Joi.boolean().description(
        'Visibility of tab "Subföretag" under "Inställningar"'
      ),
      absenceReporting: Joi.boolean().description(
        "Determine if absence reporting can be enabled from settings in " +
          "the customer instance. If it is true then they can setup absence reporting for workers and office" +
          " workers. If it is false then they will never know absence reporting exists in BK."
      ),
    }).description("Subcompanies settings"),
    time: Joi.object({
      ata: Joi.boolean()
        .required()
        .description(
          'Control filter checkbox for "Äta" at "Projekt-->Rapport->Filtrera"'
        ),
      driving: Joi.boolean()
        .required()
        .description(
          'Control filter checkbox for "Reseräkning" at "Projekt-->Rapport->Filtrera"'
        ),
      equipment: Joi.boolean()
        .required()
        .description(
          'Control filter checkbox for "Material" at "Projekt-->Rapport->Filtrera"'
        ),
      material: Joi.boolean()
        .required()
        .description(
          'Control filter checkbox for "Äta" at "Projekt-->Rapport->Filtrera"'
        ),
      time: Joi.boolean()
        .required()
        .description(
          'Control filter checkbox for "Timmar" at "Projekt-->Rapport->Filtrera"'
        ),
    })
      .required()
      .description("Time filter options"),
    webReporting: Joi.boolean()
      .valid(Joi.ref("$mobile.projectMenu.reporting"))
      .required()
      .description("Allow web reporting"),
  })
    .required()
    .description("Modules settings"),
  allowAnyoneToAddChecklist: Joi.boolean()
    .required()
    .description("Anyone can add checklist"),
  ataTypes: Joi.array().items(Joi.string()).description("Types of äta"),
  serviceTypes: Joi.array()
    .items(Joi.string())
    .description("Service price types"),
  companyName: Joi.string().invalid("").valid(Joi.ref("$backend.companyName")), //.required().description('Name of company')
  diary: Joi.object({
    allowEveningTemp: Joi.boolean()
      .valid(Joi.ref("$backend.supervisorReporting"))
      .required()
      .description("Allow for reporting secondary temperature"),
  })
    .required()
    .description("Diary settings"),
  equipment: Joi.object({
    allowEquipmentReporting: Joi.boolean()
      .required()
      .description("Allow for equipment reporting"),
    fields: Joi.array()
      .items(Joi.string())
      .required()
      .description('Governs header "Inställningar->Resurs->Hjälpmedel"'),
  })
    .required()
    .description("Equipment settings"),
  hazardName: Joi.string()
    .allow("")
    .valid("", "AJ! OJ!", "Tillbud", "{empty string}")
    .required()
    .description("Name of tillbud. Hide with empty string "),
  invoicing: Joi.object({
    fastpris: Joi.object({
      drivingTypes: Joi.array()
        .items(Joi.string())
        .description("Driving types"),
      timeTypes: Joi.array().items(Joi.string()).description("Time types"),
    }).description("Invoicing driving and time types for fixed project"),
    löpanderäkning: Joi.object({
      drivingTypes: Joi.array()
        .items(Joi.string())
        .description("Driving types"),
      timeTypes: Joi.array().items(Joi.string()).description("Time types"),
    }).description("Invoicing driving and time types for running project"),
    service: Joi.object({
      drivingTypes: Joi.array()
        .items(Joi.string())
        .description("Driving types"),
      timeTypes: Joi.array().items(Joi.string()).description("Time types"),
    }).description("Invoicing driving and time types for service"),
  }).description("Driving and timetypes for invoicing"),
  locationBasedReporting: Joi.boolean()
    .valid(Joi.ref("$backend.locationBasedReporting"))
    .description(
      "Location based reporting: Users checkin and checkout to create time reports. Some users are" +
        " required to be onsite, otherwise their reports will be flagged. Project and/or service location is " +
        "used to assess if the users' location is sufficiently close to the worksite. Absence reporting " +
        "is never location based and the only type of reporting allowed from the web."
    ),

  material: Joi.object()
    .required({
      allowEquipmentReporting: Joi.boolean()
        .required()
        .description("Allow for material reporting"),
      fields: Joi.array()
        .items(Joi.string())
        .required()
        .description('Governs header "Inställningar->Resurs->Material"'),
    })
    .description("Material settings"),
  advanceDocumentConfig: Joi.object({
    storageType: Joi.string().description("One Drive or Sharepoint"),
    storageUrl: Joi.string().description(
      "Url of business/personal One drive or Sharepoint"
    ),
    storageUseSharedFolder: Joi.string().description(
      "Use shared folder or personal folder" +
        "\n If user shared folder pls included shared user in path example sebastian_isolve_se/Documents..."
    ),
    storageSaveLocation: Joi.string().description(
      "Path to saved folder in One drive or Sharepoint"
    ),
    storageTemplateLocation: Joi.string().description(
      "Path to template folder in One drive or Sharepoint"
    ),
    storageSharedTemplateUrl: Joi.string().description(
      "Path to shared url of template location"
    ),
    storageSharedSaveLocationUrl: Joi.string().description(
      "Path to shared url of saved location"
    ),
  }),
  overtimeTimeTypes: Joi.array()
    .items(Joi.string())
    .description("Time types to use for attest overtime summary view"),
  projectTypes: Joi.array().items(Joi.string()).description("Project types"),
  remainingHoursTimeTypes: Joi.array()
    .items(Joi.string())
    .description(
      "Time types that are not included in ackord. Used to distribute money for ackord"
    ),
  reportingStartingDate: Joi.string().valid(
    Joi.ref("$mobile.startingSalaryDay")
  ),
  theme: Joi.object({
    primaryColor: Joi.string().valid(Joi.ref("$mobile.primaryColor")),
    secondaryColor: Joi.string().description("Secondary color"),
    datepickerColor: Joi.string().description("Title text color datepicker"),
    datepickerSndColor: Joi.string()
      .valid(Joi.ref("$mobile.primaryColor"))
      .description("Text months color datepicker"),
    navTextColor: Joi.string()
      .valid(Joi.ref("$mobile.primaryColor"))
      .description("Top nav text color"),
    settingsReportColor: Joi.string()
      .valid(Joi.ref("$web.theme.datepickerColor"))
      .description("Txt color in report button"),
  }).description("Theme settings"),
  time: Joi.object({
    allowDiaryReporting: Joi.boolean()
      .required()
      .description("Allow diary reporting"),
    allowExtraTime: Joi.boolean()
      .required()
      .description("Allow extra time reporting"),
    allowSeeingOwnTimeReporting: Joi.object({
      "non-supervisor": Joi.boolean()
        .required()
        .description("Allow user to see his own time reports"),
    })
      .required()
      .description("Time reporting setting visibility user"),
    allowTimeReporting: Joi.object({
      "non-supervisor": Joi.boolean()
        .required()
        .description("Allow user to report time"),
      supervisor: Joi.boolean()
        .valid(true)
        .required()
        .description("Allow supervisor to report time"),
    })
      .required()
      .description("Time reporting setting"),
    ataForAll: Joi.boolean()
      .valid(Joi.ref("$mobile.ataForAll"))
      .required()
      .description("Allow all users to see and report äta"),
    minHourValue: Joi.number().required().description("N.d."),
    minTimeInterval: Joi.number().description("Min time interval"),
    multiusertimereporting: Joi.object({
      supervisor: Joi.ref("$backend.supervisorReporting"),
    })
      .required()
      .description(
        "Settings for allowing superuser to report other users time"
      ),
    timeTypes: {
      ackord: Joi.object({
        "non-supervisor": Joi.array()
          .items(Joi.string())
          .min(1)
          .description("User time types"),
        supervisor: Joi.array()
          .items(Joi.string())
          .min(1)
          .description("Supervisor time types"),
      }).description("Time types user and supervisor for fixed project"),
      timlön: Joi.object({
        "non-supervisor": Joi.array()
          .min(1)
          .items(Joi.string())
          .description("User time types"),
        supervisor: Joi.array()
          .min(1)
          .items(Joi.string())
          .description("Supervisor time types"),
      }).description("Time types user and supervisor for running project"),
    },
    subSupplierTimeTypes: Joi.array()
      .items(Joi.string())
      .description("Time types for sub supplier"),
  })
    .required()
    .description("Time settings"),
  timeTypes: Joi.object({
    project: Joi.object({
      Fastpris: {
        "non-supervisor": Joi.string().description("Not used"),
        supervisor: Joi.string().description("Not used"),
      },
      other: {
        "non-supervisor": Joi.string().description("Not used"),
        supervisor: Joi.string().description(
          "Used in /app/reporting/reporting.ts. Löpande (not fastpris) projekt and persons who reporting is supervisor or admin this list is added"
        ),
      },
    }).description("Only other.supervisor is used in code"),
    types: Joi.array()
      .items(Joi.string())
      .description(
        "This is the default list of time type but does not seem like it has any impact on UI list shown in reporting time"
      ),
  }).description("Time type settings"),
  travel: Joi.object({
    allowTraktamente: Joi.ref("$mobile.projectMenu.traktamenteOne"),
    allowTravel: Joi.boolean().required().description("Allow travel reporting"),
    autoCountPassengerDistance: Joi.boolean()
      .required()
      .description("Auto count passenger distance"),
    fromToLocation: Joi.string().required().description("Heading in a report"),
    project: Joi.object({
      drivingTypes: Joi.array()
        .items(Joi.string())
        .description("Driving types project"),
    }).description("Driving types project settings"),
    service: Joi.object({
      drivingTypes: Joi.array()
        .items(Joi.string())
        .description("Driving types service"),
    }).description("Driving types service settings"),
    traktamenteOne: Joi.ref("$mobile.projectMenu.traktamenteOne"),
    traktamenteTwo: Joi.ref("$mobile.projectMenu.traktamenteTwo"),
  })
    .required()
    .description("Travel settings"),
};

module.exports = {
  backend,
  mobile,
  web,
  config: Joi.object({
    backend: Joi.object(backend).description("Backend config"),
    mobile: Joi.object(mobile).description("Mobile config"),
    web: Joi.object(web).description("Web config"),
  }).label("config"),
};
module.exports = {
  backend,
  mobile,
  web,
  config: Joi.object({
    backend: Joi.object(backend).description("Backend config"),
    "backend.salarySystem": salarySystem,
    instruction,
    mobile: Joi.object(mobile).description("Mobile config"),
    web: Joi.object(web).description("Web config"),
  }).label("config"),
};
