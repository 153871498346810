/**
 *Created by Mikael Lindahl on 2020-06-01
 */

"use strict";

module.exports = {

    validate_schema_note: [
        {
            keys: [
                "mobile.timeTypes.projectFixed",
                "mobile.timeTypes.projectFixedLagbas",
                "mobile.timeTypes.projectRunning",
                "mobile.timeTypes.projectRunningLagbas",
                "mobile.timeTypes.serviceorder",
                "web.time.timeTypes.ackord.non-supervisor",
                "web.time.timeTypes.ackord.supervisor",
                "web.time.timeTypes.timlön.non-supervisor",
                "web.time.timeTypes.timlön.supervisor"],
            note: '. NOTE!!! This will lead to a crash in specific views'
        }
    ]
}