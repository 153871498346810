/**
 *Created by Mikael Lindahl on 2020-05-18
 */
"use strict";
var mapSetups = {
    time_type_absence: [
        {
            label: '',
            primary: { key: 'blank' },
        },
        {
            label: 'Absence',
            primary: {
                key: 'backend.absenceTimeTypes',
                name_type: 'time_type',
                language: 'sv',
            },
            maps_to: [
                {
                    key: 'web.absenceTimeTypes',
                    name_type: 'time_type',
                    language: 'sv'
                },
                {
                    key: 'mobile.absenceTimeTypes',
                    name_type: 'time_type',
                    language: 'key'
                }
            ]
        }
    ],
    time_type_sub_supplier: [
        {
            label: '',
            primary: { key: 'blank' },
        },
        {
            label: 'Absence',
            primary: {
                key: 'web.time.subSupplierTimeTypes',
                name_type: 'time_type',
                language: 'sv',
            },
            maps_to: [
                {
                    key: 'mobile.remoteTimeTypes.subSupplier',
                    name_type: 'time_type',
                    language: 'key'
                },
                {
                    key: 'mobile.timeTypes.subSupplier',
                    name_type: 'time_type',
                    language: 'key'
                }
            ]
        }
    ],
    time_type_work: [
        {
            label: '',
            primary: { key: 'blank' },
        },
        {
            label: 'Project running user',
            primary: {
                key: 'web.time.timeTypes.timlön.non-supervisor',
                name_type: 'time_type',
                language: 'sv',
            },
            maps_to: [
                {
                    key: 'mobile.remoteTimeTypes.projectRunning',
                    name_type: 'time_type',
                    language: 'key'
                }
            ]
        },
        {
            label: 'Project running supervisor',
            primary: {
                key: 'web.time.timeTypes.timlön.supervisor',
                name_type: 'time_type',
                language: 'sv',
            },
            maps_to: [
                {
                    key: 'mobile.remoteTimeTypes.projectRunningLagbas',
                    name_type: 'time_type',
                    language: 'key'
                }
            ]
        },
        {
            label: 'Project fixed user',
            primary: {
                key: 'web.time.timeTypes.ackord.non-supervisor',
                name_type: 'time_type',
                language: 'sv',
            },
            maps_to: [
                {
                    key: 'mobile.remoteTimeTypes.projectFixed',
                    name_type: 'time_type',
                    language: 'key'
                }
            ]
        },
        {
            label: 'Project fixed supervisor',
            primary: {
                key: 'web.time.timeTypes.ackord.supervisor',
                name_type: 'time_type',
                language: 'sv',
            },
            maps_to: [
                {
                    key: 'mobile.remoteTimeTypes.projectFixedLagbas',
                    name_type: 'time_type',
                    language: 'key'
                }
            ]
        },
        {
            label: 'Remaining hours',
            primary: {
                key: 'backend.decidedHoursNegative',
                name_type: 'time_type',
                language: 'sv',
            },
            maps_to: [
                {
                    key: 'web.remainingHoursTimeTypes',
                    name_type: 'time_type',
                    language: 'sv',
                },
            ]
        },
        {
            label: 'Service',
            primary: {
                key: 'mobile.remoteTimeTypes.serviceorder',
                name_type: 'time_type',
                language: 'key',
            },
            maps_to: [] // web.time.timeTypes.timlön.supervisor  is used for this on web
        },
        {
            label: 'Invoice',
            primary: {
                key: 'web.invoicing.fastpris.timeTypes',
                name_type: 'time_type',
                language: 'sv',
            },
            maps_to: [
                {
                    key: 'web.invoicing.löpanderäkning.timeTypes',
                    name_type: 'time_type',
                    language: 'sv',
                },
                {
                    key: 'web.invoicing.service.timeTypes',
                    name_type: 'time_type',
                    language: 'sv',
                }
            ]
        },
        {
            label: 'Overtime',
            primary: {
                key: 'web.overtimeTimeTypes',
                name_type: 'time_type',
                language: 'sv',
            },
            maps_to: [] // web.time.timeTypes.timlön.supervisor  is used for this on web
        },
    ],
    travel_type: [
        {
            primary: { key: 'blank' },
            label: '',
        },
        {
            label: 'Project',
            primary: {
                key: 'web.travel.project.drivingTypes',
                name_type: 'travel_type',
                language: 'sv',
            },
            maps_to: [
                // {
                //     key: 'mobile.drivingTypes.project',
                //     name_type: 'travel_type',
                //     language: 'key'
                // },
                {
                    key: 'mobile.remoteDrivingTypes.project',
                    name_type: 'travel_type',
                    language: 'key'
                }
            ]
        },
        {
            label: 'Service',
            primary: {
                key: 'web.travel.service.drivingTypes',
                name_type: 'travel_type',
                language: 'sv',
            },
            maps_to: [
                // {
                //     key: 'mobile.drivingTypes.serviceorder',
                //     name_type: 'travel_type',
                //     language: 'key'
                // },
                {
                    key: 'mobile.remoteDrivingTypes.serviceorder',
                    name_type: 'travel_type',
                    language: 'key'
                },
            ]
        },
        {
            label: 'Invoice',
            primary: {
                key: 'web.invoicing.fastpris.drivingTypes',
                name_type: 'travel_type',
                language: 'sv'
            },
            maps_to: [
                {
                    key: 'web.invoicing.löpanderäkning.drivingTypes',
                    name_type: 'travel_type',
                    language: 'sv',
                },
                {
                    key: 'web.invoicing.service.drivingTypes',
                    name_type: 'travel_type',
                    language: 'sv',
                },
            ]
        }
    ]
};
export default mapSetups;
