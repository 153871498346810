/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import InputSelectSimpleView from "./InputSelectSimpleView";
import InputSelectReactSimpleView from "./InputSelectReactSimpleView";
import InputTextSimpleView from "./InputTextSimpleView";
import './InputColor.scss';
import "./InputProjectId.scss";
import Util from "../../lib/util";
var debug = require('debug')('bk-manager:frontend-react:components:InputProjectId');
/**
 *
 * Render input project id for salary instruction
 *
 * @param props {object} Input project id properties
 * @returns {*}
 * @constructor
 */
export default function InputProjectId(props) {
    var value = props.value || { rule: '', set_project_to: '', project_types: [] };
    var _a = useState(value.rule), rule = _a[0], setRule = _a[1];
    var _b = useState(value.set_project_to), set_project_to = _b[0], setSetProjectTo = _b[1];
    var _c = useState(value.project_types || []), project_types = _c[0], setProjectTypes = _c[1];
    var _d = useState(false), edit = _d[0], setEdit = _d[1];
    var _e = useState(''), error = _e[0], setError = _e[1];
    return (React.createElement("div", { className: 'd-flex flex-column input-project-id' },
        React.createElement("div", { className: 'mb-3' },
            React.createElement("label", null, "Replace project id"),
            React.createElement("small", { className: "form-text text-muted" }, props.help.replace_project_id)),
        React.createElement("div", { className: 'd-flex flex-column' },
            React.createElement(InputSelectSimpleView, { options: props.options.rule, disabled: props.disabled, label: React.createElement("em", null, 'Rule'), onChange: function (_a) {
                    var value = _a.value;
                    setRule(value);
                    setEdit(true);
                }, value: rule, help: props.help.rule }),
            React.createElement("div", null,
                React.createElement(InputTextSimpleView, { disabled: props.disabled, label: React.createElement("em", null, 'Set project to'), placeholder: 'Set project to ...', onChange: function (_a) {
                        var value = _a.value;
                        setSetProjectTo(value);
                        setEdit(true);
                    }, value: set_project_to, help: props.help.set_project_to })),
            rule == 'for_project_types'
                && React.createElement(InputSelectReactSimpleView, { options: props.options.project_types, disabled: props.disabled, multi: true, label: React.createElement("em", null, 'Project type'), onChange: function (_a) {
                        var value = _a.value;
                        setProjectTypes(value);
                        setEdit(true);
                    }, selected: project_types.map(function (s) { return Util.toOptions(s); }), help: props.help.project_types })),
        edit && React.createElement(Button, { className: 'mb-3', onClick: function () {
                if (rule != '' && set_project_to == '') {
                    setError('Set project to can not be empty');
                    return;
                }
                if (rule == 'for_project_types' && !project_types.length) {
                    setError('Need to set at least one project type');
                    return;
                }
                setError('');
                props.onChange({
                    value: {
                        rule: rule,
                        project_types: project_types,
                        set_project_to: set_project_to
                    }
                });
                setEdit(false);
            } }, "Done"),
        error && React.createElement("div", { className: 'alert alert-danger mb-3' }, error)));
}
