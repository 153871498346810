/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import './InputSalary.scss';
import { Button, Modal } from "react-bootstrap";
import Utils from "../../lib/util";
import useModal from "../../hooks/useModal";
import ModalInputSalaryCompany from "./ModalInputSalaryCompany";
import InputSelectSimpleView from "./InputSelectSimpleView";
import salary from "../../constants/salary";
var debug = require('debug')('bk-manager:frontend-react:components:InputSalary');
/**
 *
 * Render input for salary configuration
 *
 * @param props {object} Input salary properties
 * @returns {*}
 * @constructor
 */
export default function InputSalary(props) {
    var company_init = salary.COMPANY_INIT;
    var _a = useState(props.companies[0] || company_init), company = _a[0], setCompany = _a[1];
    var _b = useState(''), company_name_delete = _b[0], setCompanyNameDelete = _b[1];
    var modal_add = useModal();
    var modal_delete = useModal();
    var modal_edit = useModal();
    var company_names = props.companies.map(function (c) { return c.info.company_name; });
    var company_name_options = __spreadArray([
        { value: '', label: '' }
    ], company_names.map(function (n) {
        return {
            value: n,
            label: n
        };
    }), true);
    var subcompanies_names = props.subcompanies.map(function (c) { return c.name; });
    var other_customer_ids = props.companies.reduce(function (tot, c) {
        if (c.id != company.id) {
            tot.push(c.id);
        }
        return tot;
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'd-flex flex-column input-salary' },
            props.label && React.createElement("label", null, props.label),
            props.help && React.createElement("small", { className: "form-text text-muted mb-2" }, props.help),
            React.createElement(InputSelectSimpleView, { options: company_name_options, disabled: props.disabled, label: '3.4.1 Default company', onChange: props.onChangeDefaultCompany, value: props.default_company, help: props.default_company_help }),
            React.createElement("label", null, '3.4.2 Salary systems'),
            React.createElement("small", { className: "form-text text-muted mb-2" }, "Available subcompanies: ".concat(subcompanies_names.join(', '), ". NOTE!!! When using \n                    subcompanies then the subcompany names must match exactly the names of sub companies in \n                    web admin. If not the salary export will fail!")),
            React.createElement("div", { className: 'border-bottom border-light' },
                React.createElement("div", { className: 'd-flex flex-row border-bottom border-light' }, !props.disabled && React.createElement(React.Fragment, null,
                    React.createElement(Button, { className: 'mr-2', onClick: modal_add.onModalOpen }, "Add company"),
                    React.createElement(Button, { className: 'btn btn-danger ml-auto', onClick: modal_delete.onModalOpen }, "Delete company"))),
                props.companies.map(function (c) {
                    return React.createElement("div", { key: c.id, title: !props.disabled ? 'Edit' : 'View', className: 'd-flex flex-row salary-company-row p-2', onClick: function () {
                            setCompany(c);
                            modal_edit.onModalOpen();
                        } },
                        c.info.company_name,
                        c.info.company_name == props.default_company &&
                            React.createElement("em", { className: 'text-success ml-2' }, 'default company'),
                        subcompanies_names.includes(c.info.company_name) &&
                            React.createElement("em", { className: 'text-primary ml-2' }, 'sub-company'));
                }))),
        React.createElement(ModalInputSalaryCompany, __assign({}, { company_names: company_names }, { other_customer_ids: other_customer_ids, issues: props.issues, mode: 'add', modal: modal_add, data_type_options: props.data_type_options, docs: props.docs, title: 'Add company', onChange: function (_a) {
                var value = _a.value;
                var companies = Utils.clone(props.companies);
                // Ensure id
                value.id = value.info.company_name;
                companies.push(value);
                props.onChange({ value: companies });
            } })),
        React.createElement(Modal, { show: modal_delete.modal_open, onHide: modal_delete.onModalClose },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Delete company")),
            React.createElement(Modal.Body, null,
                React.createElement("label", null, "Enter company name for salary system to delete"),
                React.createElement("input", { className: 'p-1', type: 'text', value: company_name_delete, onChange: function (_a) {
                        var value = _a.target.value;
                        debug('set company_name_delete', value);
                        setCompanyNameDelete(value);
                    }, placeholder: 'Enter company name ...' })),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: modal_delete.onModalClose }, "Close"),
                React.createElement(Button, { onClick: function () {
                        var default_company = props.default_company == company_name_delete ? '' : props.default_company;
                        var companies = props.companies.reduce(function (tot, c) {
                            if (c.info.company_name != company_name_delete) {
                                tot.push(c);
                            }
                            return tot;
                        }, []);
                        props.onChangeDelete({
                            value: { companies: companies, default_company: default_company }
                        });
                        setCompanyNameDelete('');
                        modal_delete.onModalClose();
                    }, disabled: !company_names.includes(company_name_delete) }, "Delete"))),
        React.createElement(ModalInputSalaryCompany, __assign({}, { company: company }, { other_customer_ids: other_customer_ids, issues: props.issues, data_type_options: props.data_type_options, modal: modal_edit, mode: 'edit', docs: props.docs, title: props.disabled
                ? "View salary instructions for company ".concat(React.createElement("div", { className: 'd-inline company-name' }, "'company.info.company_name"))
                : React.createElement(React.Fragment, null, "Edit salary instructions for company",
                    " ",
                    React.createElement("div", { className: 'd-inline company-name' }, company.info.company_name)), onChange: function (_a) {
                var value = _a.value;
                var companies = Utils.clone(props.companies);
                companies = companies.reduce(function (tot, val) {
                    if (val.id == value.id) {
                        // Ensure correct id if company name has changed
                        value.id = value.info.company_name;
                        tot.push(value);
                    }
                    else {
                        tot.push(val);
                    }
                    return tot;
                }, []);
                props.onChange({ value: companies });
            } }))));
}
