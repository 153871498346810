/**
 *Created by Mikael Lindahl on 2019-10-29
 */
"use strict";
import React, { useEffect, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import './InputTextarea.scss';
var debug = require('debug')('bk-manager:frontend-react:components:InputTextarea');
/**
 *
 * Input textarea
 *
 * @param props {object} Input textarea properties
 * @returns {*}
 * @constructor
 */
export default function InputTextarea(props) {
    var _a = useState(props.value), value = _a[0], setValue = _a[1];
    useEffect(function () {
        setValue(props.value);
    }, [props.value]);
    return (React.createElement(TextareaAutosize, { className: (props.className || '') + ' input-textarea', onChange: function (event) {
            setValue(event.target.value);
            if (props.onChange) {
                props.onChange({ value: event.target.value });
            }
        }, disabled: props.disabled, onBlur: function (e) {
            // e.preventDefault();
            props.onBlur({ value: value });
        }, style: props.style, value: value, placeholder: props.placeholder }));
}
