var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import InputTextarea from "../inputs/InputTextarea";
import util from "../../lib/util";
import translationConfigTables from "../../constants/translationConfigTables";
var debug = require('debug')('bk-manager:frontend-react:components:TableTranslationCells');
var CELL_SIZES = {
    locales: 1300,
    eligible_for: 150,
    trash: 20
};
/**
 * Render a cell in translation table
 *
 * @param cell {object} React table cell
 * @param translation_edit {object} Edits to translation
 * @param updateTranslationEdit {function} Callback for updating translation edit
 * @param locale {string} Translation locale
 * @returns {*}
 * @constructor
 */
export function CellLocale(_a) {
    var cell = _a.cell, locale = _a.locale, translation = _a.translation, translation_edit = _a.translation_edit, updateTranslationEdit = _a.updateTranslationEdit;
    var data = __assign({}, cell.row.original[locale]);
    if (!data) {
        console.log('Cell', cell, locale);
        console.log('translation_edit', translation_edit);
        console.log('translation', translation);
    }
    var n_keys = Object.keys(cell.row.original).length;
    var key = data.key;
    var _b = useState(data), cellState = _b[0], setCellState = _b[1];
    return React.createElement("td", { className: cellState.className }, locale == 'key'
        ? React.createElement("div", { style: { wordWrap: 'break-word', width: "".concat(CELL_SIZES.locales / (n_keys - 1), "px") } },
            React.createElement("strong", null, cellState.text))
        : React.createElement("div", { className: '' },
            React.createElement("div", null,
                React.createElement(InputTextarea, { style: { width: "".concat(CELL_SIZES.locales / (n_keys - 1), "px") }, className: 'input-textarea-translation', disabled: cellState.action == 'deleted', onBlur: function (_a) {
                        var _b, _c;
                        var value = _a.value;
                        var te = util.getTranslation({
                            key: key,
                            locale: locale,
                            translation: translation_edit
                        });
                        // No update if value did not change
                        if (te && te.text == value) {
                            return;
                        }
                        var entry = util.generateTranslationEdit({
                            action: te && te.action == 'created' ? 'created' : (value == cellState.text_original
                                ? 'default'
                                : 'edited'),
                            is_created: te && te.is_created,
                            text: value,
                            text_original: cellState.text_original
                        });
                        updateTranslationEdit({ update: (_b = {}, _b[key] = (_c = {}, _c[locale] = entry, _c), _b) });
                        setCellState(entry);
                    }, value: cellState.text })),
            cellState.text_original != cellState.text
                && cellState.action != 'created'
                && cellState.action != 'deleted'
                && React.createElement("div", { className: 'ml-auto' },
                    React.createElement("i", { className: 'fas fa-undo', onClick: function () {
                            var _a, _b;
                            var te = util.getTranslation({
                                key: key,
                                locale: locale,
                                translation: translation_edit
                            });
                            var entry = util.generateTranslationEdit({
                                action: te && te.action != 'edited' ? te.action : 'default',
                                is_created: te && te.is_created,
                                text: cellState.text_original,
                                text_original: cellState.text_original
                            });
                            if (!util.isEmptyObject(entry)) {
                                setCellState(entry);
                                updateTranslationEdit({ update: (_a = {}, _a[key] = (_b = {}, _b[locale] = entry, _b), _a) });
                            }
                        } }))));
}
/**
 * Render a React table cell in translation table
 *
 * @param cell {object} React table cell
 * @param translation_edit {object} Edits to translation
 * @param updateTranslationEdit {function} Callback for updating translation edit
 * @param locale {string} Translation locale
 * @returns {*}
 * @constructor
 */
export function CellEligibleFor(_a) {
    var cell = _a.cell, translation_edit = _a.translation_edit, updateTranslationEdit = _a.updateTranslationEdit;
    var locale = 'eligible_for';
    var data = __assign({}, cell.row.original[locale]);
    var key = data.key;
    var _b = useState(data), cellState = _b[0], setCellState = _b[1];
    return React.createElement("td", { className: cellState.className },
        React.createElement("select", { className: "input-select-eligible-for custom-select", value: cellState.text, style: { width: CELL_SIZES.eligible_for }, onChange: function (event) {
                var _a, _b;
                var value = event.target.value;
                var te = util.getTranslation({
                    key: key,
                    locale: locale,
                    translation: translation_edit
                });
                // No update if value did not change
                if (te && te.text == value) {
                    return;
                }
                var entry = util.generateTranslationEdit({
                    action: te && te.action == 'created' ? 'created' : (value == cellState.text_original
                        ? 'default'
                        : 'edited'),
                    is_created: false,
                    text: value,
                    text_original: cellState.text_original
                });
                updateTranslationEdit({ update: (_a = {}, _a[key] = (_b = {}, _b[locale] = entry, _b), _a) });
                setCellState(entry);
            } }, translationConfigTables.options.map(function (o) {
            return React.createElement("option", { key: o.value, value: o.value }, o.label);
        })));
}
/**
 * Render row cell for deletion
 *
 * @param cell {object} React table cell
 * @param translation_edit {object} Edits to translation
 * @param updateTranslationEdit {function} Callback for updating translation edit
 * @returns {*}
 * @constructor
 */
export function CellTrash(_a) {
    var cell = _a.cell, translation_edit = _a.translation_edit, updateTranslationEdit = _a.updateTranslationEdit;
    var data = __assign({}, cell.row.original.key);
    var key = data.key;
    var original = cell.row.original;
    var te = util.getTranslation({ key: key, locale: 'key', translation: translation_edit });
    var count = 0;
    if (te) {
        for (var locale in translation_edit[key]) {
            if (translation_edit[key][locale].action == 'deleted')
                count++;
        }
    }
    var is_deleted = util.dicToList(translation_edit[key]).length <= count && count > 0;
    var icon = is_deleted ? 'fa-undo' : 'fa-trash';
    return React.createElement("td", null,
        React.createElement("i", { className: "fas ".concat(icon), style: { width: CELL_SIZES.trash }, onClick: function () {
                var _a;
                var update = (_a = {}, _a[key] = {}, _a);
                // debug('click')
                for (var locale in original) {
                    var te_1 = util.getTranslation({
                        key: key,
                        locale: locale,
                        translation: translation_edit
                    });
                    var action = te_1 && te_1.action == 'deleted' && !te_1.is_deleted_column
                        ? te_1.is_created
                            ? 'created'
                            : (te_1.text != te_1.text_original
                                ? 'edited'
                                : 'default')
                        : 'deleted';
                    var entry = util.generateTranslationEdit({
                        action: action,
                        is_deleted_row: !is_deleted,
                        is_deleted_column: te_1 && te_1.is_deleted_column,
                        is_created: te_1 && te_1.is_created,
                        text_original: original[locale].text_original,
                        text: te_1 ? te_1.text : original[locale].text,
                    });
                    // debug(entry, key, locale)
                    update[key][locale] = entry;
                }
                // debug(update)
                updateTranslationEdit({ update: update, reset: true });
            } }));
}
