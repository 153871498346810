import React from "react";
import './ExportUserDataView.scss';
// import InputAbsenceTimeTypes from "../inputs/InputAbscenceTimeTypes";
import ExportUserEmail from "../exports/ExportUserEmail";
var debug = require('debug')('bk-manager:frontend-react:components:ExportUserData');
/**
 * Render customer edit global config view
 *
 * @returns {*}
 * @constructor
 */
export default function ExportUserData(props) {
    debug('ExportUserData');
    return React.createElement("div", { id: 'event' },
        React.createElement("div", { className: "header" },
            React.createElement("div", { className: "header-body" },
                React.createElement("div", { className: "row align-items-end" },
                    React.createElement("div", { className: "col" },
                        React.createElement("h6", { className: "header-pretitle" }, "Export"),
                        React.createElement("h1", { className: "header-title" }, "User data"))))),
        React.createElement("div", { className: 'export-user-data pb-5 container' },
            React.createElement("label", null, "1. Emails"),
            React.createElement(ExportUserEmail, { user_emails_instances: props.user_emails_instances })));
}
