/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
import React from "react";
var debug = require('debug')('bk-manager:frontend-react:components:InputSelect');
/**
 * Render select input for simple view with label and help text
 *
 * @param props {object} Input select simple view properties
 * @returns {*}
 * @constructor
 */
export default function InputSelectSimpleView(props) {
    return (React.createElement("div", { className: "form-group" },
        props.label && React.createElement("label", null, props.label),
        props.help && React.createElement("small", { className: "form-text text-muted mb-2" }, props.help),
        React.createElement("div", null,
            React.createElement("select", { className: "input-select custom-select", disabled: props.disabled, value: props.value, onChange: function (event) {
                    debug('event.target.value', event.target.value);
                    props.onChange({
                        value: event.target.value,
                    });
                } }, props.options.map(function (o) {
                return React.createElement("option", { key: o.value, value: o.value }, o.label);
            }))),
        props.required && React.createElement("small", { className: "form-text text-danger mb-2 ml-2" }, 'required')));
}
