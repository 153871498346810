/**
 *Created by Mikael Lindahl on 2022-05-16
 */
import React, { useState } from 'react';
import InputTextList from "./InputTextList";
import url from "../../constants/url.jsx";
/**
 * Input element for absence time types
 *
 * @param props {object} Input absence time types properties
 * @returns {JSX.Element}
 * @constructor
 */
export default function InputAbsenceTimeTypes(props) {
    var _a = useState(props.absenceTimeTypes), absenceTimeTypes = _a[0], setAbsenceTimeTypes = _a[1];
    return React.createElement(InputTextList, { exclude_file_upload: true, disabled: props.disabled, label: props.label, text_list: absenceTimeTypes, onChange: function (_a) {
            var text_list = _a.text_list;
            var payload = {
                absence_time_types: text_list
            };
            fetch(url.GLOBAL_CONFIG_ABSENCE_TIME_TYPES, {
                method: 'PUT',
                body: JSON.stringify(payload)
            }).catch(function (e) {
                console.log(e);
                alert('Something when wrong saving to db...');
            });
            setAbsenceTimeTypes(text_list);
        }, placeholder: 'Add time type', help: 'Add or remove time types that are counted as absence. The time type has to be a time type, ' +
            'exist in translation table and be the swedish translation in order for it to show up on 1.3 in simple view' });
}
