import React from "react";
import { swagger } from "config-validation";
import SwaggerUI from "swagger-ui-react";
import "./CustomerDocumentationView.scss";
var debug = require('debug')('bk-manager:frontend-react:components:CustomerDocumentationView');
/**
 * Swagger documentation view for config
 *
 * @param props {object} Customer documentation view properties
 * @returns {*}
 * @constructor
 */
export default function CustomerDocumentationView() {
    debug('CustomerDocumentationView');
    return (React.createElement("div", { id: 'documentation' },
        React.createElement(SwaggerUI, { spec: swagger() })));
}
