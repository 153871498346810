/**
 *Created by Mikael Lindahl on 2019-10-29
 */
"use strict";
import React, { useState, useEffect } from "react";
/**
 * Input checkbox for table
 *
 * @param props {object} Input checkbox properties
 * @returns {*}
 * @constructor
 */
export default function InputCheckbox(props) {
    var _a = useState(props.value), value = _a[0], setValue = _a[1];
    useEffect(function () {
        setValue(props.value);
    }, [props.value]);
    return (React.createElement("input", { type: 'checkbox', onChange: function (_) {
            setValue(!value);
            props.onChange({ value: !value });
        }, disabled: props.disabled, checked: value }));
}
