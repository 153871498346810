import React from "react";
import './ConfigGlobalView.scss';
import TableTranslation from "../tables/TableTranslation";
import InputAbsenceTimeTypes from "../inputs/InputAbscenceTimeTypes";
import InputUnit from "../inputs/InputUnit";
var debug = require('debug')('bk-manager:frontend-react:components:ConfigGlobalView');
/**
 * Render customer edit global config view
 *
 * @returns {*}
 * @constructor
 */
export default function ConfigGlobalView(props) {
    debug('ConfigGlobalView');
    return React.createElement("div", { id: 'event' },
        React.createElement("div", { className: "header" },
            React.createElement("div", { className: "header-body" },
                React.createElement("div", { className: "row align-items-end" },
                    React.createElement("div", { className: "col" },
                        React.createElement("h6", { className: "header-pretitle" }, "Edit"),
                        React.createElement("h1", { className: "header-title" }, "Global config"))))),
        React.createElement("div", { className: 'global-config pb-5' },
            React.createElement("label", null, "1. Translation table"),
            React.createElement(TableTranslation, { translation: props.translation_config, locales_available_for_web: props.locales_available_for_web, translation_edit: props.translation_edit || {} }),
            React.createElement(InputAbsenceTimeTypes, { absenceTimeTypes: props.absence_time_types, label: '2. Absence time types' }),
            React.createElement(InputUnit, { units: props.units, label: '3. Units' })));
}
