/**
 *Created by Mikael Lindahl on 2022-05-24
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import InputTextSimpleView from "./InputTextSimpleView";
import util from "../../lib/util";
import InputCheckboxSimpleView from "./InputCheckboxSimpleView";
import InputSelectSimpleView from "./InputSelectSimpleView";
import "./InputPaymentInstructionDuplicateRules.scss";
var debug = require('debug')('bk-manager:frontend-react:components:inputs:InputPaymentInstructionDuplicateRules');
/**
 * Component to create a rule row for payment instruction duplicate rules
 *
 * @param props {object} Rule row props
 * @returns {JSX.Element}
 * @constructor
 */
function RuleRow(props) {
    return React.createElement("div", { className: 'rule-row' },
        React.createElement(InputTextSimpleView, { disabled: props.disabled, help: 'Set pay type id', placeholder: 'Set pay type id to ...', onChange: function (_a) {
                var value = _a.value;
                props.onChange(props.index, { payTypeId: value });
            }, value: props.rule.payTypeId }),
        React.createElement(InputSelectSimpleView, { disabled: props.disabled, help: 'Set quantity from for this rule', onChange: function (_a) {
                var value = _a.value;
                props.onChange(props.index, { quantity_from: value });
            }, value: props.rule.quantity_from, options: props.quantity_from_options }),
        React.createElement(InputCheckboxSimpleView, { disabled: props.disabled, help: 'Set if salary addon user group should be use as filter', value: props.rule.only_for_salary_add_on_user_group, onChange: function (_a) {
                var value = _a.value;
                props.onChange(props.index, { only_for_salary_add_on_user_group: value });
            } }),
        React.createElement("div", null,
            React.createElement("button", { className: 'btn btn-danger my-2', onClick: function () { return props.onDelete(props.index); } }, 'Delete rule')));
}
/**
 * Component for generating payment instruction input for duplicate row rules
 * for salary instruction
 *
 * @param props {object} Input Payment instruction duplication rules properties
 * @returns {JSX.Element}
 * @constructor
 */
export default function InputPaymentInstructionDuplicateRules(props) {
    var _a = useState(props.rules), rules = _a[0], setRules = _a[1];
    var _b = useState(false), edit = _b[0], setEdit = _b[1];
    return React.createElement("div", { className: 'd-flex flex-column input-project-id' },
        React.createElement("div", { className: 'mb-3' },
            React.createElement("label", null, "Payment instruction duplicate rules"),
            React.createElement("small", { className: "form-text text-muted" }, props.help),
            rules.map(function (r, index) { return React.createElement(RuleRow, { key: index, rule: r, quantity_from_options: props.quantity_from_options, index: index, onChange: function (row_index, data) {
                    setEdit(true);
                    var clone = util.clone(rules);
                    clone[row_index] = __assign(__assign({}, clone[row_index]), data);
                    setRules(clone);
                }, onDelete: function (row_index) {
                    var filtered = rules.filter(function (_, i) { return i != row_index; });
                    setRules(filtered);
                } }); }),
            React.createElement("div", { className: 'mt-4' },
                edit && React.createElement("button", { className: 'btn btn-primary mr-3', onClick: function () {
                        debug('rules', rules);
                        props.onChange({ value: rules });
                        setEdit(false);
                    } }, 'Save rules'),
                React.createElement("button", { className: 'btn btn-secondary', onClick: function () {
                        var add = __spreadArray(__spreadArray([], rules, true), [{
                                payTypeId: '',
                                quantity_from: 'hours',
                                only_for_salary_add_on_user_group: false
                            }], false);
                        setEdit(true);
                        setRules(add);
                    } }, "Add rule"))));
}
