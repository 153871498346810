import React from "react";
import Locales from "../../constants/locales";
var debug = require('debug')('bk-manager:frontend-react:components:ConfigGlobalTranslationValidLocalesView');
/**
 * Render customer global translation view for valid locales
 *
 * @returns {*}
 * @constructor
 */
export default function ConfigGlobalTranslationValidLocalesView() {
    return React.createElement("div", { id: 'global-view-file-types' },
        React.createElement("div", { className: "header" },
            React.createElement("div", { className: "header-body" },
                React.createElement("div", { className: "row align-items-end" },
                    React.createElement("div", { className: "col" },
                        React.createElement("h6", { className: "header-pretitle" }, "Help"),
                        React.createElement("h1", { className: "header-title" }, "Global config valid locales"))))),
        React.createElement("div", { className: 'global-config container' },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("td", null,
                            React.createElement("strong", null, "Locale")),
                        React.createElement("td", null,
                            React.createElement("strong", null, "Native name")),
                        React.createElement("td", null,
                            React.createElement("strong", null, "English name")))),
                React.createElement("tbody", null, (function () {
                    var body = [];
                    var _loop_1 = function (_locale) {
                        body.push(React.createElement("tr", null, (function () {
                            var locale = _locale;
                            var row = [React.createElement("td", null, locale)];
                            for (var _i = 0, _a = ['nativeName', 'englishName']; _i < _a.length; _i++) {
                                var key = _a[_i];
                                row.push(React.createElement("td", null, Locales[locale][key]));
                            }
                            return row;
                        })()));
                    };
                    for (var _locale in Locales) {
                        _loop_1(_locale);
                    }
                    return body;
                })()))));
}
