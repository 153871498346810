/**
 *Created by Mikael Lindahl on 2020-06-09
 */
"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import Table from "./Table";
import util from "../../lib/util";
import url from "../../constants/url.jsx";
import './TableTranslation.scss';
import { Button, Modal } from "react-bootstrap";
import InputText from "../inputs/InputText";
import ButtonWithTooltip from "../ButtonWithTooltip";
import ExportAsJsonFile from "../exports/ExportAsJsonFile";
import ExportAsCsvFile from "../exports/ExportAsCsvFile";
import ExportAsXLSXFile from "../exports/ExportAsXLSXFile";
import dateFormat from "../../constants/dateFormat";
import moment from "moment";
import locales from "../../constants/locales";
import translationConfigTables from "../../constants/translationConfigTables";
import InputTranslationFile from "../inputs/InputTranslationFile";
import { useAddHocFixEnsureLocales, useAddNewKey, useAddNewLocale, } from "../../hooks/useTableTranslation";
import useUpdateTranslationEdit from "../../hooks/useTableTranslationEdit";
import useSubmitTranslationEdit from "../../hooks/useTableTranslationSubmit";
import useTableTranslationColumnsAndData from "../../hooks/useTableTranslationColumnsAndData";
import useTableTranslationGetExportData from "../../hooks/useTableTranslationGetExportData";
import useModal from "../../hooks/useModal";
import InputTextList from "../inputs/InputTextList";
var debug = require('debug')('bk-manager:frontend-react:components:TableTranslation');
// const fnGetFacetedUniqueValues = _getFacetedUniqueValues()
/**
 * Render select eligible for
 *
 * @param value {string} Select value
 * @param set {function} Set current value
 * @param options {array} Lis with select options
 * @returns {JSX.Element}
 * @constructor
 */
function selectEligibleFor(_a) {
    var value = _a.value, set = _a.set, options = _a.options;
    return React.createElement("select", { className: "input-select-eligible-for custom-select", value: value, onChange: function (event) {
            set(event.target.value);
        } }, options.map(function (o) {
        return React.createElement("option", { key: o.value, value: o.value }, o.label);
    }));
}
/**
 * Component for rendering UI for translations
 *
 * @param props {object} Table translation properties
 * @returns {*}
 * @constructor
 */
export default function TableTranslation(props) {
    var _this = this;
    var isInvalidValidString = function (value) {
        // Regular expression to match letters, numbers, and underscores
        var regex = /[\\/]/;
        return regex.test(value);
    };
    var _a = useAddHocFixEnsureLocales({ translation: props.translation }), addHocUpdateMessage = _a.addHocUpdateMessage, getAddHocFixEnsureLocales = _a.getAddHocFixEnsureLocales;
    var translation = useState(props.translation)[0];
    var _b = useState(getAddHocFixEnsureLocales(util.clone(props.translation_edit) || {})), translation_edit = _b[0], setTranslationEdit = _b[1];
    var updateTranslationEdit = useUpdateTranslationEdit({
        translation: translation,
        translation_edit: translation_edit,
        setTranslationEdit: setTranslationEdit
    }).updateTranslationEdit;
    // New translation structure ok
    var _c = useAddNewKey({ translation: translation, translation_edit: translation_edit, updateTranslationEdit: updateTranslationEdit }), errorAdd = _c.errorAdd, addNewKey = _c.addNewKey, setErrorAdd = _c.setErrorAdd;
    var _d = useAddNewLocale({
        translation: translation,
        translation_edit: translation_edit,
        updateTranslationEdit: updateTranslationEdit
    }), errorAddLocale = _d.errorAddLocale, addNewLocale = _d.addNewLocale, setErrorAddLocale = _d.setErrorAddLocale;
    var _e = useState(props.locales_available_for_web), locales_available_for_web = _e[0], setLocalesAvailableForWeb = _e[1];
    var _f = useState(null), wrong_locale = _f[0], setWrongLocale = _f[1];
    var _g = useState(''), add = _g[0], setAdd = _g[1];
    var _h = useState(""), errorKey = _h[0], setErrorKey = _h[1];
    var _j = useState(''), addEligibleFor = _j[0], setAddEligibleFor = _j[1];
    var _k = useState(''), addLocale = _k[0], setAddLocale = _k[1];
    var _l = useState('all'), only_show = _l[0], setOnlyShow = _l[1];
    var _m = useState('show_all'), eligibleForFilter = _m[0], setEligibleForFilter = _m[1];
    var _o = useTableTranslationColumnsAndData({
        translation: translation,
        translation_edit: translation_edit,
        only_show: only_show,
        updateTranslationEdit: updateTranslationEdit,
        setEligibleForFilter: setEligibleForFilter,
        eligibleForFilter: eligibleForFilter
    }), getColumns = _o.getColumns, getData = _o.getData;
    // New translation structure ok
    var data = getData();
    // New translation structure ok
    var columns = getColumns(selectEligibleFor);
    var _p = useModal(), input_ref = _p.input_ref, onModalClose = _p.onModalClose, onModalOpen = _p.onModalOpen, modal_open = _p.modal_open;
    var _q = useState(''), description = _q[0], setDescription = _q[1];
    var _r = useState(''), error = _r[0], setError = _r[1];
    var _s = useState([]), steps = _s[0], setSteps = _s[1];
    var _t = useState(''), event_uid = _t[0], setEventUid = _t[1];
    var onSubmit = useSubmitTranslationEdit({
        translation_edit: translation_edit,
        description: description,
        onModalClose: onModalClose,
        setError: setError,
        setSteps: setSteps,
        setDescription: setDescription,
        setEventUid: setEventUid,
        updateTranslationEdit: updateTranslationEdit,
        locales_available_for_web: locales_available_for_web
    }).onSubmit;
    var _u = useTableTranslationGetExportData({ translation: translation, translation_edit: translation_edit }), getDatabaseExportSecondary = _u.getDatabaseExportSecondary, getExportCsv = _u.getExportCsv, getExportJson = _u.getExportJson, getExportXlsx = _u.getExportXlsx;
    var exportFileName = function () {
        var exported_at = moment().format(dateFormat.FILE_EXPORT);
        return "translation-".concat(exported_at);
    };
    var pageSizeOptions = [5, 10, 20, 25, 50, 100, 200, data.length].reduce(function (tot, val) {
        if (tot.indexOf(val) == -1 && val <= data.length) {
            tot.push(val);
        }
        return tot;
    }, []);
    var eligibleForOptions = __spreadArray([], translationConfigTables.options, true);
    eligibleForOptions.push({ value: '', label: '' });
    var link = React.createElement("a", { href: url.GLOBAL_CONFIG_VALID_LOCALES, target: '_blank' }, "here");
    return React.createElement("div", { className: 'table-config-global' },
        steps.length == 0
            ? React.createElement("div", null,
                React.createElement("div", { className: 'table-config-global-panel' },
                    React.createElement("div", { className: 'd-flex flex-row mb-3' },
                        React.createElement(ButtonWithTooltip, { className: 'global-config-reset mr-auto', label: 'Reset translation table', onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, updateTranslationEdit({
                                                update: getAddHocFixEnsureLocales({}),
                                                is_replace: true,
                                                reset: true
                                            })];
                                        case 1:
                                            _a.sent();
                                            setError('');
                                            setErrorAdd('');
                                            setErrorAddLocale('');
                                            return [2 /*return*/];
                                    }
                                });
                            }); } })),
                    addHocUpdateMessage != '' && React.createElement("div", { className: "w-100 alert alert-warning", role: "alert" }, addHocUpdateMessage),
                    React.createElement("div", { className: 'd-flex flex-wrap' },
                        React.createElement("div", { className: 'd-flex flex-column w-50' },
                            React.createElement("div", { className: 'd-flex flex-column' },
                                React.createElement("h3", { className: 'my-2' }, "Download translation"),
                                React.createElement("div", { className: 'd-flex flex-row mb-3' },
                                    React.createElement("div", { className: 'd-flex flex-column mr-auto' },
                                        React.createElement(ExportAsXLSXFile, { className: 'my-1', data: getExportXlsx(), file_name: "".concat(exportFileName(), "-edited.xlsx"), label: 'Download with edits as xlsx' }),
                                        React.createElement(ExportAsJsonFile, { className: 'my-1', data: getExportJson(), file_name: "".concat(exportFileName(), "-edited.json"), label: 'Download with edits as json' }),
                                        React.createElement(ExportAsCsvFile, { className: 'my-1', data: getExportCsv(), file_name: "".concat(exportFileName(), "-edited.csv"), label: 'Download with edits as csv' }),
                                        React.createElement(ExportAsJsonFile, { className: 'my-1', data: getDatabaseExportSecondary(), file_name: "".concat(exportFileName(), "-edited-feature.json"), label: 'Download for feature' })))),
                            React.createElement("div", { className: 'd-flex flex-column' },
                                React.createElement("h3", { className: 'mt-2 mb-1' }, "Edit translation table"),
                                React.createElement("small", { className: "form-text text-muted mt-2" }, 'To add a new time type start the key with "time_type_" or to ' +
                                    'add a new travel type start key with "travel_type_. "' +
                                    'Eligible for table can currently take two values: Time and travel types - used by all and Web, backend and ' +
                                    'puppeteer pdf server - used by web clients, backend and the puppeteer pdf server'),
                                React.createElement("div", { className: 'd-flex flex-column mt-2 mb-0' },
                                    React.createElement(InputText, { clearOnEnter: true, onBlur: function (_a) {
                                            var value = _a.value;
                                            if (isInvalidValidString(value)) {
                                                setErrorKey("Sorry,can  not container slash or backslash characters");
                                            }
                                            else {
                                                setErrorKey("");
                                            }
                                            setAdd(value);
                                        }, onFocus: function () {
                                            setErrorAdd('');
                                        }, placeholder: 'Enter new key ...', value: add }),
                                    errorKey && React.createElement("div", { style: { color: 'red' } }, errorKey),
                                    React.createElement("small", { className: 'mt-2' }, "Eligible for table"),
                                    selectEligibleFor({
                                        value: addEligibleFor,
                                        set: setAddEligibleFor,
                                        options: eligibleForOptions
                                    }),
                                    React.createElement(Button, { className: 'mr-2 mt-3', disabled: !!errorKey, onClick: function () {
                                            addNewKey({ value: add, eligibleFor: addEligibleFor });
                                            setAdd('');
                                            setAddEligibleFor('');
                                        } }, "Add key / row")),
                                React.createElement("small", { className: "form-text text-danger error-msg" }, errorAdd),
                                React.createElement("small", { className: "form-text text-muted mt-1" },
                                    'To add a new language simply enter a new locale and it will add and column to the' +
                                        ' table. Has to be an eligible locale for a country. Se list of available locales ',
                                    link),
                                React.createElement("div", { className: 'd-flex flex-row mt-2 mb-0' },
                                    React.createElement(Button, { className: 'mr-2', onClick: function () {
                                            addNewLocale({ value: addLocale });
                                            setAddLocale('');
                                        } }, "Add language / column"),
                                    React.createElement(InputText, { clearOnEnter: true, onBlur: function (_a) {
                                            var value = _a.value;
                                            return setAddLocale(value);
                                        }, onEnter: function (_a) {
                                            var value = _a.value;
                                            addNewLocale({ value: value });
                                        }, onFocus: function () { return setErrorAddLocale(''); }, placeholder: 'Enter new locale ...', value: addLocale })),
                                React.createElement("small", { className: "form-text text-danger error-msg" }, errorAddLocale))),
                        React.createElement("div", { className: 'd-flex flex-column w-50 pl-5' },
                            React.createElement("h3", { className: 'mt-1 mb-0' }, "Import translation file"),
                            React.createElement("div", { className: 'd-flex flex-row mb-1' },
                                React.createElement(InputTranslationFile, { onChange: function (_a) {
                                        var update = _a.update, is_replace = _a.is_replace;
                                        return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_b) {
                                                updateTranslationEdit({
                                                    is_replace: is_replace,
                                                    reset: true,
                                                    update: update,
                                                }).catch(function (e) { return console.error(e); });
                                                return [2 /*return*/];
                                            });
                                        });
                                    }, translation: translation, translation_edit: translation_edit })),
                            React.createElement("div", { className: 'd-flex flex-column' },
                                React.createElement("h3", { className: 'mt-2 mb-3' }, "Filter translation table"),
                                React.createElement("div", { className: 'd-flex flex-wrap' }, [
                                    { label: 'Show all', type: 'all' },
                                    { label: 'Show edits', type: 'edit' },
                                    { label: 'Show time types', type: 'time_type' },
                                    { label: 'Show travel types', type: 'travel_type' }
                                ].map(function (_a, index) {
                                    var label = _a.label, type = _a.type;
                                    return (React.createElement("div", { className: 'd-flex flex-wrap mb-2 w-50', key: index },
                                        React.createElement(Button, { variant: "primary", active: only_show == type, className: 'mr-2', onClick: function () { return setOnlyShow(type); } }, label)));
                                })),
                                React.createElement("div", { className: 'my-3' },
                                    React.createElement("h3", { className: 'mt-2 mb-3' }, 'Available locales for web'),
                                    React.createElement(InputTextList, { exclude_file_upload: true, help: React.createElement("span", null, 'Add locale that should be available for translation. Has to be a locale present in translation table below'), placeholder: 'Add locale ...', text_list: locales_available_for_web, onChange: function (_a) {
                                            var text_list = _a.text_list;
                                            for (var _i = 0, text_list_1 = text_list; _i < text_list_1.length; _i++) {
                                                var locale = text_list_1[_i];
                                                if (!locales[locale]) {
                                                    setWrongLocale(locale);
                                                    return;
                                                }
                                            }
                                            setWrongLocale(null);
                                            setLocalesAvailableForWeb(text_list);
                                        } }),
                                    wrong_locale && React.createElement("small", { className: 'alert-danger' }, "Locale ".concat(wrong_locale, " is not a valid locale"))))))),
                React.createElement("small", { className: "form-text text-muted mt-3" }, "You can edit directly in the table"),
                React.createElement(Table, { data: data, columns: columns, defaultPageSize: 10, 
                    // getFacetedUniqueValues={getFacetedUniqueValues}
                    // getFacetedUniqueValues={reduce((m:{[key:string]:TranslationRowData},v:TranslationRowData)=>{
                    //
                    //     }, {})
                    // }
                    onPageSizeChange: function () {
                    }, pageSizeOptions: pageSizeOptions, renderWithoutTd: true }),
                React.createElement(Button, { onClick: onModalOpen }, "Submit"),
                error != '' && React.createElement("div", { className: 'alert alert-danger my-1' }, error))
            : React.createElement("div", { className: 'col-12' },
                React.createElement("div", { className: 'alert alert-success my-1' }, "Global config translation data saved"),
                event_uid &&
                    React.createElement("p", { className: "mt-4" },
                        "Details can be found ",
                        React.createElement("a", { href: url.EVENT_PAGE(event_uid), target: "_blank" }, "here")),
                React.createElement(Button, { onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, updateTranslationEdit({
                                        update: {},
                                        is_replace: true,
                                        reset: true
                                    })];
                                case 1:
                                    _a.sent();
                                    location.reload();
                                    return [2 /*return*/];
                            }
                        });
                    }); } },
                    " ",
                    'Reload translation table')),
        React.createElement(Modal, { show: modal_open, onHide: onModalClose, onShow: function () { return input_ref.current.focus(); }, onEnter: function () { return debug('enter!'); } },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Submit Description")),
            React.createElement(Modal.Body, null,
                React.createElement(InputText, { input_ref: input_ref, value: description, onBlur: function (_a) {
                        var value = _a.value;
                        return setDescription(value);
                    }, placeholder: 'Add submit description ...' })),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: onModalClose }, "Close"),
                React.createElement(Button, { variant: "primary", onClick: onSubmit }, "Submit"))));
}
