var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import Content from "../Content";
import { defaultConfig } from "config-validation";
import InputSelectCustomer from "../inputs/InputSelectCustomer";
import url from "../../constants/url.jsx";
import util from "../../lib/util";
import RouterLink from "../RouterLink";
import Storage from "../../lib/storage";
import InputFile from "../inputs/InputFile";
import './SelectCustomerView.scss';
import ButtonWithTooltip from "../ButtonWithTooltip";
import ExportAsJsonFile from "../exports/ExportAsJsonFile";
import ConfigTool from "../../lib/config";
var debug = require('debug')('bk-manager:frontend-react:components:SelectCustomerView');
/**
 * Render select customer view
 */
var SelectCustomerView = /** @class */ (function (_super) {
    __extends(SelectCustomerView, _super);
    function SelectCustomerView(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            modal_open: false
        };
        _this.inputRef = React.createRef();
        _this.renderReset = _this.renderReset.bind(_this);
        _this.onSelect = _this.onSelect.bind(_this);
        _this.onModalClose = _this.onModalClose.bind(_this);
        _this.onModalOpen = _this.onModalOpen.bind(_this);
        _this.renderFileImport = _this.renderFileImport.bind(_this);
        return _this;
    }
    /**
     * On close modal
     */
    SelectCustomerView.prototype.onModalClose = function () {
        this.setState({ modal_open: false });
    };
    /**
     * On open modal
     */
    SelectCustomerView.prototype.onModalOpen = function () {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
        this.setState({ modal_open: true });
    };
    /**
     * On select company
     *
     * @param uid {string} Company uid
     * @param reset {boolean} Should config edits be reset
     * @param setLoading {function} Set loading
     * @param setError {function} Set error
     * @returns {Promise<*>}
     */
    SelectCustomerView.prototype.onSelect = function (_a) {
        var uid = _a.uid, reset = _a.reset, setLoading = _a.setLoading, setError = _a.setError;
        return __awaiter(this, void 0, void 0, function () {
            var state, path, res, resJson;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        debug('onSelect');
                        if (reset) {
                            if (setLoading) {
                                setLoading(false);
                            }
                            return [2 /*return*/, this.props.onSelect({ state: { config_edit: {}, uid: uid } })];
                        }
                        return [4 /*yield*/, Storage.get(Storage.getKey(uid))];
                    case 1:
                        state = (_b.sent()) || {};
                        state.config_edit = state.config_edit || {};
                        if (!(uid == 'default')) return [3 /*break*/, 2];
                        state.config = defaultConfig;
                        state.subcompanies = [];
                        return [3 /*break*/, 6];
                    case 2:
                        if (!(uid == '')) return [3 /*break*/, 3];
                        state.config = {};
                        state.config_edit = {};
                        state.subcompanies = [];
                        return [3 /*break*/, 6];
                    case 3:
                        path = location.origin + url.CONFIG + "?uid=".concat(uid);
                        return [4 /*yield*/, fetch(path)];
                    case 4:
                        res = _b.sent();
                        if (res.status != 200) {
                            setLoading(false);
                            setError(true);
                            console.error('res', res);
                            return [2 /*return*/, this.props.onSelect({ state: { config: {}, uid: '' } })];
                        }
                        return [4 /*yield*/, res.json()];
                    case 5:
                        resJson = _b.sent();
                        state.config = ConfigTool.addEmptyList(resJson.config);
                        state.subcompanies = resJson.subcompanies || [];
                        _b.label = 6;
                    case 6:
                        setLoading(false);
                        this.props.onSelect({
                            state: __assign(__assign({}, state), { uid: uid }),
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Render file import input
     *
     * @returns {JSX.Element}
     */
    SelectCustomerView.prototype.renderFileImport = function () {
        var _this = this;
        var text = 'add changes from file';
        if (this.props.type == 'create') {
            text = 'and/or import file';
        }
        return (React.createElement("div", { className: 'add-by-file d-flex flex-column align-items-center' },
            React.createElement("div", null,
                React.createElement("p", null, text)),
            React.createElement("div", null,
                React.createElement(InputFile, { help: this.props.type == 'edit'
                        ? 'Please submit all current edits you want to save before doing this step since it will ' +
                            'discard all current edits with the ones provided from file'
                        : '', onChange: function (_a) {
                        var data = _a.data;
                        var config_id = Storage.getKey('config-' + _this.props.type);
                        if (config_id == 'config-create') {
                            data = ConfigTool.addEmptyList(data);
                            _this.props.onSelect({
                                state: {
                                    config: data,
                                    uid: 'file-import'
                                },
                            });
                        }
                        else {
                            _this.props.onSelect({
                                state: {
                                    config_edit: data,
                                    uid: util.getUid()
                                },
                            });
                        }
                    } }))));
    };
    /**
     * Render reset button
     *
     * @returns {JSX.Element}
     */
    SelectCustomerView.prototype.renderReset = function () {
        var _this = this;
        return React.createElement(ButtonWithTooltip, { onClick: function () { return _this.onSelect({ reset: true, uid: util.getUid() }); } });
    };
    SelectCustomerView.prototype.render = function () {
        var config = ConfigTool.get({
            config: this.props.config,
            config_edit: this.props.config_edit,
            translation: this.props.translation
        });
        var file_name_export = '';
        var file_name_export_salary_system = '';
        if (config.backend.companyName) {
            file_name_export = util.getUid()
                ? "config-".concat(config.backend.companyName.split(' ').join('-'), ".json")
                : null;
            file_name_export_salary_system = util.getUid()
                ? "config-salary-system-".concat(config.backend.companyName.split(' ').join('-'), ".json")
                : null;
        }
        return (React.createElement("div", { className: 'container' },
            React.createElement(Content, null,
                this.props.missing_translations.length > 0 &&
                    React.createElement("div", { className: 'alert alert-warning' }, "Missing translation for keys ".concat(this.props.missing_translations.join(', '), ". \n                            This should be fixed. Go to global config and edit the translation table accordingly")),
                React.createElement("div", { className: 'help-text' },
                    React.createElement("small", null,
                        React.createElement("em", null, this.props.help_text))),
                React.createElement(InputSelectCustomer, { type: this.props.type, onSelect: this.onSelect, options: util.sort(this.props.company_list, function (v) { return v.label; }) }),
                (this.props.type === 'create' || (this.props.type === 'edit' && !util.isEmptyObject(this.props.config)))
                    && this.renderFileImport(),
                !['delete', 'show'].includes(this.props.type) && this.renderReset(),
                !['create', 'delete'].includes(this.props.type) && file_name_export &&
                    React.createElement(React.Fragment, null,
                        React.createElement(ExportAsJsonFile, { className: 'ml-2', file_name: file_name_export, data: this.props.config, label: 'Download config without changes' }),
                        React.createElement(ExportAsJsonFile, { className: 'ml-2', file_name: file_name_export_salary_system, data: {
                                backend: {
                                    salarySystem: this.props.config.backend.salarySystem
                                }
                            }, label: 'Download salary system without changes' })),
                this.props.continue && util.getUid() != '' &&
                    React.createElement(RouterLink, __assign({}, this.props.continue)))));
    };
    return SelectCustomerView;
}(Component));
export default SelectCustomerView;
