var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import util from "../lib/util";
import url from "../constants/url.jsx";
/**
 * Hook for submitting translation table to db
 *
 * @param translation_edit {object} Translation edits to translation mapping
 * @param description {string} Submit event description
 * @param onModalClose {function} Callback for modal close
 * @param setError {function} Callback for setting error
 * @param setSteps {function} Callback for setting submit event steps
 * @param setDescription {function} Callback for setting event description
 * @param setEventUid {function} Callback for setting event uid
 * @param updateTranslationEdit {function} Callback for updating translation edits
 * @param locales_available_for_web {array<string>} List with locales that are available for web
 * @returns {{onSubmit: onSubmit}}
 */
export default function useSubmit(_a) {
    var translation_edit = _a.translation_edit, description = _a.description, onModalClose = _a.onModalClose, setError = _a.setError, setSteps = _a.setSteps, setDescription = _a.setDescription, setEventUid = _a.setEventUid, updateTranslationEdit = _a.updateTranslationEdit, locales_available_for_web = _a.locales_available_for_web;
    function onSubmit() {
        return __awaiter(this, void 0, void 0, function () {
            var body, body_text, method, response, responseJson;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        onModalClose();
                        setError('');
                        setSteps([]);
                        body = {
                            description: description
                        };
                        body.global_config_edit = {
                            locales_available_for_web: locales_available_for_web
                        };
                        if (!util.isEmptyObject(translation_edit)) {
                            body.global_config_edit.translation = { master_config_tool: translation_edit };
                        }
                        body_text = JSON.stringify(body);
                        method = 'PUT';
                        return [4 /*yield*/, fetch(location.origin + url.GLOBAL_CONFIG, { method: method, body: body_text })];
                    case 1:
                        response = _a.sent();
                        if (response.status !== 200) {
                            console.error(response);
                            setError('Ops unexpected error happen');
                        }
                        return [4 /*yield*/, response.json()];
                    case 2:
                        responseJson = _a.sent();
                        if (responseJson.error) {
                            return [2 /*return*/, setError(responseJson.error)];
                        }
                        setSteps(responseJson.steps || []);
                        setDescription(description);
                        setEventUid(responseJson.event_uid);
                        return [4 /*yield*/, updateTranslationEdit({
                                update: {},
                                is_replace: true,
                                reset: true
                            })];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    return { onSubmit: onSubmit };
}
