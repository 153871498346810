/**
 *Created by Mikael Lindahl on 2019-12-10
 */
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import './ExportUserEmail.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
var debug = require('debug')('bk-manager:frontend-react:components:ExportUserEmail');
function AllCheckbox(props) {
    var checked = props.checkedStateAll[props.choice];
    return React.createElement("div", null,
        React.createElement("input", { key: 'input', onChange: function () {
                var _a;
                var newCheckedState = props.checkedState.map(function (item) {
                    item[props.choice] = !checked;
                    return item;
                });
                props.setCheckedStateAll(__assign(__assign({}, props.checkedStateAll), (_a = {}, _a[props.choice] = !checked, _a)));
                props.setCheckedState(newCheckedState);
                props.setChange(true);
            }, type: 'checkbox', checked: checked, name: props.choice, title: 'Select all' }));
}
/**
 * Display selected user emails with copy to clipboard button
 *
 * @param props
 * @constructor
 */
function UserEmails(props) {
    var _a = useState(false), expand = _a[0], setExpand = _a[1];
    var text = '';
    if (expand) {
        text = props.emails.join(', ');
    }
    else {
        text = props.emails.slice(0, 8).join(', ');
        if (props.emails.length > 8) {
            text += ' ...';
        }
    }
    return text && React.createElement("div", null,
        React.createElement("div", { className: 'py-2' },
            text,
            props.emails.length > 8 && React.createElement("i", { className: "pl-2 fas fa-chevron-" + (expand ? 'up' : 'down'), onClick: function () { return setExpand(!expand); } })),
        React.createElement("div", { className: 'pb-3' },
            React.createElement(CopyToClipboard, { text: props.emails.join(', '), onCopy: function () { return props.setChange(false); } },
                React.createElement("button", { className: 'btn btn-secondary' }, "Copy to clipboard")),
            !props.change ? React.createElement("span", { className: 'pl-2', style: { color: 'red' } }, "Copied.") : null));
}
/**
 * Filter and copy emails to clipboard
 *
 * @param props {object} Export data properties
 * @returns {*}
 * @constructor
 */
export default function ExportUserEmail(props) {
    var _a = useState(props.user_emails_instances.map(function (instance) { return ({
        company_name: instance.company_name,
        users: instance.users,
        admin: false,
        manager: false,
        UE: false,
        regular: false
    }); })), checkedState = _a[0], setCheckedState = _a[1];
    var _b = useState({
        admin: false,
        manager: false,
        UE: false,
        regular: false
    }), checkedStateAll = _b[0], setCheckedStateAll = _b[1];
    var _c = useState(true), change = _c[0], setChange = _c[1];
    var emails = [];
    for (var _i = 0, checkedState_1 = checkedState; _i < checkedState_1.length; _i++) {
        var instance = checkedState_1[_i];
        var _loop_1 = function (user) {
            if ((user.isAdmin && instance.admin)
                || (user.isManager && instance.manager)
                || (user.isUE && instance.UE)
                || (instance.regular && !(user.isAdmin || user.isManager || user.isUE))) {
                if (!emails.find(function (e) { return e == user.email; })) {
                    emails.push(user.email);
                }
            }
        };
        for (var _d = 0, _e = instance.users; _d < _e.length; _d++) {
            var user = _e[_d];
            _loop_1(user);
        }
    }
    var choices = ['admin', 'manager', 'UE', 'regular'];
    return (React.createElement("div", { className: 'export-user-email' },
        React.createElement(UserEmails, { change: change, emails: emails, setChange: setChange }),
        React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("td", { key: 'company' }, "Company"),
                    choices.map(function (c) { return React.createElement("td", { key: c, className: 'choice input' }, c[0].toUpperCase() + c.slice(1)); }))),
            React.createElement("tbody", null,
                React.createElement("tr", { key: 'all', className: 'select-all' },
                    React.createElement("td", { key: 'empty' }),
                    choices.map(function (choice) {
                        return React.createElement("td", { key: choice, className: 'input' },
                            React.createElement(AllCheckbox, { choice: choice, checkedState: checkedState, checkedStateAll: checkedStateAll, setChange: setChange, setCheckedState: setCheckedState, setCheckedStateAll: setCheckedStateAll }));
                    })),
                checkedState.map(function (item) { return (React.createElement("tr", { key: item.company_name },
                    React.createElement("td", null, item.company_name),
                    choices.map(function (choice) {
                        return React.createElement("td", { key: choice, className: 'input' },
                            React.createElement("input", { key: 'input', onChange: function () {
                                    var _a;
                                    var index = checkedState.findIndex(function (_item) { return _item.company_name == item.company_name; });
                                    var newCheckedState = __spreadArray([], checkedState, true);
                                    newCheckedState[index][choice] = !newCheckedState[index][choice];
                                    setCheckedState(newCheckedState);
                                    var all = newCheckedState.map(function (item) { return item[choice]; }).reduce(function (val, all) {
                                        all = val && all;
                                        return all;
                                    }, true);
                                    setCheckedStateAll(__assign(__assign({}, checkedStateAll), (_a = {}, _a[choice] = all, _a)));
                                    setChange(true);
                                }, type: 'checkbox', checked: item[choice], name: choice }));
                    }))); })))));
}
