var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import "./ConfigTableView.scss";
import ConfigTool from "../../lib/config";
import RouterLink from "../RouterLink";
import Url from "../../constants/url.jsx";
import util from "../../lib/util";
import SubmitSteps from "../SubmitSteps";
import Table from "../tables/Table";
import ExportAsJsonFile from "../exports/ExportAsJsonFile";
import { Button, Modal } from "react-bootstrap";
import "./ConfigSubmitView.scss";
var debug = require("debug")("bk-manager:frontend-react:components:ConfigSubmitView");
/**
 * Render config submit view
 */
var ConfigSubmitView = /** @class */ (function (_super) {
    __extends(ConfigSubmitView, _super);
    function ConfigSubmitView(props) {
        var _this = _super.call(this, props) || this;
        var config = ConfigTool.get({
            config_edit: props.config_edit,
            config: props.config,
            translation: props.translation,
        });
        debug("constructor config", util.clone(config));
        _this.state = {
            companyName: config.backend.companyName,
            description: "",
            event_uid: "",
            error: "",
            message: "",
            failure: false,
            modal_open: false,
            success: false,
            steps: [],
        };
        _this.inputRef = React.createRef();
        _this.render_diff = _this.render_diff.bind(_this);
        _this.onChangeDescription = _this.onChangeDescription.bind(_this);
        _this.onSubmit = _this.onSubmit.bind(_this);
        _this.onModalClose = _this.onModalClose.bind(_this);
        _this.onModalOpen = _this.onModalOpen.bind(_this);
        return _this;
    }
    /**
     * On change description for submit event
     *
     * @param event {object} Input event object with description
     */
    ConfigSubmitView.prototype.onChangeDescription = function (event) {
        event.preventDefault();
        this.setState({ description: event.target.value });
    };
    /**
     * On close modal
     */
    ConfigSubmitView.prototype.onModalClose = function () {
        this.setState({ modal_open: false });
    };
    /**
     * On open modal
     */
    ConfigSubmitView.prototype.onModalOpen = function () {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
        this.setState({ modal_open: true });
    };
    /**
     * On submit config
     *
     * @returns {Promise<void|*>}
     */
    ConfigSubmitView.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var method, body, body_text, response, responseJson, state;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        debug("onSubmit", this.props.type);
                        this.onModalClose();
                        this.setState({ failure: false, success: false, steps: [] });
                        method = this.props.type == "create"
                            ? "POST"
                            : this.props.type == "delete"
                                ? "DELETE"
                                : "PUT";
                        body = {
                            description: this.state.description,
                        };
                        if (this.props.type == "edit") {
                            body.config_edit = ConfigTool.getFinalEdit(this.props);
                            body.uid = util.getUid();
                        }
                        else if (this.props.type == "delete") {
                            body.uid = util.getUid();
                        }
                        else if (this.props.type == "create") {
                            body.config = ConfigTool.get(this.props);
                        }
                        body_text = JSON.stringify(body);
                        return [4 /*yield*/, fetch(location.origin + Url.CUSTOMER, {
                                method: method,
                                body: body_text,
                            })];
                    case 1:
                        response = _a.sent();
                        if (response.status !== 200) {
                            return [2 /*return*/, this.setState({
                                    failure: true,
                                })];
                        }
                        return [4 /*yield*/, response.json()];
                    case 2:
                        responseJson = _a.sent();
                        if (responseJson.error) {
                            console.error(responseJson.error);
                            return [2 /*return*/, this.setState({
                                    failure: true,
                                    message: responseJson.message,
                                    error: responseJson.error,
                                })];
                        }
                        this.setState({
                            success: true,
                            steps: responseJson.steps || [],
                            description: "",
                            event_uid: responseJson.event_uid,
                            uid: responseJson.uid,
                        });
                        state = {
                            uid: util.getUid(),
                            config_edit: {},
                        };
                        if (this.props.type == "edit") {
                            state.config = responseJson.config;
                        }
                        return [2 /*return*/, this.props.setPageState(state)];
                }
            });
        });
    };
    /**
     * Render different between current config and config with edits
     *
     * @returns {false|JSX.Element}
     */
    ConfigSubmitView.prototype.render_diff = function () {
        var config_fix = ConfigTool.fix(this.props.config, this.props.translation);
        var config_default = ConfigTool.syncWithDefault(this.props.config);
        var config_fix_flatten = util.flattenObject(config_fix, "action", [
            "added",
            "edited",
            "deleted",
        ]);
        var config_default_flatten = util.flattenObject(config_default, "action", [
            "added",
            "edited",
            "deleted",
        ]);
        var config_edit_flatten = util.flattenObject(this.props.config_edit);
        var setup = {};
        for (var key in config_fix_flatten) {
            setup[key] = __assign({ key: key, description: "Add-hoc fix", className: "diff-fix" }, config_fix_flatten[key]);
        }
        for (var key in config_default_flatten) {
            setup[key] = __assign({ key: key, description: "Added by default. Please make sure it is correct", className: "diff-default" }, config_default_flatten[key]);
        }
        for (var key in config_edit_flatten) {
            setup[key] = {
                key: key,
                description: "Added by user",
                before: util.getNestedObject(this.props.config, key),
                after: config_edit_flatten[key],
            };
        }
        var data = util.dicToList(setup);
        data.map(function (s) {
            for (var _i = 0, _a = ["before", "after"]; _i < _a.length; _i++) {
                var key = _a[_i];
                var _key = key;
                var d = s[_key];
                if (typeof d == "boolean") {
                    d = String(d);
                }
                else if (d == undefined || d == null) {
                    d = undefined;
                }
                else if (Array.isArray(d) && typeof d[0] == "object") {
                    d = JSON.stringify(d);
                    d = "[ ... ]";
                }
                else if (typeof d == "object") {
                    d = JSON.stringify(d);
                    d = d.split(",").join(", ");
                }
                else if (typeof d == "string") {
                    if (s.key === "web.time.minTimeInterval" ||
                        s.key === "web.time.minHourValue" ||
                        s.key === "mobile.time.minTimeInterval") {
                        d = parseFloat(d);
                    }
                    else {
                        d = "\"".concat(d, "\"");
                    }
                }
                s[_key] = d;
            }
        });
        data = util.sort(data, function (s) { return s.key; });
        var columns = [
            {
                header: "Key",
                accessorKey: "key", // String-based value accessors!
                // minWidth: 200,
            },
            {
                header: "Before",
                accessorKey: "before",
                // minWidth: 200,
            },
            {
                header: "After",
                accessorKey: "after",
                // minWidth: 200,
            },
            {
                header: "Action",
                accessorKey: "description",
                // minWidth: 150,
            },
        ];
        return (data.length > 0 && (React.createElement("div", { className: "my-5" },
            React.createElement("div", { className: "my-1" },
                React.createElement("b", null, "Applied changes")),
            React.createElement(Table, { data: data, columns: columns, defaultPageSize: 10, 
                // showPaginationTop={false}
                // minRows={0}
                getTrProps: function (row) {
                    return {
                        className: row.original.className,
                    };
                } }))));
    };
    ConfigSubmitView.prototype.render = function () {
        var _this = this;
        var config_edit_final = ConfigTool.getFinalEdit(this.props);
        var instruction = {
            create: (React.createElement("div", null,
                'Press "Submit" to to deploy the new customer ',
                React.createElement("b", { className: "company" }, this.state.companyName || '""'))),
            edit: (React.createElement("div", null,
                'Press "Submit" to update customer config for ',
                React.createElement("b", { className: "company" }, this.state.companyName),
                " with uid ",
                React.createElement("b", { className: "company" }, util.getUid()),
                ".")),
        };
        var issues = this.props.issues.reduce(function (tot, val) {
            tot[val.type]++;
            return tot;
        }, { conflict: 0, warning: 0 });
        var file_name_export = "config-".concat(this.state.companyName
            .split(" ")
            .join("-"), ".json");
        if (location && this.state.success && util.getTip() == "delete") {
            return (location.href = Url.EVENT_PAGE(this.state.event_uid));
        }
        return (React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row config-submit-view" },
                !this.state.success && (React.createElement("div", { className: "col-12" },
                    util.getTip() != "delete" && (React.createElement(React.Fragment, null,
                        React.createElement("h2", null, "Summary"),
                        issues.warning > 0 && (React.createElement("div", { className: "alert alert-warning" }, "".concat(issues.warning, " warnings remaining"))),
                        issues.conflict > 0 && (React.createElement("div", { className: "alert alert-danger" }, "".concat(issues.conflict, " conflicts remaining"))),
                        React.createElement("div", { className: "alert alert-primary" }, this.props.schedule
                            ? ""
                            : "Changes will take effect immediately"),
                        this.render_diff())),
                    util.getTip() == "delete" && (React.createElement("div", null, "You are about to delete customer ",
                        React.createElement("b", { className: "text-warning" }, this.state.companyName), " with uid ".concat(util.getUid(), ". Once this is done you will no see the customer \n                        in byggkollen. However the customer data can be restored from the delete event."))),
                    React.createElement("div", { className: "instructions" }, instruction[this.props.type]),
                    this.state.failure && (React.createElement("div", { className: "alert alert-danger my-1" }, this.state.error || "Ops... something went wrong")),
                    React.createElement("div", { className: "d-flex flex-row" },
                        this.props.back && React.createElement(RouterLink, __assign({}, this.props.back)),
                        React.createElement("button", { className: "btn btn-primary", onClick: this.onModalOpen, disabled: JSON.stringify(config_edit_final) === "{}" &&
                                this.props.type == "edit" }, "Submit"),
                        ["create", "delete"].indexOf(this.props.type) == -1 && (React.createElement(ExportAsJsonFile, { file_name: file_name_export, className: "ml-2", data: this.props.config, label: "Download config without changes" }))))),
                this.state.success && (React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "alert alert-success my-1" }, "Data saved for customer ".concat(this.state.companyName, " with uid ").concat(this.state.uid)),
                    this.state.event_uid && (React.createElement("p", { className: "mt-4" },
                        "Details can be found ",
                        React.createElement("a", { href: Url.EVENT_PAGE(this.state.event_uid), target: "_blank" }, "here"))),
                    this.state.steps.length > 0 && (React.createElement("div", { className: "mb-5 mt-4" },
                        React.createElement("h2", null, "Changes to database"),
                        React.createElement(SubmitSteps, { steps: this.state.steps })))))),
            React.createElement(Modal, { show: this.state.modal_open, onHide: this.onModalClose, onShow: function () { return _this.inputRef.current.focus(); }, onEnter: function () { return debug("enter!"); } },
                React.createElement(Modal.Header, { closeButton: true },
                    React.createElement(Modal.Title, null, "Submit Description")),
                React.createElement(Modal.Body, null,
                    React.createElement("input", { className: "p-1", ref: this.inputRef, type: "text", value: this.state.description, onChange: this.onChangeDescription, placeholder: "Add submit description ..." })),
                React.createElement(Modal.Footer, null,
                    React.createElement(Button, { variant: "secondary", onClick: this.onModalClose }, "Close"),
                    React.createElement(Button, { variant: "primary", disabled: this.state.description == "", onClick: this.onSubmit }, "Submit")))));
    };
    return ConfigSubmitView;
}(Component));
export default ConfigSubmitView;
