var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from "react";
import "./CustomerDocumentationView.scss";
import SubmitSteps from "../SubmitSteps";
import ExportAsJsonFile from "../exports/ExportAsJsonFile";
import ButtonWithTooltip from "../ButtonWithTooltip";
import useRestoreCustomer from "../../hooks/useRestoreCustomer";
import useModal from "../../hooks/useModal";
import InputText from "../inputs/InputText";
import { Button, Modal } from "react-bootstrap";
import url from "../../constants/url.jsx";
var debug = require('debug')('bk-manager:frontend-react:components:ConfigEventView');
/**
 * Render customer event view. Events are created when e.g. submitting
 * to translation edits or edits to byggkollen config
 *
 * @param props {object} Config event view properties
 * @returns {JSX.Element}
 * @constructor
 */
export default function ConfigEventView(props) {
    var _this = this;
    debug('ConfigEventView');
    if (!props.event) {
        return React.createElement("div", { id: 'event', className: 'm-5' }, 'Sorry the events is missing in the database. Somehow the event did ' +
            'not save properly at creation. Might be an issue with firebase db');
    }
    var event = useState(props.event)[0];
    var _a = useModal(), input_ref = _a.input_ref, onModalClose = _a.onModalClose, onModalOpen = _a.onModalOpen, modal_open = _a.modal_open;
    var _b = useState(''), description = _b[0], setDescription = _b[1];
    var _c = useState(''), error = _c[0], setError = _c[1];
    var _d = useState([]), steps = _d[0], setSteps = _d[1];
    var _e = useState(''), event_uid = _e[0], setEventUid = _e[1];
    var onSubmitRestoreCustomer = useRestoreCustomer({
        description: description,
        onModalClose: onModalClose,
        setDescription: setDescription,
        setError: setError,
        setEventUid: setEventUid,
        setSteps: setSteps
    }).onSubmitRestoreCustomer;
    var file_name_pre;
    var created_at_file = event.created_at.split(':').join('').split('-').join('').split('T').join('-');
    file_name_pre = "".concat(event.file_name || 'config', "-").concat(created_at_file);
    console.log(event);
    // Backward compatibility
    if (event.data.config) {
        event.data.before = event.data.config.before;
        event.data.after = event.data.config.after;
    }
    return (React.createElement("div", { id: 'event' },
        steps.length == 0 ?
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: "header" },
                    React.createElement("div", { className: "header-body" },
                        React.createElement("div", { className: "row align-items-end" },
                            React.createElement("div", { className: "col" },
                                React.createElement("h6", { className: "header-pretitle" },
                                    event.database,
                                    ' | ',
                                    event.label && React.createElement("div", { className: 'd-inline company-name' }, event.label)),
                                React.createElement("h1", { className: "header-title" }, event.title))))),
                React.createElement("div", { className: 'container pb-5' },
                    React.createElement("div", { className: 'mb-5' },
                        'Created at: ',
                        event.created_at.split('T').join(' ')),
                    React.createElement("div", { className: 'd-flex flex-column' },
                        event.data.before
                            && React.createElement("div", { className: 'mb-2' },
                                React.createElement(ExportAsJsonFile, { file_name: "".concat(file_name_pre, "-before.json"), data: event.data.before, label: 'Download data present before event' })),
                        event.data.after
                            && React.createElement("div", { className: 'mb-2' },
                                React.createElement(ExportAsJsonFile, { file_name: "".concat(file_name_pre, "-after.json"), data: event.data.after, label: 'Download data present after event' })),
                        event.type == 'customer-delete' &&
                            React.createElement(ButtonWithTooltip, { className: 'mb-2', variant: 'primary', onClick: onModalOpen, label: 'Restore customer', tooltip: 'Restore the customer from data' }),
                        event.type == 'customer-delete' &&
                            React.createElement(ButtonWithTooltip, { variant: 'primary', onClick: function () { return location.href = url.APP_BASE + '/delete'; }, label: 'Back to customer delete', tooltip: 'Go back to customer delete view' }),
                        error != '' && React.createElement("div", { className: 'alert alert-danger mt-2' }, error)),
                    event.data.uid && React.createElement("div", { className: 'mb-5 mt-5' },
                        React.createElement("h3", null, "Customer uid"),
                        React.createElement("div", null, event.data.uid)),
                    React.createElement("div", { className: 'mb-5' },
                        React.createElement("h3", null, "Description"),
                        React.createElement("div", null, event.description)),
                    React.createElement("div", { className: 'mb-5' },
                        React.createElement("h3", null, "Updated by"),
                        React.createElement("div", null, "Name: ".concat(event.user.name)),
                        React.createElement("div", null, "Id: ".concat(event.user.id))),
                    React.createElement("div", { className: 'mb-5' },
                        React.createElement("h3", null, 'Steps'),
                        React.createElement(SubmitSteps, { steps: event.data.steps })),
                    event.data.config_edit &&
                        React.createElement("div", { className: 'mb-5' },
                            React.createElement("h3", null, 'Config edit'),
                            React.createElement("pre", null,
                                React.createElement("code", null,
                                    React.createElement("div", null, JSON.stringify(event.data.config_edit, null, 2))))))) :
            React.createElement("div", { className: 'col-12' },
                React.createElement("div", { className: 'alert alert-success mt-5 mb-1' }, "Company ".concat(event.data.before.company_name, " restored")),
                event_uid &&
                    React.createElement("p", { className: "mt-4" },
                        "Details can be found ",
                        React.createElement("a", { href: url.EVENT_PAGE(event_uid), target: "_blank" }, "here")),
                React.createElement(Button, { onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            location.reload();
                            return [2 /*return*/];
                        });
                    }); } },
                    " ",
                    'Back')),
        React.createElement(Modal, { show: modal_open, onHide: onModalClose, onShow: function () { return input_ref.current.focus(); }, onEnter: function () { return debug('enter!'); } },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Restore Description")),
            React.createElement(Modal.Body, null,
                React.createElement(InputText, { input_ref: input_ref, value: description, onBlur: function (_a) {
                        var value = _a.value;
                        return setDescription(value);
                    }, placeholder: 'Add restore description ...' })),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { variant: "secondary", onClick: onModalClose }, "Close"),
                React.createElement(Button, { variant: "primary", onClick: function () { return onSubmitRestoreCustomer({
                        company_name: event.data.before.company_name,
                        users: event.data.before.users,
                        uid: event.data.uid
                    }); } }, "Submit")))));
}
