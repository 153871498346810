var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from "react";
import Config from "../lib/config";
import PropTypes from "prop-types";
import "./DataProvider.scss";
import Storage from "../lib/storage";
import util from "../lib/util";
var debug = require('debug')('bk-manager:frontend-react:components:DataProvider');
/**
 * Wrapper for fetching data on page load
 */
var DataProvider = /** @class */ (function (_super) {
    __extends(DataProvider, _super);
    function DataProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            data: {},
            loaded: false,
            storage: {},
            placeholder: "Loading..."
        };
        return _this;
    }
    DataProvider.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var endpoint, response, res, e_1, data, tip, data_storage, is_edit, key, entry, locale, id, data_storage, _i, _a, key, _b, _c, key, _d, config_edit, is_edit_update;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        debug('componentDidMount', this.props.endpoint);
                        endpoint = location.origin + this.props.endpoint;
                        endpoint = this.props.is_config ? endpoint + util.getUidAsQuery() : endpoint;
                        return [4 /*yield*/, fetch(endpoint)];
                    case 1:
                        response = _e.sent();
                        if (!(response.status !== 200)) return [3 /*break*/, 6];
                        _e.label = 2;
                    case 2:
                        _e.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, response.json()];
                    case 3:
                        res = _e.sent();
                        console.error(res.error);
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _e.sent();
                        console.error(e_1);
                        console.error(response);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, this.setState({ placeholder: "Something went wrong" })];
                    case 6: return [4 /*yield*/, response.json()];
                    case 7:
                        data = _e.sent();
                        tip = util.getTip();
                        if (!(['global-config'].indexOf(tip) != -1)) return [3 /*break*/, 11];
                        return [4 /*yield*/, Storage.get('global-config')];
                    case 8:
                        data_storage = (_e.sent()) || {};
                        if (!data_storage.translation_edit) return [3 /*break*/, 10];
                        is_edit = false;
                        for (key in data_storage.translation_edit) {
                            entry = data_storage.translation_edit[key];
                            // Missmatch keys
                            if (entry.key && entry.key.text != key) {
                                delete data_storage.translation_edit[key];
                                continue;
                            }
                            for (locale in entry) {
                                if (entry[locale].text == entry[locale].text_original
                                    && entry[locale].action == 'edited') {
                                    delete entry[locale];
                                    is_edit = true;
                                }
                                else if (entry[locale].action == 'default') {
                                    delete entry[locale];
                                    is_edit = true;
                                }
                            }
                            if (util.isEmptyObject(entry)) {
                                delete data_storage.translation_edit[key];
                            }
                        }
                        if (!is_edit) return [3 /*break*/, 10];
                        return [4 /*yield*/, Storage.set('global-config', { translation_edit: data_storage.translation_edit })];
                    case 9:
                        _e.sent();
                        _e.label = 10;
                    case 10:
                        data = __assign(__assign({}, data_storage), data // data from server always has priority
                        );
                        _e.label = 11;
                    case 11:
                        if (!(['create', 'edit', 'show', 'delete'].indexOf(tip) != -1)) return [3 /*break*/, 24];
                        id = Storage.getKey();
                        return [4 /*yield*/, Storage.get(id)];
                    case 12:
                        data_storage = (_e.sent()) || {};
                        _i = 0, _a = ['backend', 'mobile', 'web', 'label', 'storage', 'create_based_on', 'config_edit-edit'];
                        _e.label = 13;
                    case 13:
                        if (!(_i < _a.length)) return [3 /*break*/, 16];
                        key = _a[_i];
                        if (!data_storage[key]) return [3 /*break*/, 15];
                        delete data_storage[key];
                        return [4 /*yield*/, Storage.remove(id, key)];
                    case 14:
                        _e.sent();
                        _e.label = 15;
                    case 15:
                        _i++;
                        return [3 /*break*/, 13];
                    case 16:
                        _b = 0, _c = ['config', 'config_edit'];
                        _e.label = 17;
                    case 17:
                        if (!(_b < _c.length)) return [3 /*break*/, 20];
                        key = _c[_b];
                        if (!(data_storage[key] && !util.getUid())) return [3 /*break*/, 19];
                        delete data_storage[key];
                        return [4 /*yield*/, Storage.remove(id, key)];
                    case 18:
                        _e.sent();
                        _e.label = 19;
                    case 19:
                        _b++;
                        return [3 /*break*/, 17];
                    case 20:
                        data = __assign(__assign({}, data_storage), data // data from server always has priority
                        );
                        data.config = Config.addEmptyList(data.config || {});
                        if (!data.config_edit) return [3 /*break*/, 23];
                        _d = Config.removeValuesFromConfigEditThatAreEqualInConfig(data.config_edit, data.config), config_edit = _d.config_edit, is_edit_update = _d.is_edit_update;
                        if (!is_edit_update) return [3 /*break*/, 22];
                        return [4 /*yield*/, Storage.set(id, { config_edit: config_edit })];
                    case 21:
                        _e.sent();
                        _e.label = 22;
                    case 22: return [3 /*break*/, 24];
                    case 23:
                        data.config_edit = {};
                        _e.label = 24;
                    case 24:
                        // debug('data', data)
                        this.setState({
                            data: data,
                            loaded: true,
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DataProvider.prototype.render = function () {
        var _a = this.state, data = _a.data, loaded = _a.loaded, placeholder = _a.placeholder, storage = _a.storage;
        return loaded ? this.props.render(__assign(__assign({}, data), { storage: storage })) :
            React.createElement("div", { className: 'data-provider center' },
                React.createElement("p", null, placeholder));
    };
    DataProvider.propTypes = {
        endpoint: PropTypes.string.isRequired,
        is_config: PropTypes.bool,
        render: PropTypes.func.isRequired
    };
    return DataProvider;
}(Component));
export default DataProvider;
