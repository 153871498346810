/**
 *Created by Mikael Lindahl on 2021-04-20
 */
"use strict";
var salary = {
    COMPANY_INIT: {
        allowance: {
            version_1_active: false,
            version_2_active: false,
        },
        instructions: [],
        info: { company_name: '' }
    },
    DEFAULT_INSTRUCTION: {
        employee_type: 'default',
        quantity_from: 'hours',
        salary_instruction_map: 'report',
    }
};
export default salary;
