/**
 *Created by Mikael Lindahl on 2020-10-06
 */
"use strict";
import { useState, useRef } from "react";
/**
 * Hook for using modal
 *
 * @returns {{modal_open: *, onModalClose: onModalClose, onModalOpen: onModalOpen, input_ref: *}}
 */
var useModal = function (init) {
    if (init === void 0) { init = false; }
    var input_ref = useRef(null);
    var _a = useState(init), modal_open = _a[0], setModalOpen = _a[1];
    /**
     * Set modal close
     */
    function onModalClose() {
        setModalOpen(false);
    }
    /**
     * Set modal open
     */
    function onModalOpen() {
        if (input_ref.current) {
            input_ref.current.focus();
        }
        setModalOpen(true);
    }
    return { input_ref: input_ref, onModalClose: onModalClose, onModalOpen: onModalOpen, modal_open: modal_open };
};
export default useModal;
