/**
 *Created by Mikael Lindahl on 2020-06-24
 */
"use strict";
import React, { useState } from "react";
import util from "../lib/util";
import Locales from "../constants/locales";
import url from "../constants/url.jsx";
import translationConfigTables from "../constants/translationConfigTables";
var debug = require('debug')('bk-manager:frontend-react:hooks:TableTranslation');
/**
 * Add missing/blank entries to translation
 *
 * @param translation {object} Current translation mapping
 * @param translation_edit {object} Translation edits to translation mapping
 * @returns {{changed_keys: *[], update: {}}}
 */
function addMissingEntries(_a) {
    var translation = _a.translation, translation_edit = _a.translation_edit;
    var locales = util.getLocales([translation], []);
    var update = {};
    var changed_keys = [];
    for (var key in translation) {
        for (var _i = 0, locales_1 = locales; _i < locales_1.length; _i++) {
            var locale = locales_1[_i];
            if (translation[key][locale] !== undefined) {
                continue;
            }
            if (!update[key]) {
                update[key] = {};
            }
            update[key][locale] = util.generateTranslationEdit({
                action: 'created',
                text_original: '',
                text: locale == 'key'
                    ? key
                    : (locale == 'eligible_for'
                        ? translationConfigTables.options.slice(-1)[0].value
                        : ''),
                is_created: true
            });
            changed_keys.push("".concat(key, ".").concat(locale));
        }
    }
    for (var key in translation_edit) {
        // Skip if key present in translation
        if (translation[key] !== undefined) {
            continue;
        }
        for (var _b = 0, locales_2 = locales; _b < locales_2.length; _b++) {
            var locale = locales_2[_b];
            if (translation_edit[key][locale] !== undefined) {
                continue;
            }
            if (!update[key]) {
                update[key] = {};
            }
            update[key][locale] = util.generateTranslationEdit({
                action: 'created',
                text_original: '',
                text: locale == 'key'
                    ? key
                    : (locale == 'eligible_for'
                        ? translationConfigTables.options.slice(-1)[0].value
                        : ''),
                is_created: true
            });
            changed_keys.push("".concat(key, ".").concat(locale));
        }
    }
    return { changed_keys: changed_keys, update: update };
}
/**
 * Hook for fixing entries missing locales. If missing then they are created.
 *
 * @param translation {object} Current translation mapping
 * @returns {{addHocUpdateMessage: *, getAddHocFixEnsureLocales: (function(*): *)}}
 */
function useAddHocFixEnsureLocales(_a) {
    var translation = _a.translation;
    var _b = useState(''), addHocUpdateMessage = _b[0], setAddHocUpdateMessage = _b[1];
    function getAddHocFixEnsureLocales(translation_edit) {
        // debug('getAddHocFixEnsureLocales')
        var _a = addMissingEntries({ translation: translation, translation_edit: translation_edit }), changed_keys = _a.changed_keys, update = _a.update;
        var message_locales = changed_keys.join(', ');
        if (changed_keys.length > 30) {
            message_locales = "".concat(changed_keys.slice(0, 20).join(', '), " ... ").concat(changed_keys.slice(20).length, " more entries");
        }
        var message = changed_keys.length != 0 ? "Add hoc fix. Missing locales ".concat(message_locales, ". Automatically created") : '';
        if (!util.isEmptyObject(update)) {
            for (var key in update) {
                if (!translation_edit[key]) {
                    translation_edit[key] = {};
                }
                for (var locale in update[key]) {
                    if (translation_edit[key][locale]) {
                        continue;
                    }
                    translation_edit[key][locale] = update[key][locale];
                }
            }
        }
        if (message != addHocUpdateMessage) {
            setAddHocUpdateMessage(message);
        }
        return translation_edit;
    }
    return { addHocUpdateMessage: addHocUpdateMessage, getAddHocFixEnsureLocales: getAddHocFixEnsureLocales };
}
/**
 * Hook for adding new key entry to translation table
 *
 * @param translation {object} Current translation mapping
 * @param translation_edit {object} Translation edits to translation mapping
 * @param updateTranslationEdit {function} Callback for updating translation edits
 * @returns {{setErrorAdd: *, addNewKey: addNewKey, errorAdd: *}}
 */
function useAddNewKey(_a) {
    var translation = _a.translation, translation_edit = _a.translation_edit, updateTranslationEdit = _a.updateTranslationEdit;
    var _b = useState(''), errorAdd = _b[0], setErrorAdd = _b[1];
    function addNewKey(_a) {
        var _b;
        var value = _a.value, eligibleFor = _a.eligibleFor;
        setErrorAdd('');
        var locales = util.getLocales([translation, translation_edit]);
        var is_deleted_column = {};
        for (var key in translation_edit) {
            for (var locale in translation_edit[key]) {
                is_deleted_column[locale] = is_deleted_column[locale] == undefined
                    ? translation_edit[key][locale].is_deleted_column
                    : translation_edit[key][locale].is_deleted_column && is_deleted_column[locale];
            }
        }
        if (value == '') {
            return setErrorAdd('Sorry empty key "" is not allowed');
        }
        if (eligibleFor == '') {
            return setErrorAdd('Sorry empty eligible for table is not allowed');
        }
        if (translation[value] || translation_edit[value]) {
            return setErrorAdd('Sorry key "' + value + '" already exists in translation table');
        }
        var update = (_b = {},
            _b[value] = {
                key: util.generateTranslationEdit({
                    action: 'created',
                    is_created: true,
                    text: value,
                    text_original: value
                }),
                eligible_for: util.generateTranslationEdit({
                    action: 'created',
                    is_created: true,
                    text: eligibleFor,
                    text_original: eligibleFor
                })
            },
            _b);
        for (var _i = 0, locales_3 = locales; _i < locales_3.length; _i++) {
            var locale = locales_3[_i];
            if (locale == 'key' || locale == 'eligible_for') {
                continue;
            }
            var entry = util.generateTranslationEdit({
                action: is_deleted_column[locale] ? 'deleted' : 'created',
                is_deleted_column: is_deleted_column[locale],
                is_created: true,
                text: '',
                text_original: '',
            });
            update[value][locale] = entry;
        }
        updateTranslationEdit({ update: update, reset: true });
    }
    return { errorAdd: errorAdd, addNewKey: addNewKey, setErrorAdd: setErrorAdd };
}
/**
 * Hook for adding new locale to translation table
 *
 * @param translation {object} Current translation mapping
 * @param translation_edit {object} Translation edits to translation mapping
 * @param updateTranslationEdit {function} Callback for updating translation edits
 * @returns {{errorAddLocale: *, setErrorAddLocale: *, addNewLocale: addNewLocale}}
 */
function useAddNewLocale(_a) {
    var translation = _a.translation, translation_edit = _a.translation_edit, updateTranslationEdit = _a.updateTranslationEdit;
    var _b = useState(null), errorAddLocale = _b[0], setErrorAddLocale = _b[1];
    function addNewLocale(_a) {
        var _b;
        var value = _a.value;
        var locale = value;
        setErrorAddLocale('');
        if (!Locales[locale]) {
            return setErrorAddLocale(React.createElement(React.Fragment, null,
                'Sorry locale "' + value + '" is not a ',
                React.createElement("a", { href: url.GLOBAL_CONFIG_VALID_LOCALES, target: '_blank' }, "valid"),
                ' locale'));
        }
        var update = {};
        for (var _i = 0, _c = [translation, translation_edit]; _i < _c.length; _i++) {
            var dic = _c[_i];
            for (var key in dic) {
                if (dic[key] && dic[key][locale]) {
                    return setErrorAddLocale('Sorry locale "' + value + '" already exists in translation table');
                }
                var is_deleted_row = true;
                for (var _locale in dic[key]) {
                    is_deleted_row = is_deleted_row && dic[key][_locale].is_deleted_row;
                }
                var entry = util.generateTranslationEdit({
                    action: is_deleted_row ? 'deleted' : 'created',
                    is_deleted_row: is_deleted_row,
                    is_created: true,
                    text: '',
                    text_original: '',
                });
                update[key] = (_b = {},
                    _b[locale] = entry,
                    _b);
            }
        }
        updateTranslationEdit({ update: update, reset: true });
    }
    return { errorAddLocale: errorAddLocale, addNewLocale: addNewLocale, setErrorAddLocale: setErrorAddLocale };
}
export { useAddHocFixEnsureLocales, useAddNewKey, useAddNewLocale,
// useSubmit,
// useUpdateTranslationEdit
 };
