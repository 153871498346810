/**
 *Created by Mikael Lindahl on 2020-06-24
 */
"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import url from "../constants/url.jsx";
var debug = require('debug')('bk-manager:frontend-react:hooks:ConfigEventView');
/**
 * Hook for restore customer
 *
 * @param description {string} Event description for restore customer event
 * @param onModalClose {function} Callback for closing modal
 * @param setDescription {function} Set restore event description
 * @param setError {function} Set error
 * @param setEventUid {function} Set restore event uid
 * @param setSteps {function1} Set restore event steps
 * @returns {{onSubmitRestoreCustomer: ((function({uid: *, company_name: *, users: *}): Promise<*|undefined>)|*)}}
 */
function useRestoreCustomer(_a) {
    var description = _a.description, onModalClose = _a.onModalClose, setDescription = _a.setDescription, setError = _a.setError, setEventUid = _a.setEventUid, setSteps = _a.setSteps;
    /**
     * On submit restore customer
     *
     * @param uid {string} Company uid
     * @param company_name {string} Company name
     * @param users {array<object>} List of company users
     * @returns {Promise<*>}
     */
    function onSubmitRestoreCustomer(_a) {
        var uid = _a.uid, company_name = _a.company_name, users = _a.users;
        return __awaiter(this, void 0, void 0, function () {
            var body, body_text, method, response, responseJson;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        onModalClose();
                        setError('');
                        setSteps([]);
                        body = {
                            company_name: company_name,
                            description: description,
                            uid: uid,
                            users: users
                        };
                        body_text = JSON.stringify(body);
                        method = 'PUT';
                        return [4 /*yield*/, fetch(location.origin + url.CUSTOMER_RESTORE, { method: method, body: body_text })];
                    case 1:
                        response = _b.sent();
                        if (response.status !== 200) {
                            console.error(response);
                            setError('Ops unexpected error happen');
                        }
                        return [4 /*yield*/, response.json()];
                    case 2:
                        responseJson = _b.sent();
                        if (responseJson.error) {
                            return [2 /*return*/, setError(responseJson.error)];
                        }
                        setSteps(responseJson.steps || []);
                        setDescription(description);
                        setEventUid(responseJson.event_uid);
                        return [2 /*return*/];
                }
            });
        });
    }
    return { onSubmitRestoreCustomer: onSubmitRestoreCustomer };
}
export default useRestoreCustomer;
