/**
 *Created by Mikael Lindahl on 2019-10-29
 */
"use strict";
import React, { useEffect, useState } from "react";
var debug = require('debug')('bk-manager:frontend-react:components:InputText');
/**
 * Input text
 *
 * @param props {object} Input text properties
 * @returns {*}
 * @constructor
 */
export default function InputText(props) {
    var _a = useState(props.value), value = _a[0], setValue = _a[1];
    useEffect(function () {
        setValue(props.value);
    }, [props.value]);
    return (React.createElement("input", { type: 'text', onChange: function (event) { return setValue(event.target.value); }, disabled: props.disabled, ref: props.input_ref, onFocus: props.onFocus || (function () {
        }), onBlur: function (e) {
            props.onBlur ? props.onBlur({ value: value }) : function () {
            };
        }, onKeyDown: function (e) {
            if (props.onEnter && e.keyCode == 13) {
                props.onEnter({ value: value });
                if (props.clearOnEnter) {
                    setValue('');
                }
            }
        }, value: value, placeholder: props.placeholder }));
}
