var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from "react";
var overtimeTypes = [
    {
        priority: 1,
        timeType: "ÖT 1 - 30%",
        timeTypeFlex: "ÖT 1 - spara 30%"
    },
    {
        priority: 2,
        timeType: "ÖT 2 - 50%",
        timeTypeFlex: "ÖT 2 - spara 50%",
    },
    {
        priority: 3,
        timeType: "ÖT 3 - 70%",
        timeTypeFlex: "ÖT 3 - spara 70%",
    },
    {
        priority: 4,
        timeType: "ÖT 4 - 100%",
        timeTypeFlex: "ÖT 4 - spara 100%",
    },
];
var InputTextList = /** @class */ (function (_super) {
    __extends(InputTextList, _super);
    function InputTextList(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            start: '',
            end: '',
            type: 1
        };
        _this.fileInput = React.createRef();
        _this.onAdd = _this.onAdd.bind(_this);
        _this.onDelete = _this.onDelete.bind(_this);
        _this.onChangeFile = _this.onChangeFile.bind(_this);
        _this.getEdit = _this.getEdit.bind(_this);
        return _this;
    }
    /**
     * On change file load file data and add to list
     *
     * @param event {object} Input change event
     */
    InputTextList.prototype.onChangeFile = function (event) {
        var _this = this;
        event.preventDefault();
        var file = this.fileInput.current.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            var data = reader.result;
            var rules = JSON.parse(data);
            if (rules) {
                _this.props.onChange(rules);
            }
        };
        reader.onerror = function (e) {
            alert('Something went wrong. Please check format of text file.');
        };
        reader.readAsText(file);
    };
    /**
     * Add text to list
     */
    InputTextList.prototype.onAdd = function () {
        var _this = this;
        var timeReg = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
        if (!this.state.start.match(timeReg) ||
            !this.state.end.match(timeReg)) {
            alert("Input time should be in format: HH:mm");
            return;
        }
        var rules = this.props.overtimeRules || [];
        var type = overtimeTypes.find(function (e) { return e.priority == _this.state.type; });
        if (type) {
            var newRule = __assign({ start: this.state.start, end: this.state.end }, type);
            rules.push(newRule);
            this.props.onChange(rules);
        }
        this.setState({ start: '', end: '' });
    };
    /**
     * Delete text from list
     *
     * @param text {string} Text in list to remove
     */
    InputTextList.prototype.onDelete = function (index) {
        var overtimeRules = this.props.overtimeRules;
        overtimeRules.splice(index, 1);
        this.props.onChange(overtimeRules);
    };
    /**
     * Get element for show text in list
     *
     * @param text {string} Text to show
     * @returns {JSX.Element}
     */
    InputTextList.prototype.getEntry = function (index, text) {
        var _this = this;
        return (React.createElement("div", { key: text, className: 'quick-text d-flex-flex-row mx-1' },
            React.createElement("div", null,
                text,
                !this.props.disabled && React.createElement("i", { className: 'delete fas fa-times-circle ml-2', onClick: function () { return _this.onDelete(index); } }))));
    };
    /**
     * Render text edit input
     *
     * @returns {JSX.Element}
     */
    InputTextList.prototype.getEdit = function () {
        var _this = this;
        return (React.createElement("div", null,
            !this.props.exclude_file_upload && React.createElement(React.Fragment, null,
                React.createElement("input", { type: "file", ref: this.fileInput, disabled: this.props.disabled, onChange: this.onChangeFile, className: "form-control" }),
                React.createElement("small", { className: "form-text text-muted mb-4" }, this.props.help_file_upload || 'Choose file to upload overtime rules. Must be a json')),
            React.createElement("div", { className: 'd-flex flex-row' },
                React.createElement("input", { type: "text", value: this.state.start, disabled: this.props.disabled, onChange: function (e) { return _this.setState({ start: e.target.value }); }, className: "form-control", style: { width: 200 }, placeholder: this.props.placeholder || 'Start time HH:mm' }),
                React.createElement("input", { type: "text", value: this.state.end, disabled: this.props.disabled, onChange: function (e) { return _this.setState({ end: e.target.value }); }, style: { width: 200 }, className: "form-control", placeholder: this.props.placeholder || 'End time HH:mm' }),
                React.createElement("div", { style: { marginLeft: 5 } },
                    React.createElement("select", { className: "input-select custom-select", disabled: this.props.disabled, onChange: function (e) {
                            _this.setState({ type: parseInt(e.target.value) });
                        } }, overtimeTypes.map(function (o) {
                        return React.createElement("option", { key: o.priority, value: o.priority }, o.timeType);
                    }))),
                React.createElement("button", { className: 'btn btn-primary', onClick: this.onAdd }, "Add")),
            React.createElement("small", { className: "form-text text-muted mb-4" }, this.props.help || 'Add rule manually. Input start time and end time. End time needs to be later than start time. Time frames need to be sorted in order.')));
    };
    InputTextList.prototype.render = function () {
        var _this = this;
        var overtimeRules = this.props.overtimeRules;
        overtimeRules = overtimeRules || [];
        return (React.createElement("div", { className: "input-quick-text form-group d-flex flex-column align-items-start" },
            this.props.label && React.createElement("label", null, this.props.label),
            !this.props.disabled && this.getEdit(),
            React.createElement("div", { className: 'quick-text-list d-flex flex-wrap' }, overtimeRules.map(function (rule, index) { return _this.getEntry(index, "".concat(rule.start, ":").concat(rule.end, " (").concat(rule.timeType, ")")); }))));
    };
    return InputTextList;
}(Component));
export default InputTextList;
