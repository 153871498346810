/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./ButtonWithTooltip.scss";
/**
 * Create a button with tooltip
 *
 * @param props {object} Button with tooltip properties
 * @returns {*}
 * @constructor
 */
export default function ButtonWithTooltip(props) {
    return (React.createElement(OverlayTrigger, { key: 'bottom', placement: 'bottom', overlay: React.createElement(Tooltip, { id: "tooltip-bottom" }, props.tooltip || 'Clear local edits') },
        React.createElement(Button, { variant: props.variant || "secondary", className: props.className + ' button-with-tooltip', onClick: props.onClick }, props.label || 'Reset')));
}
