/**
 *Created by Mikael Lindahl on 2019-10-08
 */
"use strict";
import React from "react";
import './InputTextSimpleView.scss';
import PropTypes from "prop-types";
import InputText from "./InputText";
/**
 * Render text input for simple view with label and help text
 */
export default function InputTextSimpleView(props) {
    return (React.createElement("div", { className: "input-text form-group" },
        props.label && React.createElement("label", null, props.label),
        props.help && React.createElement("small", { className: "form-text text-muted mb-2" }, props.help),
        React.createElement("div", { className: 'd-flex flex-row' },
            React.createElement(InputText, { disabled: props.disabled, value: props.value, onBlur: props.onChange, placeholder: props.placeholder }),
            props.required && React.createElement("small", { className: "form-text text-danger mb-2 ml-2" }, 'required'))));
}
InputTextSimpleView.propTypes = {
    disabled: PropTypes.bool,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
