/**
 *Created by Mikael Lindahl on 2019-10-30
 */
"use strict";
import React from "react";
import "./Issue.scss";
var debug = require('debug')('bk-manager:frontend-react:components:Issue');
/**
 *
 * Render an issue
 *
 * @param props {object} Issue properties
 * @returns {*}
 * @constructor
 */
export default function Issue(props) {
    var issue = props.issue, index = props.index;
    issue.message = issue.message.split(',').join(', ');
    switch (issue.type) {
        case 'conflict':
            return (React.createElement("div", { className: "alert alert-danger", role: "alert" },
                React.createElement("b", null, "".concat(index, ". ").concat(issue.type.toUpperCase(), " ")),
                React.createElement("br", null),
                issue.message.split('config.').join('')));
        case 'warning':
            return (React.createElement("div", { className: "alert alert-warning", role: "alert" },
                React.createElement("b", null, "".concat(index, ". ").concat(issue.type.toUpperCase(), " ")),
                React.createElement("br", null),
                issue.message.split('config.').join('')));
    }
}
