import React, { useState } from "react";
import util from "../../lib/util";
import './InputSelectCustomer.scss';
var debug = require('debug')('bk-manager:frontend-react:components:InputSelectCustomer');
/**
 * Render a select list with customers
 *
 * @param props {object} Input select customer properties
 * @returns {*}
 * @constructor
 */
export default function InputSelectCustomer(props) {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), error = _b[0], setError = _b[1];
    var getFirst = function (props) {
        return props.type == 'create'
            ? [React.createElement("option", { key: 'empty', value: '' }, ''), React.createElement("option", { key: 'default', value: 'default' }, 'Default')]
            : React.createElement("option", { key: 'empty', value: '' }, '');
    };
    return (React.createElement("div", { className: 'row ml-2' },
        React.createElement("select", { value: util.getUid(), className: "select-customer custom-select", onChange: function (event) {
                setLoading(true);
                setError(false);
                props.onSelect({ uid: event.target.value, setLoading: setLoading, setError: setError });
            } },
            getFirst(props),
            props.options.map(function (o) {
                return React.createElement("option", { key: o.value, value: o.value }, "".concat(o.label, " (").concat(o.value, ")"));
            })),
        loading && React.createElement("div", { className: 'ml-3 mt-2 pt-2' }, 'Loading ...'),
        error &&
            React.createElement("div", { className: 'ml-3 mt-2 pt-2 text-danger' }, 'Failed to fetch data. Is firestore working?')));
}
