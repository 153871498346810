/**
 *Created by Mikael Lindahl on 2019-10-21
 */

"use strict";

/**
 *
 * Return difference between two lists
 *
 * - `l1` {array} first list
 * - `l2` {array} second list
 *
 * @returns {object} Left and right difference
 *
 */
function difference(l1, l2) {

    let left = [];
    let right = [];

    l1.forEach(e => {

        if (l2.indexOf(e) == -1) {
            left.push(e)
        }

    })

    l2.forEach(e => {

        if (l1.indexOf(e) == -1) {
            right.push(e)
        }

    });

    return {left, right, only_in_first:left, only_in_second:right}
}

/**
 *
 * Get nested value object
 *
 * - `nestedObj` {object} the nested object
 * - `pathString` {string} path to value as path.to.value.
 *                Gives 1 for obj={path:{to:{value:1}}}
 *
 * @returns {any} Value
 *
 */
const getNestedObject = (nestedObj, pathString) => {

    return pathString.split('.').reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
};

/**
 *
 * Sort an array
 *
 * - `list` {array} list to sort
 * - `key` {function} if provides should return value to be sorted on.
 *         Acts on each element in list to be sorted
 * - `direction` {string} desc | asc
 *
 * @returns {array} Return sorted list
 *
 */
function sort(list, key, direction) {

    let i = direction == 'asc' ? -1 : 1;

    key = key ? key : e => e;

    list.sort((a, b) => {

        a = key(a);
        b = key(b);

        if (a > b) {

            return 1 * i

        } else if (a < b) {

            return -1 * i

        } else {

            return 0

        }
    })

    return list
}

module.exports = {
    difference,
    getNestedObject,
    sort
};