/**
 *Created by Mikael Lindahl on 2019-10-21
 */

"use strict";

const _c = require('./constant');
const schema = require('./schema');
const util = require('./util');
const debug = require('debug')('bk-manager:frontend-react:local_modules:config-validation:lib:validation');

const {
    difference,
    getNestedObject
} = require('./util');

/**
 *
 * Check conflict for ending salary day
 *
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 *
 */
function conflictCheckEndingSalaryDay(config, error) {

    const x = config.mobile.startingSalaryDay;

    const endingSalaryDay = x - 1;


    if (endingSalaryDay !== config.mobile.endingSalaryDay) {

        error.push({
            type: 'conflict',
            key: 'config.mobile.endingSalaryDay',
            data: endingSalaryDay,
            message: (`config.mobile.endingSalaryDay ( ${config.mobile.endingSalaryDay} )`
                + ` should equal ${endingSalaryDay}`)
        })
    }
}

/**
 *
 * Check conflict for values that should be equal
 *
 * - `first` {boolean/number/string} first value
 * - `second` {boolean/number/string} second value
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 * - `options` {object} additional options
 *
 */
function conflictCheckEqualityTypes(first, second, config, error, options) {
    /**
     If options.translation exist then it is assumed
     that first key is key values and second key is
     in swedish
     */

    const addError = (first, second, only_in_first) => {

        only_in_first = util.sort(only_in_first);

        if (options && options.translation) {

            first.vals = only_in_first.map(r => options.translation[r]
                ? options.translation[r][first.translation]
                : `Missing translation for "${r}"`);
            second.vals = only_in_first.map(r => options.translation[r]
                ? options.translation[r][second.translation]
                : `Missing translation for "${r}"`);

        } else {

            first.vals = only_in_first;
            second.vals = only_in_first;
        }

        let msg = `${second.key} is missing ${JSON.stringify(second.vals)}`
            + ` since ${JSON.stringify(first.vals)} is included in ${first.key}. `
            + `If the list concerns a time type, absence type or driving type (section 1.1, 1.2 or 1.3) `
            + `then this error could probably be mitigated by going to the simple view and press `
            + `the button that says "Synchronize" or by toggle the appropriate checkbox in grid view.`;

        return {
            type: 'conflict',
            key: second.key,
            data: {first, second},
            message: (msg)
        }
    }

    let first_value = getNestedObject(config, first.key);
    let second_value = getNestedObject(config, second.key);

    if (!first_value || !second_value) {
        return
    }

    // Enable sv to key
    if (options && options.translation) {

        for (let key in options.translation) {

            let val = options.translation[key]
            options.translation[val.sv] = val;

        }
    }

    try {

        let first_sv = first.translation == 'key'
            ? first_value.reduce((tot, m) => {

                if (options.translation[m]) {
                    tot.push(options.translation[m].sv)
                }

                return tot
            }, [])
            : first_value;

        let second_sv = second.translation == 'key'
            ? second_value.reduce((tot, m) => {

                if (options.translation[m]) {
                    tot.push(options.translation[m].sv)
                }

                return tot
            }, [])
            : second_value;


        let {only_in_first, only_in_second} = difference(first_sv, second_sv);


        if (only_in_first.length != 0) {

            // d.left = util.sort(d.left);
            error.push(addError(first, second, only_in_first))

        }

        if (only_in_second.length != 0) {

            error.push(addError(second, first, only_in_second))

        }
    } catch (e) {
        console.error(e)
        throw e
    }

}

/**
 *
 * Check conflict for traktamente
 *
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 *
 */
function conflictCheckTraktamente(config, error) {

    if (config.web.travel.traktamenteOne
        && config.web.travel.traktamenteTwo) {

        error.push({
            type: 'conflict',
            key: 'web.travel.traktamenteOne',
            data: true,
            message: (`web.travel.traktamenteOne and web.travel.traktamenteTwo can not both be true at the same time`)
        })
    }
}

/**
 *
 * Check conflict for hazard
 *
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 *
 */
function conflictCheckHazard(config, error) {

    if (config.mobile.projectMenu.hazard1
        && config.mobile.projectMenu.hazard2) {

        error.push({
            type: 'conflict',
            key: 'mobile.projectMenu.hazard1',
            data: true,
            message: (`mobile.projectMenu.hazard1 and mobile.projectMenu.hazard2 can not both be true at the same time`)
        })
    }
    if (config.mobile.projectMenu.hazard1
        && config.web.hazardName != 'AJ! OJ!') {

        error.push({
            type: 'conflict',
            key: 'mobile.projectMenu.hazard1',
            data: true,
            message: (`If mobile.projectMenu.hazard1 is true then config.web.hazardName should equal "AJ! OJ!"`)
        })
    }

    if (config.mobile.projectMenu.hazard2
        && config.web.hazardName != 'Tillbud') {

        error.push({
            type: 'conflict',
            key: 'mobile.projectMenu.hazard1',
            data: true,
            message: (`If mobile.projectMenu.hazard2 is true then config.web.hazardName should equal "Tillbud"`)
        })
    }

    if (!config.mobile.projectMenu.hazard1
        && !config.mobile.projectMenu.hazard2
        && config.web.hazardName != '') {

        error.push({
            type: 'conflict',
            key: 'mobile.projectMenu.hazard1',
            data: true,
            message: (`If mobile.projectMenu.hazard1 and mobile.projectMenu.hazard1 then config.web.hazardName should equal ""`)
        })
    }
}

/**
 *
 * Check warning for reporting
 *
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 *
 */
function warningReporting(config, error) {

    if (config.backend.supervisorReporting && config.mobile.clientType != 1) {

        error.push({
            type: 'warning',
            key: 'config.mobile.clientType',
            data: config.mobile.clientType,
            message: (`config.backend.supervisorReporting is true which means that supervisor reporting is turned on.
             Be advised that it is recommended that config.mobile.clientType in this case equal 1 (Karlsson). 
             Now it equal ${config.mobile.clientType}`)
        })
    }

    if (!config.backend.supervisorReporting && config.mobile.clientType == 1) {

        error.push({
            type: 'warning',
            key: 'config.mobile.clientType',
            data: config.mobile.clientType,
            message: (`config.backend.supervisorReporting is false which means that supervisor reporting is turned off.
             Be advised that it is recommended that config.mobile.clientType in this does not equal 1 (Karlsson). 
             Recommended to set it to 2 (Karling)`)
        })
    }
}

/**
 *
 * Check conflict for allow time reporting
 *
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 *
 */
function conflictCheckAllowTimeReporting(config, error) {

    if (config.web.time.allowTimeReporting["non-supervisor"]
        && config.backend.supervisorReporting) {

        error.push({
            type: 'conflict',
            key: 'web.time.allowTimeReporting.non-supervisor',
            data: true,
            message: (`web.time.allowTimeReporting.non-supervisor should be ` +
                `${!config.web.time.allowTimeReporting["non-supervisor"]} since config.backend.supervisorReporting ` +
                `equals ${config.backend.supervisorReporting["non-supervisor"]}`)
        })
    }
}

/**
 *
 * Check warning for absence salary instruction
 *
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 *
 */
function warningAbsenceSalaryInstruction(config, error) {


    let companies = config.backend.salarySystem.companies;

    if (!companies) {
        return
    }


    for (let i = 0; i < companies.length; i++) {

        let company = companies[i]

        if(!company.instructions){continue}

        for (let j = 0; j < company.instructions.length; j++) {

            let instruction = company.instructions[j]

            if (config.backend.salarySystem.type != 'kontek'
                && instruction.absence_salary_code) {

                let key = `backend.salarySystem.companies.${i}.instructions.${j}.absence_salary_code`

                error.push({
                    type: 'warning',
                    key,
                    data: true,
                    message: (`${key} (${instruction.data_type}) is true but the company does not have kontek as salary system. Please make sure this is correct`)
                })
            }
        }
    }
}


/**
 *
 * Check warning for inconsistencies supervisor reporting
 *
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 *
 */
function warningInconsistenciesAtSupervisorReporting(config, error) {

    if (config.mobile.projectMenu.drivingExpense == !config.backend.supervisorReporting) {

        error.push({
            type: 'warning',
            key: 'mobile.projectMenu.drivingExpense',
            data: true,
            message: (`mobile.projectMenu.drivingExpense is ${config.mobile.projectMenu.drivingExpense} ` +
                `while config.backend.supervisorReporting is ${config.backend.supervisorReporting}. It is ` +
                `recommended to set it to ${!config.mobile.projectMenu.drivingExpense}`)
        })

    }

    if ((config.web.diary.allowEveningTemp === false && config.mobile.clientType === 1)
        || (config.web.diary.allowEveningTemp === true && config.mobile.clientType !== 1)) {

        error.push({
            type: 'warning',
            key: 'config.web.diary.allowEveningTemp',
            data: true,
            message: (`web.diary.allowEveningTemp is ${config.web.diary.allowEveningTemp} ` +
                `while config.mobile.clientType is ${config.mobile.clientType}. It is ` +
                `recommended to set it to ${!config.web.diary.allowEveningTemp} then since this feature currently is governed by ` +
                `config.mobile.clientType in mobile`)
        })
    }
}

/**
 *
 * Check warning for subcompanies
 *
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 *
 */
function warningSubcompanies(config, error) {

    if (config.web.active_modules.settings.subcompanies) {

        error.push({
            type: 'warning',
            key: 'web.active_modules.settings.subcompanies',
            data: true,
            message: (`web.active_modules.settings.subcompanies is true which means that several additional manual steps is needed for it to work`)
        })
    }
}

/**
 *
 * Check warning for not supporting new salary configuration
 *
 * - `config` {object} byggkollen config object
 * - `error` {array} validation error array
 *
 */
function warningLegacyValboAndUme(config, error) {


    let name = config.backend.company;
    let legacy = config.backend.salarySystem.legacy;

    if (!legacy && ['valbo', 'ume'].includes(name)) {

        let key = 'config.backend.salarySystem.legacy'

        error.push({
            type: 'warning',
            key,
            data: true,
            message: (`${key} should be true since ${name} does not support the new salary configuration. Please set to true`)
        })
    }
}


/**
 *
 * Ensure color is uppercase
 *
 * - `config` {object} byggkollen config object
 *
 */
function ensureColorsUpperCase(config) {

    config.mobile.primaryColor = config.mobile.primaryColor && config.mobile.primaryColor.toUpperCase();
    config.mobile.accentColor = config.mobile.accentColor && config.mobile.accentColor.toUpperCase();

    if (config.web.theme) {
        config.web.theme.primaryColor = config.web.theme.primaryColor && config.web.theme.primaryColor.toUpperCase();
        config.web.theme.secondaryColor = config.web.theme.secondaryColor && config.web.theme.secondaryColor.toUpperCase();
        config.web.theme.datepickerColor = config.web.theme.datepickerColor && config.web.theme.datepickerColor.toUpperCase();
        config.web.theme.datepickerSndColor = config.web.theme.datepickerSndColor && config.web.theme.datepickerSndColor.toUpperCase();
        config.web.theme.navTextColor = config.web.theme.navTextColor && config.web.theme.navTextColor.toUpperCase();
    }
}

/**
 *
 * Parse an joi schema validation error message details for a config
 *
 * - `details` {array} joi error message details
 * - `config` {string} byggkollen config
 *
 * @returns {array} Array with parsed error objects
 *
 */
function parseErrorMessage(details, config) {
    let error = [];

    for (let d of details) {

        let msg = d.message.split(' ').slice(1).join(' ');
        let divider = 'must be [ref:global:';
        let key = d.path.join('.')
        let data = {
            first: util.getNestedObject(config, key)
        };

        let message = ''
        if (msg.includes(divider)) {

            msg = msg.split(divider);
            let second = msg[1].replace('config.', '').replace(']', '')

            second = util.getNestedObject(config, second)

            msg = msg.join('should equal config.');
            msg = msg.slice(0, msg.length - 1);
            data.second = second

            message = `config.${d.path.join('.')} (${toString(data.first)}) ${msg} (${toString(data.second)})`

        } else {
            message = `config.${d.path.join('.')} ${msg}`

        }

        for (let setup of _c.validate_schema_note) {

            if (setup.keys.indexOf(key) != -1) {

                message += setup.note;
            }
        }

        error.push({
            type: 'conflict',
            key,
            data,
            message

        })
    }

    return error
}

function toString(data) {

    if (typeof data == 'object') {
        return JSON.stringify(data)
    }
    if (data === '') {
        return "''"
    } else {
        return String(data)
    }
}

/**
 *
 * Perform config validation
 *
 * - `config` {object} byggkollen config object
 * - `translation` {object} byggkollen translation object
 *
 */
module.exports = function (config, translation) {

    if (!translation) {
        throw "Missing translation"
    }

    ensureColorsUpperCase(config);

    let out = schema.config.validate(config, {abortEarly: false, context: config});

    let error = out.error ? parseErrorMessage(out.error.details, config) : [];

    conflictCheckEndingSalaryDay(config, error);
    const params = [config, error];

    // Absence time types
    conflictCheckEqualityTypes(
        {key: 'backend.absenceTimeTypes', translation: 'sv'},
        {key: 'web.absenceTimeTypes', translation: 'sv'},
        ...params
    );

    // Absence time types
    conflictCheckEqualityTypes(
        {key: 'backend.absenceTimeTypes', translation: 'sv'},
        {key: 'mobile.absenceTimeTypes', translation: 'key'},
        ...params, {translation}
    );

    // Project running user
    conflictCheckEqualityTypes(
        {key: 'mobile.remoteTimeTypes.projectRunning', translation: 'key'},
        {key: 'web.time.timeTypes.timlön.non-supervisor', translation: 'sv'},
        ...params, {translation}
    );

    // Project running supervisor
    conflictCheckEqualityTypes(
        {key: 'mobile.remoteTimeTypes.projectRunningLagbas', translation: 'key'},
        {key: 'web.time.timeTypes.timlön.supervisor', translation: 'sv'},
        ...params, {translation}
    );

    // Project fixed user
    conflictCheckEqualityTypes(
        {key: 'mobile.remoteTimeTypes.projectFixed', translation: 'key'},
        {key: 'web.time.timeTypes.ackord.non-supervisor', translation: 'sv'},
        ...params, {translation}
    );

    // Project fixed supervisor
    conflictCheckEqualityTypes(
        {key: 'web.time.timeTypes.ackord.supervisor', translation: 'sv'},
        {key: 'mobile.remoteTimeTypes.projectFixedLagbas', translation: 'key'},
        ...params, {translation}
    );

    // Project types included in ackord
    conflictCheckEqualityTypes(
        {key: 'backend.decidedHoursNegative', translation: 'sv'},
        {key: 'web.remainingHoursTimeTypes', translation: 'sv'},
        ...params
    );

    conflictCheckEqualityTypes(
        {key: 'web.invoicing.fastpris.timeTypes', translation: 'sv'},
        {key: 'web.invoicing.löpanderäkning.timeTypes', translation: 'sv'},
        ...params
    );

    conflictCheckEqualityTypes(
        {key: 'web.invoicing.fastpris.timeTypes', translation: 'sv'},
        {key: 'web.invoicing.service.timeTypes', translation: 'sv'},
        ...params
    );

    // Sub supplier
    conflictCheckEqualityTypes(
        {key: 'web.time.subSupplierTimeTypes', translation: 'sv'},
        {key: 'mobile.remoteTimeTypes.subSupplier', translation: 'key'},
        ...params, {translation}
    );

    // Sub supplier
    conflictCheckEqualityTypes(
        {key: 'web.time.subSupplierTimeTypes', translation: 'sv'},
        {key: 'mobile.timeTypes.subSupplier', translation: 'key'},
        ...params, {translation}
    );

    // Traveling project
    conflictCheckEqualityTypes(
        // {key: 'mobile.drivingTypes.project', translation: 'key'},
        {key: 'mobile.remoteDrivingTypes.project', translation: 'key'},
        {key: 'web.travel.project.drivingTypes', translation: 'sv'},
        ...params, {translation}
    );

    // Traveling service
    conflictCheckEqualityTypes(
        // {key: 'mobile.drivingTypes.serviceorder', translation: 'key'},
        {key: 'mobile.remoteDrivingTypes.serviceorder', translation: 'key'},
        {key: 'web.travel.service.drivingTypes', translation: 'sv'},
        ...params, {translation}
    );

    // Traveling invoice
    conflictCheckEqualityTypes(
        {key: 'web.invoicing.löpanderäkning.drivingTypes', translation: 'sv'},
        {key: 'web.invoicing.fastpris.drivingTypes', translation: 'sv'},
        ...params
    );


    conflictCheckHazard(...params);
    conflictCheckTraktamente(...params);
    warningAbsenceSalaryInstruction(...params)
    warningLegacyValboAndUme(...params)
    warningSubcompanies(...params);
    warningReporting(...params);

    conflictCheckAllowTimeReporting(...params);
    warningInconsistenciesAtSupervisorReporting(...params);

    return {error, value: out.value}
};