import queryString from "query-string";
var debug = require('debug')('bk-manager:frontend-react:lib:util');
/**
 * Generate a unique client-side identifier.
 * Used for the creation of new documents.
 *
 * @returns {string} A unique 20-character wide identifier.
 */
function autoId() {
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var autoId = '';
    for (var i = 0; i < 20; i++) {
        autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return autoId;
}
/**
 * Clone object
 *
 * @param data {object} Data to clone
 * @returns {any}
 */
function clone(data) {
    try {
        return JSON.parse(JSON.stringify(data));
    }
    catch (err) {
        console.error('data', data);
        throw err;
    }
}
/**
 * Compare lists or values
 *
 * @param current {any} Data current
 * @param value {any} Data to compare
 * @returns {boolean}
 */
function compare(current, value) {
    current = typeof current == 'object' ? JSON.stringify(current) : current;
    value = typeof value == 'object' ? JSON.stringify(value) : value;
    return current == value;
}
/**
 * Delete a key value pairing in a nested object
 *
 * @param obj {object} Object to delete nested key at
 * @param path {string|array<string>} Nested key "." (dot) separated or list with keys
 */
function deleteNestedObject(obj, path) {
    if (!obj || !path) {
        return;
    }
    var path_list;
    if (typeof path === 'string') {
        path_list = path.split('.');
    }
    for (var i = 0; i < path.length - 1; i++) {
        obj = obj[path_list[i]];
        if (typeof obj === 'undefined') {
            return;
        }
    }
    delete obj[path_list.pop()];
}
/**
 * Convert dic to list
 *
 * @param dic {object} Dictionary to convert
 * @param contain {string} If provided on dic entries that contains the string in contain
 * @param transform {function} Transform the object with this function
 * @returns {*[]}
 */
function dicToList(dic, contain, transform) {
    var list = [];
    for (var key in dic) {
        if (contain) {
            var re = new RegExp(contain);
            if (key.match(re).length == 0) {
                continue;
            }
        }
        var entry = void 0;
        try {
            entry = clone(dic[key]);
        }
        catch (err) {
            console.error('key', key);
            throw err;
        }
        if (transform) {
            entry = transform({ key: key, value: entry });
        }
        list.push(entry);
    }
    return list;
}
/**
 * Update translation edit object
 *
 * @param action {string} created | edited
 * @param [is_created] {boolean} If this is creation event
 * @param [is_deleted_column] {boolean} If column has been deleted
 * @param [is_deleted_row] {boolean} If row has been deleted
 * @param text {string} New translation text
 * @param text_original {string} Original text
 * @returns {{is_deleted_row: boolean, text_original, is_created: boolean, action, className: string, is_deleted_column: boolean, text}}
 */
function generateTranslationEdit(_c) {
    var action = _c.action, _d = _c.is_created, is_created = _d === void 0 ? false : _d, _e = _c.is_deleted_column, is_deleted_column = _e === void 0 ? false : _e, _f = _c.is_deleted_row, is_deleted_row = _f === void 0 ? false : _f, text = _c.text, text_original = _c.text_original;
    var entry = {
        action: action,
        className: "translation-".concat(action),
        is_created: is_created,
        is_deleted_column: is_deleted_column,
        is_deleted_row: is_deleted_row,
        text: text,
        text_original: text_original,
    };
    return entry;
}
/**
 * Get salary instruction key
 *
 * @param instruction {object} Salary instruction
 * @returns {*}
 */
function getInstructionKey(instruction) {
    return instruction.data_type + instruction.employee_type;
}
/**
 * Get all keys from list of objects
 *
 * @param datasets {array<object>} List with object
 * @param exclude {array<string>} List with keys to exclude
 * @returns {*|*[]}
 */
function getKeys(datasets, exclude) {
    if (exclude === void 0) { exclude = []; }
    var keys = [];
    for (var _i = 0, datasets_1 = datasets; _i < datasets_1.length; _i++) {
        var data = datasets_1[_i];
        for (var key in data) {
            if (keys.includes(key) || exclude.includes(key)) {
                continue;
            }
            keys.push(key);
        }
    }
    keys = sort(keys);
    return keys;
}
/**
 * Get all locales keys from list of objects
 *
 * @param datasets {array<object>} List with object
 * @param exclude {array<string>} List with keys to exclude
 * @returns {*|*[]}
 */
function getLocales(datasets, exclude) {
    if (exclude === void 0) { exclude = ['eligible_for']; }
    var locales = [];
    for (var _i = 0, datasets_2 = datasets; _i < datasets_2.length; _i++) {
        var data = datasets_2[_i];
        for (var key in data) {
            for (var locale in data[key]) {
                if (locales.includes(locale) || exclude.includes(locale)) {
                    continue;
                }
                locales.push(locale);
            }
        }
    }
    locales = sort(locales);
    return locales;
}
/**
 * Set key value pairing in nested object
 *
 * @param nestedObj {object} Nested object
 * @param pathString {string} Dot separated path to value in nested array
 * @returns {*}
 */
var getNestedObject = function (nestedObj, pathString) {
    return pathString.split('.').reduce(function (obj, key) {
        return (obj && obj[key] !== 'undefined') ? obj[key] : undefined;
    }, nestedObj);
};
/**
 * Get url query
 *
 * @returns {number | any}
 */
function getQuery() {
    return queryString.parse(location.search);
}
/**
 * Get url tip
 *
 * @returns {string}
 */
function getTip() {
    return location.pathname.split('/')[location.pathname.split('/').length - 1];
}
/**
 * Get uid from url query
 *
 * @returns {string}
 */
function getUid() {
    var query = queryString.parse(location.search);
    return query.uid || '';
}
/**
 * Format uid as query
 *
 * @returns {string}
 */
function getUidAsQuery() {
    var uid = getUid();
    return uid != '' ? "?uid=".concat(uid) : '';
}
/**
 * Get translation object
 *
 * @param key {string} Translation key
 * @param locale {string} Translation locale for the key
 * @param translation {object} Translation edit mapping
 * @returns {null|*}
 */
function getTranslation(_c) {
    var key = _c.key, locale = _c.locale, translation = _c.translation;
    if (translation[key] && translation[key][locale]) {
        return translation[key][locale];
    }
    else {
        return null;
    }
}
// /**
//  * Get translation edit object
//  *
//  * @param key {string} Translation key
//  * @param locale {string} Translation locale for the key
//  * @param translation_edit {object} Translation edit mapping
//  * @returns {null|*}
//  */
// function getTranslationEdit({key, locale, translation_edit}
//                                 : {
//     key: string, locale: string,
//     translation_edit:TranslationEdit
// }) {
//
//     if (translation_edit[key] && translation_edit[key][locale]) {
//
//         return translation_edit[key][locale]
//
//     } else {
//         return null
//     }
// }
/**
 * Flatten a nested object
 *
 * @param ob {object} Object to flatten
 * @param key_stop {string} Stop at this key if value is in values_stop
 * @param values_stop {array} Values to stop at
 * @param array_keys_continue {array} Continue flattening for this array keys
 */
function flattenObject(ob, key_stop, values_stop, array_keys_continue) {
    if (array_keys_continue === void 0) { array_keys_continue = []; }
    var toReturn = {};
    for (var i in ob) {
        if (!ob.hasOwnProperty(i))
            continue;
        if (key_stop
            && typeof ob[i] == 'object'
            && ob[i].hasOwnProperty(key_stop)
            && values_stop.indexOf(ob[i][key_stop]) != -1) {
            toReturn[i] = ob[i];
        }
        else if ((!Array.isArray(ob[i]) || array_keys_continue.includes(i))
            && (typeof ob[i]) == 'object'
            && ob[i] !== null) {
            var flatObject = flattenObject(ob[i], key_stop, values_stop, array_keys_continue);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x))
                    continue;
                toReturn[i + '.' + x] = flatObject[x];
            }
        }
        else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}
/**
 * Check if an array is empty
 *
 * @param obj {array} Array ot check if it is empty
 * @returns {boolean}
 */
function isEmptyArray(obj) {
    if (typeof obj != 'object') {
        return false;
    }
    if (!(Object.prototype.toString.call(obj) === '[object Array]')) {
        return false;
    }
    else {
        return obj.length === 0;
    }
}
/**
 * Check if an object is empty
 *
 * @param obj {object} Object to check if it is empty
 * @returns {boolean}
 */
function isEmptyObject(obj) {
    if (typeof obj != 'object') {
        return false;
    }
    return JSON.stringify(obj) == JSON.stringify({});
}
/**
 * Convert list to dictionary
 *
 * @param list {array} List to convert
 * @param key { string | function } used to return from list element they value to create key for dictionary
 * @returns {*}
 */
function listToDic(list, key) {
    if (typeof key == 'string') {
        var _key_1 = key;
        key = function () {
            return _key_1;
        };
    }
    var dic = list.reduce(function (d, val) {
        d[key(val)] = val;
        return d;
    }, {});
    return dic;
}
// /**
//  * Create a ux input filter for react table
//  *
//  * @param placeholder {string} Placeholder for filter
//  * @returns {function({filter: *, onChange: *}): *}
//  */
// function makePlaceholderFilter(placeholder:string) {
//
//     return ({filter, onChange}) => {
//
//         return <input type='text'
//                       placeholder={placeholder}
//                       style={{
//                           width: '100%'
//                       }}
//                       value={filter ? filter.value : ''}
//                       onChange={(event) => onChange(event.target.value)}
//         />
//     }
// }
/**
 * Deep merge of two object
 *
 * @param obj_one {object} First object
 * @param obj_two {object} Second object
 * @returns {any}
 */
function merge(obj_one, obj_two) {
    obj_two = flattenObject(obj_two);
    obj_one = clone(obj_one);
    for (var key in obj_two) {
        setNestedObject(obj_one, key, obj_two[key]);
    }
    return obj_one;
}
/**
 * Set a nested object
 *
 * @param nestedObj {object} Nested object
 * @param pathString {string} Dot separated key to value
 * @param val {any} Value to set
 * @returns {T}
 */
var setNestedObject = function (nestedObj, pathString, val) {
    var path_string_list = pathString.split('.');
    return path_string_list.reduce(function (obj, key, i) {
        if (i <= path_string_list.length - 2 && obj[key] === undefined) {
            obj[key] = {};
        }
        else if (i == path_string_list.length - 1) {
            obj[key] = val;
        }
        return obj[key];
    }, nestedObj);
};
/**
 * Sort a list
 *
 * @param list {arrau} List to sort
 * @param key {function} Function to obtain sorting value
 * @param [direction] {string} Sort direction asc | desc
 * @param [indifferent_to_character_case] {boolean} Indifferent to character case
 * @returns {*}
 */
function sort(list, key, direction, indifferent_to_character_case) {
    if (direction === void 0) { direction = 'desc'; }
    if (indifferent_to_character_case === void 0) { indifferent_to_character_case = false; }
    try {
        var i_1 = direction == 'asc' ? -1 : 1;
        if (!key) {
            key = function (v) { return v; };
        }
        list.sort(function (a, b) {
            var _a = key(a);
            var _b = key(b);
            if (indifferent_to_character_case) {
                _a = typeof _a == 'number' ? _a.toString() : _a.toLowerCase();
                _b = typeof _b == 'number' ? _b.toString() : _b.toLowerCase();
            }
            if (_a > _b) {
                return 1 * i_1;
            }
            else if (_a < _b) {
                return -1 * i_1;
            }
            else {
                return 0;
            }
        });
        return list;
    }
    catch (e) {
        console.error('Params to sort', list, key, direction);
        throw e;
    }
}
/**
 * Convert to select option
 *
 * @param v {string} Text string
 * @returns {{label: *, value: *}}
 */
function toOptions(v) {
    return { value: v, label: v };
}
/**
 * Convert object to url query
 *
 * @param query {object} Url query object
 * @returns {string}
 */
function toQuery(query) {
    return "?".concat(queryString.stringify(query));
}
/**
 * Translate values in a list
 *
 * @param list {array} List with values to translate
 * @param translation {object} Translation mapping with mapping by data type and direction
 * @param name_type {string} Name type time_type | travel_type | absence_type
 * @param {string} direction e.g. sv->key or key->sv
 * @returns {*}
 */
function translateList(list, translation, name_type, direction) {
    return list.reduce(function (tot, l) {
        l = translation[name_type][direction][l];
        if (l) {
            tot.push(l);
        }
        return tot;
    }, []);
}
/**
 * Ensure values in array are unique
 *
 * @param list {array} Ensure values in a list are unique
 * @param [key] {function} Function returning value to to uniqueness comparison
 * @param [exclude] {array} Keys to exclude
 * @returns {[]}
 */
function unique(list, key, exclude) {
    if (key === void 0) { key = function (v) { return v; }; }
    if (exclude === void 0) { exclude = []; }
    var keys = [];
    var tmp = [];
    list.forEach(function (l) {
        var _key = key(l); //key ? key(l) : l;
        if (!keys.includes(_key) && !exclude.includes(_key)) {
            tmp.push(l);
            keys.push(_key);
        }
    });
    return tmp;
}
export default {
    autoId: autoId,
    clone: clone,
    compare: compare,
    deleteNestedObject: deleteNestedObject,
    dicToList: dicToList,
    flattenObject: flattenObject,
    generateTranslationEdit: generateTranslationEdit,
    getInstructionKey: getInstructionKey,
    getKeys: getKeys,
    getLocales: getLocales,
    getNestedObject: getNestedObject,
    getQuery: getQuery,
    getTranslation: getTranslation,
    // getTranslationEdit,
    getUid: getUid,
    getUidAsQuery: getUidAsQuery,
    getTip: getTip,
    isEmptyArray: isEmptyArray,
    isEmptyObject: isEmptyObject,
    listToDic: listToDic,
    // makePlaceholderFilter,
    merge: merge,
    setNestedObject: setNestedObject,
    sort: sort,
    toOptions: toOptions,
    toQuery: toQuery,
    translateList: translateList,
    unique: unique
};
