import csvDelimiters from "../constants/csvDelimiters";
import util from "../lib/util";
/**
 * Get translation as csv
 *
 * @param translation {object} Current translation
 * @returns {string}
 */
function getAsCsv(translation) {
    var rows = [[]];
    for (var key in translation) {
        for (var locale in translation[key]) {
            if (rows[0].indexOf(locale) == -1) {
                rows[0].push(locale);
            }
        }
    }
    var header = ['key'];
    rows[0] = rows[0].sort();
    for (var _i = 0, _a = rows[0]; _i < _a.length; _i++) {
        var row = _a[_i];
        if (header.indexOf(row) != -1) {
            continue;
        }
        header.push(row);
    }
    rows[0] = header;
    var i = 1;
    for (var key in translation) {
        rows.push([]);
        for (var _b = 0, header_1 = header; _b < header_1.length; _b++) {
            var locale = header_1[_b];
            // for (let locale in translation[key]){
            var val = '';
            if (translation[key][locale]) {
                val = translation[key][locale];
            }
            rows[i].push(val);
        }
        i++;
    }
    var csv = [];
    for (var _c = 0, rows_1 = rows; _c < rows_1.length; _c++) {
        var row = rows_1[_c];
        csv.push(row.join(csvDelimiters.TRANSLATION_CSV));
    }
    var csv_text = csv.join('\n');
    return csv_text;
}
function getAsXLSXInput(translation) {
    var csvData = [];
    var header = ['key'];
    var locales = util.getLocales([translation], ['eligible_for', 'key']);
    header = header.concat(locales);
    for (var key in translation) {
        csvData.push(translation[key]);
    }
    header.push('eligible_for');
    return { header: header, csvData: csvData };
}
/**
 * Get resulting export after merging local translation edits into translation
 *
 * @param translation {object} Current translation
 * @param translation_edit {object} Edits to translation
 * @returns {*}
 */
function getExport(_a) {
    var translation = _a.translation, translation_edit = _a.translation_edit;
    var translation_export = {};
    for (var key in translation) {
        for (var locale in translation[key]) {
            if (!translation_export[key]) {
                translation_export[key] = {};
            }
            translation_export[key][locale] = translation[key][locale].text;
        }
    }
    for (var key in translation_edit) {
        var te = util.getTranslation({
            key: key,
            locale: 'key',
            translation: translation_edit
        });
        if (te && te.action == 'deleted') {
            delete translation_export[key];
            continue;
        }
        for (var locale in translation_edit[key]) {
            te = translation_edit[key][locale];
            if (te.action == 'deleted') {
                delete translation_export[key][locale];
                continue;
            }
            if (!translation_export[key]) {
                translation_export[key] = {};
            }
            translation_export[key][locale] = te.text;
        }
    }
    return translation_export;
}
export default function useTableTranslationGetExportData(_a) {
    var translation = _a.translation, translation_edit = _a.translation_edit;
    function getExportCsv() {
        return getAsCsv(getExport({ translation: translation, translation_edit: translation_edit }));
    }
    function getExportJson() {
        return getExport({ translation: translation, translation_edit: translation_edit });
    }
    function getExportXlsx() {
        return getAsXLSXInput(getExport({ translation: translation, translation_edit: translation_edit }));
    }
    /**
     * Format for secondary translation tables e.g. mobile, web_backend_puppeteer-pdf-server, ...
     *
     * @param translation {object} Current translation
     * @param translation_edit {object} Edits to translation
     * @returns {{}}
     */
    function getDatabaseExportSecondary() {
        var translation_export = getExport({ translation: translation, translation_edit: translation_edit });
        var secondary_export = {};
        for (var key in translation_export) {
            var db = translation_export[key].eligible_for;
            if (!secondary_export[db]) {
                secondary_export[db] = {};
            }
            for (var locale in translation_export[key]) {
                if (['eligible_for', 'key'].includes(locale)) {
                    continue;
                }
                if (!secondary_export[db][locale]) {
                    secondary_export[db][locale] = {};
                }
                secondary_export[db][locale][key] = translation_export[key][locale];
            }
        }
        return secondary_export;
    }
    return {
        getExportCsv: getExportCsv,
        getExportJson: getExportJson,
        getExportXlsx: getExportXlsx,
        getDatabaseExportSecondary: getDatabaseExportSecondary
    };
}
