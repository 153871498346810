/**
 *Created by Mikael Lindahl on 2019-09-30
 */

"use strict";

export default {
    APP_BASE:"/customers/config",
    CONFIG: "/customers/api/config",
    CUSTOMER: "/customers/api/customer",
    CUSTOMER_RESTORE: "/customers/api/customer-restore",
    EVENT:id=>`/customers/api/event?uid=${id}`,
    EVENT_PAGE: id=>`/customers/config/event?uid=${id}`,
    STATE: "/customers/api/state",
    DOCUMENTATION_SALARY_CODES: "https://bitbucket.org/isolvetech/byggkollen-functions/src/development/README.md",
    EXPORT_USER_DATA:"/customers/api/export-user-data",
    GLOBAL_CONFIG: "/customers/api/global-config",
    GLOBAL_CONFIG_ABSENCE_TIME_TYPES: "/customers/api/global-config-absence-time-types",
    GLOBAL_CONFIG_FILE_TYPES: "/customers/config/global-config/file-types",
    GLOBAL_CONFIG_VALID_LOCALES: "/customers/config/global-config/valid-locales",
    GLOBAL_CONFIG_UNITS: "/customers/api/global-config-units",
    API_KEYS: "/customers/api/api_keys",
    CLIENT_ID: "/customers/api/client_id",
}

